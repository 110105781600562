import React from 'react';
import {Empty} from "antd";
import {useTranslation} from "react-i18next";

function EmptyData(props) {
      const {t} = useTranslation()
      return (
            <Empty className='empty-data' description={t('alert.empty_data')} image={Empty.PRESENTED_IMAGE_SIMPLE} />
      );
}

export default EmptyData;