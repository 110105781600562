export const ALERT_SUCCESS = 'success'
export const ALERT_ERROR = 'error'
export const ALERT_COMPLETE = 'complete'

export const STAFF_AVAILABLE = 1
export const STAFF_BREAK = 2
export const STAFF_WORKING = 3

export const ACTIVE = 1
export const NOT_ACTIVE = 0

export const APM_PENDING = 1
export const APM_WAITING = 2
export const APM_IN_PROCESSING = 3
export const APM_FINISH = 4
export const APM_CANCEL = 5
export const APM_DELETED = 6
export const APM_ACCEPTED = 7


export const TABLE_STAFF = 'staff'
export const TABLE_SERVICE = 'service'
export const TABLE_CUSTOMER = 'customer'

export const TYPE_APPOINTMENT_WALK_IN = 1
export const TYPE_APPOINTMENT_CUSTOMER = 2

export const APM_CANCELED_BY_ADMIN = 1
export const APM_CANCELED_BY_CUSTOMER = 2

export const ACCEPTED_APPOINTMENT = 1
export const REJECTED_APPOINTMENT = 0

export const LOGIN_TYPE_CHECK_IN = 'check-in'
export const LOGIN_TYPE_LOGIN = 'login'

export const READ = 1
export const NOT_READ = 0

export const TYPE_VOUCHER = 2
export const TYPE_COUPON = 1

export const CUSTOMER_NORMAL = 2
export const CUSTOMER_VIP = 3