import React, {useEffect, useState} from 'react';
import {Button, Col, DatePicker, Form, Input, Modal, Row} from "antd";
import {useDispatch, useSelector} from "react-redux";
import AlertCommon from "../../../components/alert";
import {ALERT_ERROR, ALERT_SUCCESS} from "../../../constants/common";
import {formatPhoneNumber} from "../../../utils/helper";
import moment from "moment";
import {toggleModalProfileCustomer} from "../../../actions/modal";
import {customerLogout} from "../../../actions/auth";
import {useNavigate} from "react-router-dom";
import {updateProfileCustomer} from "../../../actions/customer";
import '../../../stylesheets/customer-profile.scss'

const CustomerProfileModal = (props) => {
      const [form] = Form.useForm()
      const [loading, setLoading] = useState(false)
      const {customer} = useSelector(state => state.auth)
      const {openProfileCustomer} = useSelector(state => state.modal)
      const dispatch = useDispatch()
      const navigate = useNavigate()

      useEffect(() => {
            form.setFieldsValue({
                  name: customer?.name,
                  phone: formatPhoneNumber(customer?.phone),
                  email: customer ? customer.email : '',
                  address: customer ? customer.address : '',
                  city: customer ? customer.city : '',
                  state: customer ? customer.state : '',
                  zip: customer ? customer.zip : '',
                  birthday: (customer && customer.birthday) ? moment(customer.birthday, 'YYYY-MM-DD') : null
            })
      }, [customer])

      const onUpdateProfile = async (e) => {
            try {
                  setLoading(true)
                  e.birthday = e.birthday ? moment(e.birthday).format('YYYY-MM-DD') : null
                  let response = await dispatch(updateProfileCustomer(e))
                  setLoading(false)
                  AlertCommon(ALERT_SUCCESS, response.message)
                  return dispatch(toggleModalProfileCustomer(false))
            } catch (e) {
                  setLoading(false)
                  return AlertCommon(ALERT_ERROR, e.message)
            }
      }

      const disabledDate = (current) => {
            // Can not select days before today and today
            return current && current > moment().startOf('day');
      };

      const handleLogout = () => {
            Modal.confirm({
                  title: `Are you sure you want to log out account? `,
                  className: 'modal-confirm-logout',
                  okText: 'Submit',
                  onOk() {
                        return acceptLogout()
                  },
                  onCancel() {
                        return
                  },
            });
      }

      const acceptLogout = async () => {
            dispatch(toggleModalProfileCustomer(false))
            await dispatch(customerLogout())
            return navigate(`/customer-checkin`)
      }

      return (
            <Modal
                  footer={null}
                  visible={openProfileCustomer}
                  maskClosable={false}
                  closable={false}
                  centered
                  className='modal-update-profile-customer modal-general'
                  getContainer={false}

            >
                  <div className="head-modal">
                        <p className="title">Update your profile</p>
                        {customer?.name ?
                              <Button onClick={() => dispatch(toggleModalProfileCustomer(false))}>Close</Button>
                              :
                              <Button className='logout' onClick={handleLogout}>Logout</Button>
                        }

                  </div>
                  <div className="body-modal">
                        <div className="form-wp">
                              <Form
                                    form={form}
                                    layout='vertical'
                                    name="form-profile-hooks"
                                    onFinish={onUpdateProfile}
                              >
                                    <Form.Item name="name" label="Name">
                                          <Input/>
                                    </Form.Item>
                                    <Form.Item name="phone" label="Phone">
                                          <Input disabled/>
                                    </Form.Item>
                                    <div className="form-item">
                                          <div className="ant-form-item-label">
                                                <label>Email <span>(Optional)</span></label>
                                                <p className="sub-label">Please give us an email so we can send you a
                                                      promotion and gift.</p>
                                          </div>

                                          <Form.Item name="email">
                                                <Input/>
                                          </Form.Item>
                                    </div>

                                    <div className="form-item">
                                          <div className="ant-form-item-label">
                                                <label>Birthday <span>(Optional)</span></label>
                                                <p className="sub-label">Please give us your birthday so we can send you
                                                      a gift</p>
                                          </div>
                                          <Form.Item name="birthday">
                                                <DatePicker
                                                      disabledDate={disabledDate}
                                                      className='date-birth-date'
                                                      placeholder=''
                                                      showNow={false}
                                                      dropdownClassName='date-birth-date-dropdown'
                                                />
                                          </Form.Item>
                                    </div>
                                    <div className="form-item">
                                          <div className="ant-form-item-label">
                                                <label>Address <span>(Optional)</span></label>
                                                <p className="sub-label">Please give us an address so we can send you a
                                                      promotion and gift.</p>
                                          </div>
                                          <Form.Item name="address">
                                                <Input/>
                                          </Form.Item>
                                    </div>

                                    <div className="form-item">
                                          <div className="ant-form-item-label">
                                                <label>City <span>(Optional)</span></label>
                                          </div>
                                          <Form.Item name="city">
                                                <Input/>
                                          </Form.Item>
                                    </div>


                                    <Row gutter={[16, 16]}>
                                          <Col xs={24} sm={24} md={12}>
                                                <div className="form-item">
                                                      <div className="ant-form-item-label">
                                                            <label>State <span>(Optional)</span></label>
                                                      </div>
                                                      <Form.Item name="state">
                                                            <Input/>
                                                      </Form.Item>
                                                </div>
                                          </Col>
                                          <Col xs={24} sm={24} md={12}>
                                                <div className="form-item">
                                                      <div className="ant-form-item-label">
                                                            <label>Zipcode <span>(Optional)</span></label>
                                                      </div>
                                                      <Form.Item name="zip">
                                                            <Input/>
                                                      </Form.Item>
                                                </div>
                                          </Col>
                                    </Row>


                                    <Form.Item>
                                          <Button loading={loading} type="primary" htmlType="submit">
                                                Update
                                          </Button>
                                    </Form.Item>
                              </Form>
                        </div>
                  </div>
            </Modal>
      );
}

export default CustomerProfileModal;