import React, {useEffect} from 'react';
import {Button, Form, Input, Modal} from "antd";
import {useDispatch} from "react-redux";
import {convertTZLocal} from "../../../utils/helper";
import '../../../stylesheets/create-appointment-success.scss'

const ModalCreateAppointmentSuccess = ({visible, appointment, closeModal}) =>  {
      const [form] = Form.useForm()
      const dispatch = useDispatch()

      const complete = async () => {
            return closeModal()
      }

      useEffect(() => {
            if(appointment){
                  form.setFieldsValue({
                        id: `#${appointment.id}`,
                        salon_name: appointment.salon.name,
                        customer_name: appointment.customer.name,
                        customer_phone: appointment.customer.phone_format,
                        services: appointment.list_service_names_with_price,
                        service_custom: appointment.service_custom_name,
                        date_time: convertTZLocal(appointment.date_appointment).format,
                        staff: appointment.staff_name
                  })
            }
      }, [appointment])

      // const onViewAppointment = () => {
      //       localStorage.setItem('txt_search_appointments', appointment.id)
      //       // dispatch(toggleModalListAppointment(true))
      //       return closeModal()
      // }
      return (
            <Modal
                  footer={null}
                  visible={visible}
                  maskClosable={false}
                  closable={false}
                  centered
                  className='create-appointment-success-modal'
                  getContainer={false}

            >
                  <div className="body-modal">
                        <i className="fa-solid fa-circle-check icon-success"></i>
                        <h3 className="title">Thank you for booking</h3>

                        <div className="appointment-summary">
                              <p className="title-summary">Summary</p>
                              <Form
                                    form={form}
                                    layout="vertical"
                              >
                                    <Form.Item label="Booking" name='id'  >
                                          <Input disabled />
                                    </Form.Item>
                                    <Form.Item label="Salon" name='salon_name'  >
                                          <Input disabled />
                                    </Form.Item>
                                    <Form.Item label="Customer" name='customer_name'  >
                                          <Input disabled />
                                    </Form.Item>
                                    <Form.Item label="Phone customer" name='customer_phone'  >
                                          <Input disabled />
                                    </Form.Item>
                                    <Form.Item label="Services" name='services' >
                                          <Input.TextArea disabled
                                                autoSize={{ minRows: 1}}
                                          />
                                    </Form.Item>
                                    <Form.Item label="Appointment note" name='service_custom' >
                                          <Input.TextArea disabled
                                                          autoSize={{ minRows: 1}}
                                          />
                                    </Form.Item>
                                    <Form.Item label="Time and Date" name='date_time'  >
                                          <Input disabled />
                                    </Form.Item>
                                    <Form.Item label="Staff" name='staff'>
                                          <Input disabled />
                                    </Form.Item>
                              </Form>
                        </div>
                        {/*<Button className='view-appointment'*/}
                        {/*        onClick={onViewAppointment}*/}
                        {/*>View Appointment</Button>*/}
                        <Button onClick={complete}>Close</Button>
                  </div>
            </Modal>
      );
}

export default ModalCreateAppointmentSuccess;