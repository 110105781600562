import React, {useEffect, useState} from 'react';
import {Button, DatePicker, Modal} from "antd";
import moment from 'moment';
import {getReportByDate} from "../../../actions/dashboard";
import {useDispatch, useSelector} from "react-redux";
import {toggleShowModalReport} from "../../../actions/modal";
import EmptyData from "../../../components/empty";
import {useTranslation} from "react-i18next";

const ModalReport = (props) =>  {
      const {t} = useTranslation()
      const {openReport} = useSelector(state => state.modal)
      const dateFormat = 'LL';
      const [date, setDate] = useState(moment().format('YYYY-MM-DD'))
      const [appointments, setAppointments] = useState([])
      const [total, setTotal] = useState(0)
      const dispatch = useDispatch()

      useEffect(() => {
            const getReport = async () => {
                  try {
                        let response = await getReportByDate(date)
                        setAppointments(response.data.appointments)
                        setTotal(response.data.total_price)
                  } catch (e) {
                      console.log(e);
                  }
            }
            getReport()
      }, [date])

      const handleChangeDate = (date, dateString) => {
            return setDate(moment(date).format('YYYY-MM-DD'))
      }

      const renderListAppointment = () => {
            return appointments.map(item => (
                  <div className="item" key={item.id}>
                        <div className="customer">
                              <p className="name">{item.customer_name}</p>
                        </div>
                        <div className="staff">
                              <p className="name">{item.staff_name}</p>
                        </div>
                        <div className="service">
                              <p className="name">{item.list_service_names_with_price}</p>
                        </div>
                        <div className="service">
                              <p className={`name ${!item.service_custom ? 'no-info' : ''}`}>{item.service_custom ? item.service_custom_name : t('alert.no_info')}</p>
                        </div>
                        <div className="price">
                              <p className="price">${item.price_format}</p>
                        </div>
                  </div>
            ))
      }

      return (
            <Modal
                  footer={null}
                  visible={openReport}
                  maskClosable={false}
                  closable={false}
                  centered
                  className='modal-general modal-report'
            >
                  <div className="head-modal">
                        <div className='left'>
                              <p className="title">{t('modal.title_report')}</p>
                              <DatePicker onChange={handleChangeDate}  defaultValue={moment((new Date()), dateFormat)} format={dateFormat} />
                        </div>
                        <Button onClick={() => dispatch(toggleShowModalReport(false))}>{t('button.close')}</Button>
                  </div>
                  <div className="body-modal">
                        <div className="reports">
                              <div className="head-col">
                                    <p>{t('table.column_customer')}</p>
                                    <p>{t('table.column_staff')}</p>
                                    <p>{t('table.column_services')}</p>
                                    <p>{t('table.column_service_note')}</p>
                                    <p>{t('table.column_price')}</p>
                              </div>
                              <div className="body-col">
                                    {appointments.length > 0 ?
                                          renderListAppointment()
                                    :
                                          <EmptyData/>
                                    }

                              </div>
                        </div>
                  </div>
                  <div className="footer-modal">
                        <div className="total-amount">
                              <p className="total">{t('form.label_total')}: <span className="amount">${total}</span></p>
                        </div>
                  </div>
            </Modal>
      );
}

export default ModalReport;