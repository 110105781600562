import axiosInstance from "./axiosInstance";

const base_path = '/salon'
const salonApi = {
      login(data){
            const url = `${base_path}/login`
            return axiosInstance.post(url, data)
      },
      signUp(formData){
            const url = `${base_path}/sign-up`
            return axiosInstance.post(url, formData)
      },
      changePassword(data){
            const url = `${base_path}/change-password`
            return axiosInstance.post(url, data)
      },
      updateSalon(data){
            const url = `${base_path}/update-salon`
            return axiosInstance.post(url, data)
      },
      updateCustomerInfo(data){
            const url = `${base_path}/update-customer`
            return axiosInstance.post(url, data)
      },
      verifyAdmin(data){
            const url = `${base_path}/verify-admin`
            return axiosInstance.post(url, data)
      }
}

export default salonApi