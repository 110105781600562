import React, {useRef, useState} from 'react';
import {Avatar, Button, Form, Input, Modal, Popconfirm, Tabs, Upload} from "antd";
import DatatableGeneral from "../../../components/datatable";
import {DeleteOutlined, FormOutlined, HistoryOutlined, LockOutlined, UnlockOutlined} from '@ant-design/icons';
import {useDispatch, useSelector} from "react-redux";
import { SearchOutlined } from '@ant-design/icons';
import {
      ACTIVE,
      ALERT_ERROR,
      ALERT_SUCCESS,
      NOT_ACTIVE,
      STAFF_AVAILABLE,
      STAFF_BREAK, STAFF_WORKING,
      TABLE_STAFF
} from "../../../constants/common";
import AlertCommon from "../../../components/alert";
import {
      changeActiveStaff,
      changeStatusStaff,
      createNewStaff,
      deleteStaff,
      setOpenFormEditStaff,
      setStaffEdit
} from "../../../actions/staff";
import {toggleModelHistoryCheckInOutStaff, toggleShowModalStaff} from "../../../actions/modal";
import {convertTZLocal, formatPhoneNumber, normalizeInput, replaceFormatPhoneLogin} from "../../../utils/helper";
import EmptyData from "../../../components/empty";
import moment from 'moment'
import {useTranslation} from "react-i18next";

const ModalListStaff = (props) => {
      const {t} = useTranslation()
      const [tab, setTab] = useState('list-staff')
      const [loading, setLoading] = useState(false)
      const {openStaff} = useSelector(state => state.modal)
      const {staffs} = useSelector(state => state.staff)
      const dispatch = useDispatch()
      const [form] = Form.useForm()
      const [staffSelect, setStaffSelect] = useState(null)
      const [btn, setBtn] = useState('')
      const typingRef = useRef(null)
      const [txtSearch, setTxtSearch] = useState('')
      const [phone, setPhone] = useState('')
      const [avatar, setAvatar] = useState(null)

      const staffList = staffs.filter(item => `${item.first_name} ${item.last_name}`.toLowerCase().includes(txtSearch.toLowerCase()) || item.phone.includes(txtSearch) || replaceFormatPhoneLogin(item.phone).includes(txtSearch.toLowerCase()))

      const handleChangeTab = (key) => {
            setTab(key)
      }

      let columns = [
            {
                  title: 'Id',
                  dataIndex: '_id',
                  key: '_id',
            },
            {
                  title: t('table.column_stt'),
                  dataIndex: 'key',
                  key: 'key',
                  render: text => <p>{text}</p>,
            },
            {
                  title: t('table.column_name'),
                  dataIndex: 'name',
                  key: 'name',
                  render: record => <React.Fragment> <Avatar size={32} src={record.avatar} /><span>{record.name}</span></React.Fragment>,
            },
            {
                  title: t('table.column_phone'),
                  dataIndex: 'phone',
                  key: 'phone',
                  render: (text, record) => <p>{text}</p>,
            },
            {
                  title: t('table.column_check_in'),
                  dataIndex: 'time_check_in',
                  key: 'time_check_in',
                  render: text => <p>{text}</p>,
            },
            {
                  title: t('table.column_check_out'),
                  dataIndex: 'time_check_out',
                  key: 'time_check_out',
                  render: text => <p>{text}</p>,
            },
            {
                  title: t('table.column_status'),
                  dataIndex: 'status',
                  key: 'status',
                  render: (record) => <p className={`status ${record.active === NOT_ACTIVE ? 'inactive' :(record.status_name).toLowerCase()}`}>{record.active === NOT_ACTIVE ? 'In-active' : record.status_name}</p>,
            },
            {
                  title: <i className="fa-solid fa-screwdriver-wrench"></i>,
                  dataIndex: 'action',
                  key: 'action',
                  render: (record) => <div className='action-row'>

                        <Button className='edit-row-btn' onClick={() => showFormEditStaff(record)}><FormOutlined/></Button>
                        {record.status !== STAFF_WORKING &&
                              <React.Fragment>
                                    {record.status === STAFF_BREAK &&
                                          <Popconfirm
                                                title={t('alert.title_confirm_delete')}
                                                placement="topRight"
                                                onConfirm={() => handleDeleteStaff(record.id)}
                                                okText={t('button.delete')}
                                                cancelText={t('button.cancel')}

                                          >
                                                <Button loading={loading && staffSelect === record.id && btn === 'delete'} className='delete-row-btn'><DeleteOutlined/></Button>
                                          </Popconfirm>
                                    }
                                    <React.Fragment>
                                          {record.active ===  ACTIVE ?
                                                <Popconfirm
                                                      title={t('alert.lock_staff')}
                                                      placement="topRight"
                                                      onConfirm={() => handleChangeActive(record.id)}
                                                      okText={t('button.submit')}
                                                      cancelText={t('button.cancel')}
                                                >
                                                      <Button loading={loading && staffSelect === record.id && btn === 'change-active'} className='edit-row-btn'><LockOutlined/></Button>
                                                </Popconfirm>

                                                :
                                                <Popconfirm
                                                      title={t('alert.unlock_staff')}
                                                      placement="topRight"
                                                      onConfirm={() => handleChangeActive(record.id)}
                                                      okText={t('button.submit')}
                                                      cancelText={t('button.cancel')}
                                                >
                                                      <Button loading={loading && staffSelect === record.id && btn === 'change-active'} className='edit-row-btn'><UnlockOutlined/></Button>
                                                </Popconfirm>

                                          }
                                    </React.Fragment>
                              </React.Fragment>
                        }

                        {(record.active === ACTIVE && record.status !== STAFF_WORKING) &&
                              <React.Fragment>

                                    {record.status ===  STAFF_AVAILABLE ?
                                          <Button className='check-out-btn' loading={loading && staffSelect === record.id && btn === 'change-status'}
                                                  onClick={() => handleChangeStatus(record.id, STAFF_BREAK)}
                                          >
                                                <i className="fa-solid fa-arrow-right-from-bracket"></i>
                                                Check out
                                          </Button>
                                          :
                                          <Button className='check-in-btn' loading={loading && staffSelect === record.id && btn === 'change-status'}
                                                  onClick={() => handleChangeStatus(record.id, STAFF_AVAILABLE)}
                                          >
                                                <i className="fa-solid fa-location-pin"></i>
                                                Check in
                                          </Button>
                                    }
                              </React.Fragment>
                        }
                        <Button onClick={() => dispatch(toggleModelHistoryCheckInOutStaff(record, true))} className='history-btn'><HistoryOutlined /></Button>
                  </div>
            },

      ];

      const contentData = () => {
            return staffList.map((item, index) => {
                  return {
                        _id: item.id,
                        key: index + 1,
                        name: item,
                        phone: formatPhoneNumber(item.phone),
                        time_check_in: item.time_check_in ? convertTZLocal(`${moment(new Date().setHours(0,0,0,0)).format('YYYY-MM-DD')} ${item.time_check_in}`).hourA : null,
                        time_check_out: item.time_check_out ? convertTZLocal(`${moment(new Date().setHours(0,0,0,0)).format('YYYY-MM-DD')} ${item.time_check_out}`).hourA : null,
                        status: item,
                        action: item
                  }
            })
      }
      let dataSource = {
            totalElements: staffList.length,
            content: contentData(),
      };

      const {
            DataTable,
      } = DatatableGeneral({
            type: TABLE_STAFF,
            columns: columns,
            dataSource: dataSource,
            updateType: 'staff',
      });

      const handleChangePhone = (e) => {
            return setPhone(prevState => normalizeInput(e.target.value, prevState))
      }

      const onSaveStaff = async (e) => {
            try {
                  setLoading(true)
                  let formData = new FormData()
                  e.phone = replaceFormatPhoneLogin(phone)

                  if(avatar && avatar.originFileObj){
                        e.avatar = avatar.originFileObj
                  }

                  Object.keys(e).forEach( function (key) {
                        if(typeof e[key] === 'string'){
                              e[key] = e[key] ? e[key].trim() : null;
                        }
                        formData.append(key, e[key])
                  });
                  let response = await dispatch(createNewStaff(formData))
                  setLoading(false)
                  AlertCommon(ALERT_SUCCESS, response.message)
                  form.resetFields()
                  setAvatar(null)
                  setPhone('')
                  setTab('list-staff')
            } catch (e) {
                  setLoading(false)
                  return AlertCommon(ALERT_ERROR, e.message)
            }
      }

      const handleChangeStatus = async (id, status) => {
            try {
                  let data = {id, status}
                  setBtn('change-status')
                  setStaffSelect(id)
                  setLoading(true)
                  let res = await dispatch(changeStatusStaff(data))
                  AlertCommon(ALERT_SUCCESS, res.message)
                  setBtn('')
                  setStaffSelect(null)
                  setLoading(false)
            } catch (e) {
                  setLoading(false)
                  return AlertCommon(ALERT_ERROR, e.message)
            }
      }

      const handleChangeActive = async (id) => {
            try {
                  setBtn('change-active')
                  setStaffSelect(id)
                  setLoading(true)
                  let res = await dispatch(changeActiveStaff(id))
                  AlertCommon(ALERT_SUCCESS, res.message)
                  setBtn((''))
                  setStaffSelect(id)
                  setLoading(false)
            } catch (e) {

                  setLoading(false)
                  return AlertCommon(ALERT_ERROR, e.message)
            }
      }

      const handleDeleteStaff = async (id) => {
            try {
                  setBtn('delete')
                  setStaffSelect(id)
                  setLoading(true)
                  let res = await dispatch(deleteStaff(id))
                  setLoading(false)
                  return AlertCommon(ALERT_SUCCESS, res.message)
            } catch (e) {
                  setLoading(false)
                  return AlertCommon(ALERT_ERROR, e.message)
            }
      }

      const showFormEditStaff = async (staff) => {
            await dispatch(setStaffEdit(staff))
            return dispatch(setOpenFormEditStaff(true))
      }

      const handleCloseModal = () => {
            setTab('list-staff')
            form.resetFields()
            setAvatar(null)
            setPhone('')
            return dispatch(toggleShowModalStaff(false))
      }

      const onSearch = (e) => {
            const value = e.target.value || ''
            if(typingRef.current){
                  clearTimeout(typingRef.current)
            }
            typingRef.current = setTimeout(() => {
                  setTxtSearch(value)
            }, 1000)
      }

      const getBase64 = (img, callback) => {
            const reader = new FileReader();
            reader.addEventListener('load', () => callback(reader.result));
            reader.readAsDataURL(img);
      };

      const handleChangeUpload = (info) => {
            if (info.file.status === 'done') {
                  // Get this url from response in real world.
                  getBase64(info.file.originFileObj, (url) => {
                        setLoading(false);
                        let newFile = info.file
                        newFile.image = url
                        setAvatar(info.file)
                  });
            }
      }

      const dummyRequest = ({file, onSuccess}) => {
            setTimeout( () => onSuccess(true), 0)
      }

      return (
            <Modal
                  footer={null}
                  visible={openStaff}
                  maskClosable={false}
                  closable={false}
                  centered
                  className='modal-general modal-staff modal-table'
            >
                  <div className="head-modal">
                        <p className="title">{t('modal.title_staff')}</p>
                        <Button onClick={handleCloseModal}>{t('button.close')}</Button>
                  </div>
                  <div className="body-modal">
                        <div className="staffs">
                              <Tabs activeKey={tab} onChange={handleChangeTab}>
                                    <Tabs.TabPane tab={t('tab.add_new_staff')} key="new-staff">
                                          <div className="form-wp">
                                                <Form
                                                      form={form}
                                                      layout='vertical'
                                                      onFinish={onSaveStaff}
                                                      initialValues={{
                                                            first_name : '',
                                                            last_name : '',
                                                      }}
                                                >
                                                      <div className="form-staff">
                                                            <div className="left">
                                                                  <Form.Item>
                                                                        <Upload
                                                                              className='upload-avatar-staff'
                                                                              listType="picture-card"
                                                                              onChange={handleChangeUpload}
                                                                              customRequest={dummyRequest}
                                                                              accept=".jpg,.jpeg,.png"
                                                                              showUploadList={false}
                                                                        >
                                                                              {avatar ? <img src={avatar.image} alt="avatar" style={{width: '100%',}}
                                                                              /> : <i className="fa-regular fa-image"></i>}
                                                                        </Upload>
                                                                  </Form.Item>
                                                            </div>
                                                            <div className="right">
                                                                  <Form.Item name="first_name">
                                                                        <Input placeholder={t('form.placeholder_first_name')} />
                                                                  </Form.Item>
                                                                  <Form.Item name="last_name">
                                                                        <Input placeholder={t('form.placeholder_last_name')} />
                                                                  </Form.Item>
                                                                  <Form.Item>
                                                                        <Input value={phone} onChange={handleChangePhone} placeholder={t('form.placeholder_phone')} />
                                                                  </Form.Item>
                                                            </div>
                                                      </div>

                                                      <Form.Item>
                                                            <div className="form-btn">
                                                                  <Button loading={loading} type="primary" htmlType="submit">
                                                                        {t('button.add')}
                                                                  </Button>
                                                            </div>
                                                      </Form.Item>
                                                </Form>
                                          </div>
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab={t('tab.staff_list')} key="list-staff">
                                          <Input className='input-search' placeholder={t('form.placeholder_search_staff')} prefix={<SearchOutlined/>} onChange={onSearch} />
                                          <DataTable/>
                                          {staffList.length === 0 &&
                                                <EmptyData/>
                                          }
                                    </Tabs.TabPane>
                              </Tabs>
                        </div>
                  </div>
            </Modal>
      );
}

export default ModalListStaff;