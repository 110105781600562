import React from 'react';
import {Button, Modal, Rate} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {APM_CANCEL} from "../../constants/common";
import {checkObjectEmptyOrNull} from "../../utils/helper";
import {toggleModalNotificationAppointment} from "../../actions/modal";

const ModalNotification = (props) =>  {
      const dispatch = useDispatch()
      const {openModalNotify, dataNotify} = useSelector(state => state.modal)

      const renderListServices = () => {
            return dataNotify?.services.map( item => (
                        <p key={item.id}>
                              <span>{item.name}</span>
                              <span>${item.price_format}</span>
                        </p>

                  )
            )
      }

      const closeModal = () => {
            dispatch(toggleModalNotificationAppointment(null, false))
      }

      return (
            <Modal
                  footer={null}
                  visible={openModalNotify}
                  maskClosable={false}
                  closable={false}
                  centered
                  className='modal-notification-appointment'

            >
                  {dataNotify && !checkObjectEmptyOrNull(dataNotify) &&
                        <div className="body-modal">

                              <div className="content">
                                    <div className="block">
                                          <div className="head">
                                                <h4 className="title">
                                                      {dataNotify?.type_action === 'cancel-appointment' &&
                                                      <span>Your booking has been cancelled!</span>
                                                      }
                                                      {dataNotify?.type_action === 'customer-add-appointment' &&
                                                      <span>You have received a new appointment from a customer!</span>
                                                      }
                                                      {dataNotify?.type_action === 'customer-feedback-appointment' &&
                                                      <span>Customers have given feedback to your salon</span>
                                                      }
                                                </h4>

                                          </div>
                                          <div className="detail">
                                                <div className="salon">
                                                      <p>
                                                            <span className='label'>Booking ID</span>
                                                            <span className='value'>#{dataNotify.id}</span>
                                                      </p>
                                                      <p>
                                                            <span className='label'>Customer Name</span>
                                                            <span className='value'>{dataNotify.customer.name}</span>
                                                      </p>
                                                      <p>
                                                            <span className='label'>Phone number</span>
                                                            <span className='value'>{dataNotify.customer.phone_format}</span>
                                                      </p>
                                                </div>
                                                <div className="service">
                                                      <p className="label">Services</p>
                                                      {renderListServices()}
                                                      {dataNotify.service_custom &&
                                                      <p>
                                                            <span>{dataNotify.service_custom_name}</span>
                                                            <span></span>
                                                      </p>
                                                      }
                                                </div>
                                                <div className="info">
                                                      <p>
                                                            <span className='label'>Staff</span>
                                                            <span className='value'>{dataNotify.staff_name}</span>
                                                      </p>
                                                      <p>
                                                            <span className='label'>Time</span>
                                                            <span className='value'>{dataNotify.date_appointment_format}</span>
                                                      </p>
                                                      {dataNotify.status === APM_CANCEL &&
                                                            <p>
                                                                  <span className='label'>Reason</span>
                                                                  <span className='value'>{dataNotify.reason_cancel}</span>
                                                            </p>
                                                      }
                                                      {(dataNotify?.type_action === 'customer-feedback-appointment' && dataNotify.feedback?.rating) &&
                                                            <>
                                                                  <p>
                                                                        <span className='label'>Rating</span>
                                                                        <span className='value'><Rate allowHalf  disabled defaultValue={dataNotify.feedback?.rating} /></span>
                                                                  </p>
                                                                  <div className="content-feedback">
                                                                        <p>{dataNotify.feedback.content}</p>
                                                                  </div>
                                                            </>
                                                      }
                                                </div>
                                                <div className="footer">
                                                      {/*<Button onClick={() => viewDetailAppointment(data.id)}>View Detail</Button>*/}
                                                      <Button onClick={closeModal} >Close</Button>
                                                </div>
                                          </div>
                                    </div>
                              </div>

                        </div>
                        }

            </Modal>
      );
}

export default ModalNotification;