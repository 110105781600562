import React, {useEffect, useState} from 'react';
import {Button,Form, Input, Modal, TimePicker, Upload} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {toggleModalFormProfileSalon} from "../../../actions/modal";
import { PlusOutlined } from '@ant-design/icons';
import {
      convertToTZ,
      formatPhoneNumber,
      normalizeInput,
      replaceFormatPhoneLogin
} from "../../../utils/helper";
import AlertCommon from "../../../components/alert";
import {ALERT_ERROR, ALERT_SUCCESS} from "../../../constants/common";
import {updateSalon} from "../../../actions/salon";
import PlacesAutocomplete, {geocodeByAddress, getLatLng, geocodeByPlaceId} from 'react-places-autocomplete';
import moment from 'moment'
import {getTimeZoneByLocation} from "../../../actions/common";
import {useTranslation} from "react-i18next";

const ModalSalon = (props) =>  {
      const {t} = useTranslation()
      const {salon} = useSelector(state => state.auth)
      const [form] = Form.useForm()
      const dispatch = useDispatch()
      const [loading, setLoading] = useState(false)
      const {openProfileSalon} = useSelector(state => state.modal)
      const [images, setImages] = useState([])
      const [location, setLocation] = useState('')
      const [coordinates, setCoordinates] = useState({
            lat : 0,
            lng : 0,
            timezone: '',
            tz: '',
            city: null,
            state: null,
            zipcode: null,
      })
      const [refresh, setRefresh ] = useState(false)
      const [schedules, setSchedules] = useState([])
      const [phone, setPhone] = useState('')

      useEffect(() => {
            if(salon){
                  form.setFieldsValue({
                        name: salon.name,
                        email: salon.email,
                        phone: formatPhoneNumber(salon.phone),
                        address: salon.address,
                        city: salon.city,
                        state: salon.state,
                        zipcode: salon.zipcode,
                        description: salon.description,
                  })
                  setLocation(salon.address)
                  if(salon.images?.length > 0){
                        const newList = []
                        salon.images.map(item => {
                              newList.push({
                                    uid: item.id,
                                    name: item.image.split('/').pop(),
                                    status: 'done',
                                    url: item.image
                              })
                        })
                        setImages(newList)
                  }
                  const arrSchedule = []
                  salon.schedules.map(item => {
                        arrSchedule.push({
                              id: item.id,
                              day : item.day,
                              day_name : item.day_name,
                              start: item.start_time,
                              end: item.end_time
                        })
                  })
                  setSchedules(arrSchedule)
            }
      }, [salon, refresh])

      const dummyRequest = ({file, onSuccess}) => {
            setTimeout(() => {
                  onSuccess(true);
            }, 0);
      };

      const handleChangeUpload = ({ fileList: newFileList }) => {
            setImages(newFileList)
      }

      const handleSelectAddress = async value => {
            const results = await geocodeByAddress(value);

            const latLng = await getLatLng(results[0]);
            const timezoneByLocation = await getTimeZoneByLocation(latLng.lat, latLng.lng)

            const [place] = await geocodeByPlaceId(results[0].place_id)
            let city = null
            let state = null
            let zipcode = null

            if (place.address_components) {
                  let cityData = place.address_components.filter(item => item.types.join().includes('locality'))
                  if (cityData.length > 0) {
                        city = cityData[0].long_name
                  }
                  let stateData = place.address_components.filter(item => item.types.join().includes('administrative_area_level_1'))
                  if (stateData.length > 0) {
                        state = stateData[0].short_name
                  }
                  let zipcodeData = place.address_components.filter(item => item.types.join().includes('postal_code'))
                  if (zipcodeData.length > 0) {
                        zipcode = zipcodeData[0].short_name
                  }
            }

            setCoordinates({
                  lat : latLng.lat,
                  lng : latLng.lng,
                  timezone: timezoneByLocation.timeZoneId,
                  tz: `UTC ${timezoneByLocation.rawOffset / 3600}`,
                  city: city,
                  state: state,
                  zipcode: zipcode,
            })
            setLocation(results[0].formatted_address);
      };

      const onUpdateSalon = async (e) => {
            let formData = new FormData()

            const files = []
            const uidImageUpload = images.map(item => item.uid)
            e.name = e.name.trim()
            e.phone = replaceFormatPhoneLogin(phone)
            e.id = salon.id
            e.address = location
            e.lat = coordinates.lat || salon.lat
            e.lng = coordinates.lng || salon.lng
            e.country = salon.country
            const deleteImagesArr = salon.images.filter(item => !uidImageUpload.includes(item.id)).map(k => k.id)
            e.delete_images = JSON.stringify(deleteImagesArr)
            e.timezone = coordinates.timezone || salon.timezone
            e.tz =  coordinates.tz || salon.tz
            e.city =  coordinates.city || salon.city
            e.state =  coordinates.state || salon.state
            e.zipcode =  coordinates.zipcode || salon.zipcode

            const scheduleData = []
            const dateNow = moment().utc().format('YYYY-MM-DD')
            schedules.map(item => {
                  if(!item.start || !item.end){
                        scheduleData.push({
                              day : item.day,
                              start_time: null,
                              end_time: null
                        })
                  } else{
                        const convertStartTimeToUtc = convertToTZ(new Date(`${dateNow} ${item.start}`), 'UTC')
                        const convertEndTimeToUtc = convertToTZ(new Date(`${dateNow} ${item.end}`), 'UTC')
                        scheduleData.push({
                              day : item.day,
                              start_time: convertStartTimeToUtc.hour,
                              end_time: convertEndTimeToUtc.hour
                        })
                  }
            })
            e.schedules = JSON.stringify(scheduleData)

            const imageUpload = images.filter(item => item.originFileObj)
            if(imageUpload.length > 0){
                  imageUpload.map(item => files.push(item.originFileObj))
            }
            Object.keys(e).forEach(item => formData.append(item, e[item]))
            Object.keys(files).forEach((key, i) => {
                  formData.append('images', files[key]);
            });
            try {
                  setLoading(true)
                  const res = await dispatch(updateSalon(formData))
                  setLoading(false)
                  return AlertCommon(ALERT_SUCCESS, res.message)

            } catch (e) {
                  setLoading(false)
                  return AlertCommon(ALERT_ERROR, e.message)
            }

      }

      const renderFunc =  ({ getInputProps, getSuggestionItemProps, suggestions }) => {
            return (
                  <div className="autocomplete-root">
                        <Input  {...getInputProps({placeholder: t('form.placeholder_salon_address') })} />
                        <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map(suggestion => {
                                    const style = {
                                          backgroundColor : suggestion.active ? '#92278f' : '#fff' ,
                                          padding : '10px',
                                          color: suggestion.active ? '#fff' : '#000' ,
                                          cursor: 'pointer'
                                    }
                                    return (
                                          <div key={suggestion.placeId} {...getSuggestionItemProps(suggestion, { style })}>
                                                <span>{suggestion.description}</span>
                                          </div>
                                    );
                              })}
                        </div>
                  </div>
            )
      };

      const closeModal = () => {
            setRefresh(true)
            dispatch(toggleModalFormProfileSalon(false))
      }

      const onChangeTime = (time, timeString, day, type) => {
            const newSchedules = schedules
            let  index = newSchedules.findIndex(item => item.day === day)
            if(type === 'start'){
                  newSchedules[index].start = time ?  moment(time).format('HH:mm:ss') : null
            }
            if(type === 'end'){
                  newSchedules[index].end = time ? moment(time).format('HH:mm:ss') : null
            }
            setSchedules(newSchedules)
      };


      const renderScheduleSalon = () => {
            return schedules.map((item, index) =>
                  <div className="item" key={item.id}>
                        <label className='label-day'>{item.day_name}</label>
                        <div className="input-time">
                              <Form.Item>
                                    <TimePicker minuteStep={15} onChange={(time, timeString) => onChangeTime(time, timeString, item.day, 'start')} defaultValue={item.start ? moment(item.start, 'HH:mm:ss') : null} popupClassName='dropdown-schedule'  showNow={false} placeholder={t('form.placeholder_open_time')} format='hh:mm A' />
                              </Form.Item>
                              <span>to</span>
                              <Form.Item>
                                    <TimePicker minuteStep={15} onChange={(time, timeString) => onChangeTime(time, timeString, item.day, 'end')} defaultValue={item.end ? moment(item.end, 'HH:mm:ss') : null} popupClassName='dropdown-schedule' showNow={false} placeholder={t('form.placeholder_close_time')} format='hh:mm A' />
                              </Form.Item>
                        </div>
                  </div>

            )
      }

      const handleChangePhone = (e) => {
            return setPhone(prevState => normalizeInput(e.target.value, prevState))
      }

      return (
            <Modal
                  footer={null}
                  visible={openProfileSalon}
                  maskClosable={false}
                  closable={false}
                  centered
                  className='modal-general modal-salon modal-table'
            >
                  <div className="head-modal">
                        <p className="title">{t('modal.title_salon')}</p>
                        <Button onClick={closeModal}>{t('button.close')}</Button>
                  </div>
                  <div className="body-modal">
                        <div className="wp-form">
                              <Form
                                    form={form}
                                    layout='vertical'
                                    name="update-salon-hooks"
                                    onFinish={onUpdateSalon}
                              >
                                    <Form.Item name="name" label={t('form.label_salon_name')}>
                                          <Input placeholder={t('form.placeholder_salon_name')} />
                                    </Form.Item>
                                    <Form.Item name="email" label={t('form.label_email')}>
                                          <Input placeholder={t('form.placeholder_salon_email')}/>
                                    </Form.Item>
                                    <Form.Item label={t('form.label_phone')}>
                                          <Input placeholder={t('form.placeholder_salon_phone')} value={phone} onChange={handleChangePhone} />
                                    </Form.Item>

                                    <Form.Item  label={t('form.label_address')}>
                                          <PlacesAutocomplete value={location}
                                                              onChange={value =>  {
                                                                    setLocation(value)
                                                              }}
                                                              searchOptions={{
                                                                    types: ["geocode", "establishment"],
                                                                    componentRestrictions: { country: ['us'] },

                                                              }}
                                                              onSelect={handleSelectAddress}
                                          >
                                                {renderFunc}
                                          </PlacesAutocomplete>
                                    </Form.Item>

                                    <Form.Item label={t('form.label_images')}>
                                          <Upload
                                                className='upload-image-salon'
                                                listType="picture-card"
                                                fileList={images}
                                                onChange={handleChangeUpload}
                                                customRequest={dummyRequest}
                                                accept=".jpg,.jpeg,.png"
                                                showUploadList={{showPreviewIcon: false, showRemoveIcon: true}}
                                          >
                                                {images.length >= 10 ? null : <PlusOutlined />}
                                          </Upload>
                                    </Form.Item>
                                    <Form.Item label={`${t('form.label_business')} (${salon?.tz} )`}>
                                          <div className="business-hour">
                                                {/*<div className="note">*/}
                                                {/*      <p className="label">{t('form.label_note')}</p>*/}
                                                {/*      <ul>*/}
                                                {/*            <li>If you leave it blank, it's a day off</li>*/}
                                                {/*            <li>Please fill in the start time and end time, if not complete, we will update it as the shop's day off.</li>*/}
                                                {/*      </ul>*/}
                                                {/*</div>*/}
                                                {renderScheduleSalon()}
                                          </div>
                                    </Form.Item>
                                    <Form.Item name="description" label={t('form.label_description')}>
                                          <Input.TextArea
                                                placeholder={t('form.placeholder_description')}
                                                autoSize={{ minRows: 4}}
                                          />
                                    </Form.Item>

                                    <Form.Item>
                                          <Button loading={loading} type="primary" htmlType="submit">
                                                {t('button.update')}
                                          </Button>
                                    </Form.Item>
                              </Form>
                        </div>
                  </div>
            </Modal>
      );
}

export default ModalSalon;