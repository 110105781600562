import {
      CREATE_APPOINTMENT,
      LIST_APPOINTMENT_IN_DASHBOARD,
      SET_APPOINTMENT_UPDATE,
      TOGGLE_FORM_SAVE_APPOINTMENT,
      RECEIVE_APPOINTMENT_FROM_CLIENT,
      UPDATE_APPOINTMENT,
      REMOVE_APPOINTMENT, SET_APPOINTMENT_SELECTED, OPEN_APPOINTMENT_DETAIL, CUSTOMER_ADD_NEW_APPOINTMENT
} from '../constants/action';

const initialState = {appointments: [], appointmentEdit : null, openFormSaveAppointment : false, appointmentSelected : null, openAppointmentDetail : false}

const dashboardReducer = (state = initialState, action) =>  {
      const {type, payload} = action
      switch (type) {
            case LIST_APPOINTMENT_IN_DASHBOARD:
                  return {
                        ...state,
                        appointments: payload,
                  };
            case CREATE_APPOINTMENT:
                  return {
                        ...state,
                        appointments: [payload].concat(state.appointments),
                  };
            case TOGGLE_FORM_SAVE_APPOINTMENT:
                  return {
                        ...state,
                        openFormSaveAppointment : payload
                  };
            case SET_APPOINTMENT_UPDATE:
                  return {
                        ...state,
                        appointmentEdit: payload,
                  };
            case RECEIVE_APPOINTMENT_FROM_CLIENT:
                  return {
                        ...state,
                        appointments: [payload].concat(state.appointments),
                  };
            case UPDATE_APPOINTMENT:
                  let newList = state.appointments
                  let index = newList.findIndex(item => item.id === payload.id)
                  newList[index] = payload
                  let newAppointmentEdit = state.appointmentEdit ? payload : null
                  let newAppointmentSelected = state.appointmentSelected ? payload : null
                  return {
                        ...state,
                        appointments: newList,
                        appointmentEdit: newAppointmentEdit,
                        appointmentSelected: newAppointmentSelected,
                  };
            case REMOVE_APPOINTMENT:
                  return {
                        ...state,
                        appointments: state.appointments.filter(item => item.id !== payload),
                  };
            case SET_APPOINTMENT_SELECTED:
                  return {
                        ...state,
                        appointmentSelected: payload,
                  };
            case OPEN_APPOINTMENT_DETAIL:
                  return {
                        ...state,
                        openAppointmentDetail : payload
                  };
            case CUSTOMER_ADD_NEW_APPOINTMENT:
                  return {
                        ...state,
                        appointments: [payload].concat(state.appointments),
                  };
            default:
                  return state;
      }
}

export default dashboardReducer;
