import axiosInstance from "./axiosInstance";

const base_path = '/notification'
const notificationApi = {
      getListNotification(data){
            const url = `${base_path}/salon`
            return axiosInstance.post(url, data)
      },
      readNotification(id){
            const url = `${base_path}/read`
            return axiosInstance.post(url, {id})
      },
      unreadNotification(id){
            const url = `${base_path}/mark-as-unread`
            return axiosInstance.post(url, {id})
      },
      deleteNotification(arr){
            const url = `${base_path}/delete`
            return axiosInstance.post(url, {ids: arr})
      },
      readAllNotification(){
            const url = `${base_path}/read-all-of-salon`
            return axiosInstance.post(url)
      },
      deleteAllNotification(){
            const url = `${base_path}/delete-all-of-salon`
            return axiosInstance.post(url)
      },
}

export default notificationApi