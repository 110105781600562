import voucherApi from "../api/voucherApi";

export const checkVoucher = (data) => {
      return new Promise((resolve, reject) => {
            voucherApi.checkVoucher(data).then(
                  (res) => {
                        return resolve(res)
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
}