import React, {useEffect, useState} from 'react';
import {Button, Form, Input, Modal, Radio } from "antd";
import {useDispatch} from "react-redux";
import {ALERT_ERROR, ALERT_SUCCESS} from "../../../constants/common";
import AlertCommon from "../../../components/alert";
import {updateInfoCustomer} from "../../../actions/salon";
import '../../../stylesheets/update-customer-info.scss'
import {useTranslation} from "react-i18next";

const ModalUpdateCustomer = ({visible, customer, closeModal}) => {
      const {t} = useTranslation()
      const [loading, setLoading] = useState(false)
      const dispatch = useDispatch()
      const [form] = Form.useForm()

      const typeOptions = [
            {
                  label: 'Normal',
                  value: 2,
            },
            {
                  label: 'Vip',
                  value: 3,
            },
      ];

      useEffect(() => {
            if(customer){
                  form.setFieldsValue({
                        type: customer.type,
                        note: customer.note,
                  })
            }
      }, [customer])

      const onUpdateCustomer = async (e) => {
            setLoading(true)
            let dataUpdate = {
                  id: customer.id,
                  type: e.type,
                  note: e.note
            }
            try {
                  const res = await dispatch(updateInfoCustomer(dataUpdate))
                  setLoading(false)
                  AlertCommon(ALERT_SUCCESS, res.message)
                  closeModal()
            } catch (e) {
                setLoading(false)
                  return AlertCommon(ALERT_ERROR, e.message)
            }
      }

      return (
            <Modal
                  footer={null}
                  visible={visible}
                  maskClosable={false}
                  closable={false}
                  centered
                  className='modal-general modal-update-info-customer'
            >
                  <div className="head-modal">
                        <p className="title">{t('modal.title_update_customer')}</p>
                        <Button onClick={() => closeModal()}>{t('button.close')}</Button>
                  </div>
                  <div className="body-modal">
                        <div className="staffs">
                              <div className="form-wp">
                                    <Form
                                          form={form}
                                          layout='vertical'
                                          name="update-customer-hooks"
                                          onFinish={onUpdateCustomer}
                                    >

                                          <Form.Item name="type" label={t('form.label_type')}>
                                                <Radio.Group
                                                      options={typeOptions}
                                                      optionType="button"
                                                      buttonStyle="solid"
                                                />
                                          </Form.Item>

                                          <Form.Item name="note">
                                                <Input.TextArea
                                                      placeholder='Enter note'
                                                      autoSize={{ minRows: 3, maxRows: 7 }}
                                                />
                                          </Form.Item>

                                          <Form.Item>
                                                <Button loading={loading} type="primary" htmlType="submit">
                                                      {t('button.update')}
                                                </Button>
                                          </Form.Item>
                                    </Form>
                              </div>
                        </div>
                  </div>
            </Modal>
      );
}

export default ModalUpdateCustomer;