import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import {Spin} from "antd";
import {useTranslation} from "react-i18next";

function LoadingData(props) {
      const {t} = useTranslation()
      return (
            <div className='wp-loading-data'>
                  <Spin tip={t('alert.loading_data')} indicator={<LoadingOutlined spin />} />
            </div>
      );
}

export default LoadingData;