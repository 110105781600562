import customerApi from "../api/customerApi";
import userApi from "../api/userApi";
import {DELETE_CUSTOMER, LIST_CUSTOMER, UPDATE_PROFILE_CUSTOMER} from '../constants/action'

export const getListCustomer = () => (dispatch) => {
      return new Promise((resolve, reject) => {
            customerApi.listCustomers().then(
                  (res) => {
                        dispatch({
                              type: LIST_CUSTOMER,
                              payload: res.data,
                        });
                        resolve(res)
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
}

export const deleteCustomer = (id) => (dispatch) => {
      return new Promise((resolve, reject) => {
            customerApi.deleteCustomer(id).then(
                  (res) => {
                        dispatch({
                              type: DELETE_CUSTOMER,
                              payload: id,
                        });
                        resolve(res)
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
}


export const listAppointmentForCustomer = (data) => {
      return new Promise((resolve, reject) => {
            customerApi.listAppointmentForCustomer(data).then(
                  (res) => {
                       return resolve(res)
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
}


export const updateProfileCustomer = (formData) => (dispatch) => {
      return new Promise((resolve, reject) => {
            userApi.updateProfileCustomer(formData).then(
                  (res) => {
                        dispatch({
                              type: UPDATE_PROFILE_CUSTOMER,
                              payload: res.data,
                        });
                        resolve(res)
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
}

