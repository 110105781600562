import axiosInstance from "./axiosInstance";

const base_path = '/user'
const userApi = {
      updateProfileCustomer(formData){
            const url = `${base_path}/update-profile`
            return axiosInstance.post(url, formData)
      },
}

export default userApi