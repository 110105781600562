import {
      CREATE_STAFF,
      DELETE_STAFF,
      LIST_STAFF, LIST_STAFF_IN_BOOKING, OPEN_FORM_EDIT_STAFF, SET_STAFF_EDIT,
      SET_STAFF_SELECTED,
      SHOW_STAFF_PANEL, UPDATE_STAFF
} from '../constants/action';

const initialState = {staffs: [], staffInBooking: [], staffSelected : null, showStaffPanel: false, staffEdit: null, openFormEditStaff : null}

const staffReducer = (state = initialState, action) =>  {
      const {type, payload} = action
      switch (type) {
            case LIST_STAFF:
                  return {
                        ...state,
                        staffs: payload,
                  };
            case CREATE_STAFF:
                  return {
                        ...state,
                        staffs: [payload].concat(state.staffs)
                  };
            case UPDATE_STAFF:
                  let newList = state.staffs
                  let index = newList.findIndex(item => item.id === payload.id)
                  newList[index] = payload
                  let newStaffSelected = state.staffSelected ? payload : null
                  let newStaffEdit = state.staffEdit ? payload : null
                  return {
                        ...state,
                        staffs: newList,
                        staffSelected : newStaffSelected,
                        staffEdit : newStaffEdit,
                  };
            case DELETE_STAFF:
                  return {
                        ...state,
                        staffs: state.staffs.filter(item => item.id !== payload)
                  };
            case SET_STAFF_SELECTED:
                  return {
                        ...state,
                        staffSelected: payload
                  };
            case SHOW_STAFF_PANEL:
                  return {
                        ...state,
                        showStaffPanel: payload
                  };
            case SET_STAFF_EDIT:
                  return {
                        ...state,
                        staffEdit: payload
                  };
            case OPEN_FORM_EDIT_STAFF:
                  return {
                        ...state,
                        openFormEditStaff: payload
                  };
            case LIST_STAFF_IN_BOOKING:
                  return {
                        ...state,
                        staffInBooking: payload
                  };
            default:
                  return state;
      }
}

export default staffReducer;
