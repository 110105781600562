import {
      CREATE_SERVICE,
      DELETE_SERVICE,
      LIST_SERVICE, LIST_SERVICE_IN_BOOKING,
      OPEN_FORM_EDIT_SERVICE,
      SET_SERVICE_EDIT, UPDATE_SERVICE
} from '../constants/action';

const initialState = {services: [], serviceInBooking: [], serviceEdit: null, openFormEditService: false}

const serviceReducer = (state = initialState, action) =>  {
      const {type, payload} = action
      switch (type) {
            case LIST_SERVICE:
                  return {
                        ...state,
                        services: payload,
                  };
            case CREATE_SERVICE:
                  return {
                        ...state,
                        services: [payload].concat(state.services),
                  };
            case DELETE_SERVICE:
                  return {
                        ...state,
                        services: state.services.filter(item => item.id !== payload),
                  };
            case SET_SERVICE_EDIT:
                  return {
                        ...state,
                        serviceEdit: payload
                  };
            case OPEN_FORM_EDIT_SERVICE:
                  return {
                        ...state,
                        openFormEditService: payload
                  };
            case UPDATE_SERVICE:
                  let newList = state.services
                  let index = newList.findIndex(item => item.id === payload.id)
                  newList[index] = payload
                  let newServiceEdit = state.serviceEdit ? payload : null
                  return {
                        ...state,
                        services: newList,
                        serviceEdit : newServiceEdit,
                  };
            case LIST_SERVICE_IN_BOOKING:
                  return {
                        ...state,
                        serviceInBooking : payload,
                  };
            default:
                  return state;
      }
}

export default serviceReducer;
