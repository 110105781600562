import React from 'react';
import '../../stylesheets/support.scss'
import logo from '../../assets/images/logo.png'

function SupportLayout({children}) {
      return (
            <div className='support-content'>
                  <div className="widget">
                        <div className="widget-head">
                              <a href="#" className="thumb">
                                    <img src={logo} alt=""/>
                              </a>
                              <h3 className="title">123 Best Solutions</h3>
                        </div>
                        <div className="widget-body">
                              {children}
                        </div>
                  </div>
            </div>
      );
}

export default SupportLayout;