import React, {useEffect} from 'react';
import {Button,  Modal} from "antd";
import '../../../stylesheets/detail.scss'
import {useTranslation} from "react-i18next";

const CustomerDetail = ({visible, customer, closeModal}) =>  {
      const {t} = useTranslation()
      useEffect(() => {
            if(!customer){
                  closeModal()
            }
      }, [customer])

      return (
            <Modal
                  footer={null}
                  visible={visible}
                  maskClosable={false}
                  closable={false}
                  centered
                  className='modal-general modal-detail'
            >
                  {customer &&
                        <React.Fragment>
                              <div className="head-modal">
                                    <div className='left'>
                                          <p className="title">{t('modal.title_customer_detail')}</p>
                                    </div>
                                    <Button onClick={() => closeModal()}>{t('button.close')}</Button>
                              </div>
                              <div className="body-modal">
                                    <div className="detail">
                                          <p>
                                                <span>ID</span>
                                                <span>#{customer.id}</span>
                                          </p>
                                          <p>
                                                <span>{t('table.column_name')}</span>
                                                <span>{customer.name}</span>
                                          </p>
                                          <p>
                                                <span>{t('table.column_phone')}</span>
                                                <span>{customer.phone_format}</span>
                                          </p>
                                          <p>
                                                <span>{t('table.column_email')}</span>
                                                <span className={`text ${customer.email ? '' : 'no-info'}`}>{customer.email ? customer.email : t('alert.no_info')}</span>
                                          </p>
                                          <p>
                                                <span>{t('table.column_address')}</span>
                                                <span className={`text ${customer.address ? '' : 'no-info'}`}>{customer.address ? customer.address : t('alert.no_info')}</span>
                                          </p>
                                    </div>
                              </div>
                        </React.Fragment>
                  }
            </Modal>
      );
}

export default CustomerDetail;