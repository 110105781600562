import moment from 'moment-timezone'

export const checkObjectEmptyOrNull = (object) => {
      return  !object || (object &&  Object.keys(object).length === 0)
}

export const normalizeInput  = (value, previousValue) => {
      // return nothing if no value
      if (!value) return value;
      // only allows 0-9 inputs
      const currentValue = value.replace(/[^\d]/g, '');
      const cvLength = currentValue.length;

      if (!previousValue || value.length > previousValue.length) {

            // returns: "x", "xx", "xxx"
            if (cvLength < 4) return currentValue;

            // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
            if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;

            // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
            return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
      } else{
            return
      }
}

export const replaceFormatPhoneLogin  = (str) => {
      /* eslint-disable no-useless-escape */
      return str ? str.replace(/([\ \/\,\!\\\^\$\{\}\[\]\(\)\.\*\+\?\|\<\>\-\&])/g,'') : null
}

export const formatPhoneNumber = (phone) =>  {
      const cleaned = ('' + phone).replace(/\D/g, '');
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
      }
      return null;
}

export const getFirstDayWeek = () => {
      const curr = new Date();
      let date =  new Date(curr.setDate(curr.getDate() - curr.getDay() + 1));
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
}

export const getLastDayWeek = () => {
      const curr = new Date();
      let date =  new Date(curr.setDate(curr.getDate() - curr.getDay() + 7 ));
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
}

export const convertTZLocal = (date) => {
      if(!date) return null
      // const input = moment(date).format('YYYY-MM-DD HH:mm:ss')
      const fmt   = "YYYY-MM-DD HH:mm:ss";  // must match the input
      const tz = 'UTC'
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      const m = moment.tz(date, fmt, tz)
      const s = m.tz(timezone)
      return  {
            time : s.format(),
            format: s.format('LLL'),
            timestamp : new Date(s.format()).getTime(),
            day: s.day(),
            hour: s.format('hh:mm a'),
            hourA: s.format('hh:mm A')
      }
}

export const convertToTZ = (date, tz) => {
      if(!date) return null
      const input = moment(date).format('YYYY-MM-DD HH:mm:ss')
      const fmt  = "YYYY-MM-DD HH:mm:ss";  // must match the input
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      const m = moment.tz(input, fmt, timezone)
      const s = m.tz(tz)
      return  {
            time : s.format(),
            format: s.format('LLL'),
            timestamp : new Date(s.format()).getTime(),
            day: s.day(),
            hour: s.format('HH:mm:ss'),
            hour_format: s.format('hh:mm a')
      }
}

export const getTimeZoneLocal = () => {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      const offset = new Date().getTimezoneOffset()
      return {
            timezone,
            offset
      }
}

export const getBase64 = (img, callback) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result));
      reader.readAsDataURL(img);
};