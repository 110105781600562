import React from 'react';

const  Softkeys = ({onClickSoftKey}) =>  {

      const onClickKey = (key) => {
            onClickSoftKey(key)
      }

      return (
            <div className='wp-softkey'>
                  <div className="widget">
                        <div className="widget-head">
                              <h3 className="title">Please enter your phone number</h3>
                        </div>
                        <div className="widget-body">
                              <ul className="softkeys">
                                    <li onClick={() => onClickKey(1)}><span>1</span></li>
                                    <li onClick={() => onClickKey(2)}><span>2</span></li>
                                    <li onClick={() => onClickKey(3)}><span>3</span></li>
                                    <li onClick={() => onClickKey(4)}><span>4</span></li>
                                    <li onClick={() => onClickKey(5)}><span>5</span></li>
                                    <li onClick={() => onClickKey(6)}><span>6</span></li>
                                    <li onClick={() => onClickKey(7)}><span>7</span></li>
                                    <li onClick={() => onClickKey(8)}><span>8</span></li>
                                    <li onClick={() => onClickKey(9)}><span>9</span></li>
                                    <li onClick={() => onClickKey(0)}><span>0</span></li>
                                    <li  onClick={() => onClickKey('submit')}><span className='key-submit'>OK</span></li>
                                    <li onClick={() => onClickKey('delete')}><span ><i className="fa-solid fa-delete-left"></i></span></li>
                              </ul>
                        </div>
                  </div>
            </div>
      );
}

export default Softkeys;