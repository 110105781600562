import React, {useEffect, useState} from 'react';
import {Button, Form, Input, Modal, Upload} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {ALERT_ERROR, ALERT_SUCCESS} from "../../../constants/common";
import AlertCommon from "../../../components/alert";
import {setOpenFormEditStaff, setStaffEdit, updateStaff} from "../../../actions/staff";
import {formatPhoneNumber, normalizeInput, replaceFormatPhoneLogin} from "../../../utils/helper";
import {useTranslation} from "react-i18next";

const ModalUpdateStaff = (props) => {
      const {t} = useTranslation()
      const [loading, setLoading] = useState(false)
      const {staffEdit, openFormEditStaff} = useSelector(state => state.staff)
      const dispatch = useDispatch()
      const [form] = Form.useForm()
      const [phone, setPhone] = useState('')
      const [avatar, setAvatar] = useState(null)

      useEffect(() => {
            if(staffEdit){
                  form.setFieldsValue({
                        first_name: staffEdit.first_name,
                        last_name: staffEdit.last_name,
                  })
                  setPhone(formatPhoneNumber(staffEdit.phone))
                  setAvatar(staffEdit.avatar ? {id : staffEdit.id , image : staffEdit.avatar} : null)
            }
      }, [staffEdit, openFormEditStaff])

      const handleChangePhone = (e) => {
            return setPhone(prevState => normalizeInput(e.target.value, prevState))
      }

      const getBase64 = (img, callback) => {
            const reader = new FileReader();
            reader.addEventListener('load', () => callback(reader.result));
            reader.readAsDataURL(img);
      };

      const handleChangeUpload = (info) => {
            if (info.file.status === 'done') {
                  // Get this url from response in real world.
                  getBase64(info.file.originFileObj, (url) => {
                        setLoading(false);
                        let newFile = info.file
                        newFile.image = url
                        setAvatar(info.file)
                  });
            }
      }

      const dummyRequest = ({file, onSuccess}) => {
            setTimeout( () => onSuccess(true), 0)
      }

      const onSaveStaff = async (e) => {
            try {
                  if(!staffEdit) return
                  setLoading(true)
                  let formData = new FormData()
                  e.phone = replaceFormatPhoneLogin(phone)
                  e.id = staffEdit.id
                  if(avatar && avatar.originFileObj){
                        e.avatar = avatar.originFileObj
                  }
                  Object.keys(e).forEach( function (key) {
                        if(typeof e[key] === 'string'){
                              e[key] = e[key] ? e[key].trim() : null;
                        }
                        formData.append(key, e[key])
                  });
                  let response = await dispatch(updateStaff(formData))
                  setLoading(false)
                  AlertCommon(ALERT_SUCCESS, response.message)
                  form.resetFields()
                  dispatch(setStaffEdit(response.data))
                  dispatch(setOpenFormEditStaff(false))
            } catch (e) {
                  setLoading(false)
                  return AlertCommon(ALERT_ERROR, e.message)
            }
      }

      const handleCloseModal = () => {
            dispatch(setStaffEdit(null))
            return dispatch(setOpenFormEditStaff(false))
      }

      return (
            <Modal
                  footer={null}
                  visible={openFormEditStaff}
                  maskClosable={false}
                  closable={false}
                  centered
                  className='modal-general modal-staff'
            >
                  <div className="head-modal">
                        <p className="title">{t('modal.title_update_staff')}</p>
                        <Button onClick={handleCloseModal}>{t('button.close')}</Button>
                  </div>
                  <div className="body-modal">
                        <div className="staffs">
                              <div className="form-wp">
                                    <Form
                                          form={form}
                                          layout='vertical'
                                          name="control-hooks"
                                          onFinish={onSaveStaff}
                                    >

                                          <div className="form-staff">
                                                <div className="left">
                                                      <Form.Item>
                                                            <Upload
                                                                  className='upload-avatar-staff'
                                                                  listType="picture-card"
                                                                  onChange={handleChangeUpload}
                                                                  customRequest={dummyRequest}
                                                                  accept=".jpg,.jpeg,.png"
                                                                  showUploadList={false}
                                                            >
                                                                  {avatar ? <img src={avatar.image} alt="avatar" style={{width: '100%',}}
                                                                  /> : <i className="fa-regular fa-image"></i>}
                                                            </Upload>
                                                      </Form.Item>
                                                </div>
                                                <div className="right">
                                                      <Form.Item name="first_name">
                                                            <Input placeholder={t('form.placeholder_first_name')} />
                                                      </Form.Item>
                                                      <Form.Item name="last_name">
                                                            <Input placeholder={t('form.placeholder_last_name')} />
                                                      </Form.Item>
                                                      <Form.Item>
                                                            <Input value={phone} onChange={handleChangePhone} placeholder={t('form.placeholder_phone')} />
                                                      </Form.Item>
                                                </div>
                                          </div>

                                          <Form.Item>
                                                <Button loading={loading} type="primary" htmlType="submit">
                                                      {t('button.update')}
                                                </Button>
                                          </Form.Item>
                                    </Form>
                              </div>
                        </div>
                  </div>
            </Modal>
      );
}

export default ModalUpdateStaff;