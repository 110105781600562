import React, {useEffect} from 'react';
import '../../stylesheets/auth.scss'
import {useLocation, useNavigate} from 'react-router-dom'
import {useSelector} from "react-redux";
import CustomerChangePasswordModal from "./components/customer-change-password";
import AdminEnterPasswordModal from "./components/admin-enter-password";

const AuthFeature = (props) => {
      const navigate = useNavigate()
      const location = useLocation()
      const {is_logged} = useSelector(state => state.auth)

      useEffect(() => {
            if(is_logged && location.pathname !== '/customer-checkin'){
                  return navigate('/dashboard')
            }
      }, [])
      return (
            <div className='auth-page'>
                  {props.children}

                  <AdminEnterPasswordModal/>
            </div>
      );
}

export default AuthFeature;