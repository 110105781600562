import React, {useEffect} from 'react';
import {Button, Form, Input, Modal, Radio} from "antd";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const ModalChooseStaffAppointment = ({visible, appointment, closeModal, loading, handleData}) => {
      const [form] = Form.useForm()
      const {staffInBooking} = useSelector(state => state.staff)
      const {t} = useTranslation()

      useEffect(() => {
            form.setFieldsValue({
                  staff_id: appointment?.staff_id || null,
                  work_time: appointment?.work_time || null
            })
      }, [appointment])

      const renderListStaff = () => {
            return staffInBooking.map(item =>
                  <Radio.Button key={item.id} value={item.id}>{item.name}</Radio.Button>
            )
      }

      const handleSelectData = (e) => {
            let formData = {
                  staff_id: e.staff_id || null,
                  work_time: e.work_time || null
            }
            handleData(formData)
      }

      return (
            <Modal
                  footer={null}
                  visible={visible}
                  maskClosable={false}
                  closable={false}
                  centered
                  className='modal-general modal-choose-staff'
            >
                  <div className="head-modal">
                        <p className="title">{t('modal.title_choose_staff')}</p>
                        <Button onClick={() => {
                              form.resetFields()
                              closeModal()
                        }}>{t('button.close')}</Button>
                  </div>
                  <div className="body-modal">
                        <div className="form-wp">
                              <Form
                                    form={form}
                                    layout='vertical'
                                    name="control-hooks"
                                    onFinish={handleSelectData}
                              >

                                    <Form.Item label={t('form.label_staff')} name="staff_id">
                                          <Radio.Group className='list-staffs'>
                                                {renderListStaff()}
                                          </Radio.Group>
                                    </Form.Item>
                                    <Form.Item label={t('form.label_work_time')} name="work_time">
                                          <Input placeholder={t('form.placeholder_work_time')} />
                                    </Form.Item>

                                    <Form.Item>
                                          <Button loading={loading} type="primary" htmlType="submit">
                                                {t('button.submit')}
                                          </Button>
                                    </Form.Item>
                              </Form>
                        </div>
                  </div>
            </Modal>
      );
}

export default ModalChooseStaffAppointment;