import React from 'react';
import {Button, Popconfirm} from 'antd';
import {DeleteOutlined, FormOutlined} from '@ant-design/icons';
import {TABLE_SERVICE} from "../../constants/common";

function useActionMenu({type, selectedRow, updateType}) {
      const handleSingleDelete = async () => {

      };

      const handleEditRow = async () => {
            console.log('type', type);
      }

      const handleCheckIn = async () => {
            console.log('type', type);
      }

      const handleCheckOut = async () => {
            console.log('type', type);
      }

      const actionColumnView = (
            <div className='action-row'>
                  {type === TABLE_SERVICE &&
                  <Button className='edit-row-btn' onClick={handleEditRow}><FormOutlined/></Button>
                  }

                  <Popconfirm
                        title="Are you sure to delete this?"
                        placement="topRight"
                        onConfirm={handleSingleDelete}
                        // icon={<QuestionCircleOutlined style={{color: 'red'}}/>}
                        okText="Delete"
                        cancelText="Cancel"
                  >
                        <Button className='delete-row-btn'><DeleteOutlined/></Button>
                  </Popconfirm>
            </div>
      );

      return [actionColumnView];
}

export default useActionMenu;
