import React, {useState} from 'react';
import {Button, Form, Input, Modal} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {ALERT_ERROR, ALERT_SUCCESS} from "../../../constants/common";
import AlertCommon from "../../../components/alert";
import {toggleModalRejectAppointment} from "../../../actions/modal";
import {handleAppointment,
      setAppointmentSelected,
} from "../../../actions/appointment";
import {useTranslation} from "react-i18next";

const ModalRejectAppointment = (props) => {
      const {t} = useTranslation()
      const dispatch = useDispatch()
      const [form] = Form.useForm()
      const [loading, setLoading] = useState(false)
      const {openReject} = useSelector(state => state.modal)
      const {appointmentSelected} = useSelector(state => state.dashboard)

      const onCancelAppointment = async (e) => {
            if (!appointmentSelected) return
            try {
                  setLoading(true)
                  let formData = {
                        id : appointmentSelected.id,
                        is_accepted : 0,
                        reason: e.reason
                  }
                  let response = await dispatch(handleAppointment(formData))
                  setLoading(false)
                  form.resetFields()
                  AlertCommon(ALERT_SUCCESS, response.message)
                  dispatch(setAppointmentSelected(null))
                  dispatch(toggleModalRejectAppointment(false))
            } catch (e) {
                  setLoading(false)
                  return AlertCommon(ALERT_ERROR, e.message)
            }

      }

      const handleCloseModal = () => {
            dispatch(setAppointmentSelected(null))
            return dispatch(toggleModalRejectAppointment(false))
      }

      return (
            <Modal
                  footer={null}
                  visible={openReject}
                  maskClosable={false}
                  closable={false}
                  centered
                  className='modal-general modal-cancel-appointment'
            >
                  <div className="head-modal">
                        <p className="title">{t('modal.title_cancel_appointment')}</p>
                        <Button onClick={handleCloseModal}>{t('button.close')}</Button>
                  </div>
                  <div className="body-modal">
                        <div className="staffs">
                              <div className="form-wp">
                                    <Form
                                          form={form}
                                          layout='vertical'
                                          name="control-hooks"
                                          onFinish={onCancelAppointment}
                                    >

                                          <Form.Item name="reason" label={t('form.label_reason_reject')}>
                                                <Input.TextArea
                                                      autoSize={{ minRows: 3, maxRows: 5 }}
                                                />
                                          </Form.Item>

                                          <Form.Item>
                                                <Button loading={loading} type="primary" htmlType="submit">
                                                      {t('button.submit')}
                                                </Button>
                                          </Form.Item>
                                    </Form>
                              </div>
                        </div>
                  </div>
            </Modal>
      );
}

export default ModalRejectAppointment;