import React, {useEffect, useState} from 'react';
import AuthFeature from "../index";
import {Button, Form, Input, Modal} from "antd";
import Softkeys from "../components/softkeys";
import {customerCheckIn} from '../../../actions/auth'
import {useDispatch, useSelector} from "react-redux";
import AlertCommon from "../../../components/alert";
import {ALERT_ERROR, ALERT_SUCCESS} from '../../../constants/common'
import { useNavigate } from "react-router-dom";
import {normalizeInput, replaceFormatPhoneLogin} from "../../../utils/helper";
import {toggleModalEnterPasswordAdmin, toggleModalProfileCustomer} from "../../../actions/modal";
import '../../../stylesheets/checkin.scss'

const CustomerCheckIn = (props) => {
      const [form] = Form.useForm();
      let navigate = useNavigate();
      const dispatch = useDispatch()
      const {customer, salon} = useSelector(state => state.auth)
      const [loading, setLoading] = useState(false)
      const [initValueForm , setInitValueForm] = useState({
            phone : '',
      })

      useEffect(() => {
            if(customer){
                  return navigate('/booking')
            }
      })

      const onSignIn = async (e) => {
            try {
                  setLoading(true)
                  initValueForm.phone = replaceFormatPhoneLogin(initValueForm.phone)
                  let response = await dispatch(customerCheckIn(initValueForm))
                  setLoading(false)
                  AlertCommon(ALERT_SUCCESS, response.message)
                  if(!response.data.user.is_new){
                        return navigate(`/booking`);
                  }
                  navigate(`/booking`);
                  Modal.confirm({
                        className: 'modal-notify-new-member',
                        wrapClassName: 'popup-new-member-wrap',
                        footer: 'Ok',
                        content: <div>
                              <p>Hello, new member!</p>
                              <p>We are pleased to provide you with an automated account so that you can log in to your account to view service history or book an appointment.</p>
                              <p>The default password will be your phone number. You can change it while logged in. Thank you!</p>
                        </div>,
                        onOk() {
                              dispatch(toggleModalProfileCustomer(true))

                        }
                  });

            } catch (e) {
                  localStorage.removeItem('admin-data')
                  setLoading(false)
                  return  AlertCommon(ALERT_ERROR, e.message)
            }
      }

      const handleChangePhone = (e) => {
            setInitValueForm(prevState  => ({
                  ...prevState,
                  phone: normalizeInput(e.target.value, prevState.phone)
            }))
      }

      const onClickSoftKey = async (key) => {
            let phoneCurrent = initValueForm.phone
            if(key === 'delete' && !phoneCurrent) return

            if(key === 'delete'){
                  phoneCurrent = phoneCurrent.substr(0, phoneCurrent.length - 1)
            }
            let arr = Array.from({length: 10}, (_, i) => i);
            if(arr.includes(key)){
                  phoneCurrent =  normalizeInput(phoneCurrent.concat(key), phoneCurrent)
            }

            setInitValueForm((prevState => {
                  return {...prevState , ...{phone: phoneCurrent}}
            }))
            form.setFieldsValue({ phone: phoneCurrent });

            if(key === 'submit'){
                  try {
                        setLoading(true)
                        initValueForm.phone = replaceFormatPhoneLogin(initValueForm.phone)
                        let response = await dispatch(customerCheckIn(initValueForm))
                        AlertCommon(ALERT_SUCCESS, response.message)
                        if(!response.data.user.is_new){
                              return navigate(`/booking`);
                        }
                        navigate(`/booking`);
                        Modal.confirm({
                              className: 'modal-notify-new-member',
                              wrapClassName: 'popup-new-member-wrap',
                              footer: 'Ok',
                              content: <div>
                                    <p>Hello, new member!</p>
                                    <p>We are pleased to provide you with an automated account so that you can log in to your account to view service history or book an appointment.</p>
                                    <p>The default password will be your phone number. You can change it while logged in. Thank you!</p>
                              </div>,
                              onOk() {
                                    dispatch(toggleModalProfileCustomer(true))
                              }
                        });
                  } catch (e) {
                        setLoading(false)
                        return  AlertCommon(ALERT_ERROR, e.message)
                  }
            }
      }

      const backToAdmin = () => {
            dispatch(toggleModalEnterPasswordAdmin(true))
      }

      return (
            <AuthFeature>
                  <div className='check-in-page'>
                        <div className="wp-inner container wp-flex">
                              <div className="left">
                                    <Softkeys onClickSoftKey={onClickSoftKey} />
                              </div>
                              <div className="right">
                                    <div className="wp-form">
                                          <h3 className="title-form">
                                                <Button className='owner-logout' onClick={backToAdmin}>
                                                      <i className="fa-solid fa-arrow-left-long"></i>
                                                </Button>
                                                Welcome to {salon?.name}
                                          </h3>
                                          <Form form={form}
                                                layout='vertical'
                                                name="check-in-form"
                                                onFinish={onSignIn}
                                                initialValues={{
                                                      phone : initValueForm.phone,
                                                }}
                                          >
                                                <Form.Item  label="Phone number">
                                                      <Input value={initValueForm.phone} onChange={handleChangePhone}  />
                                                </Form.Item>
                                                <div className="btn-form">
                                                      <Button loading={loading} type="primary" htmlType="submit" className='btn-check-in'>
                                                            Check In
                                                      </Button>
                                                </div>
                                          </Form>

                                    </div>
                              </div>
                        </div>
                  </div>
            </AuthFeature>
      );
}

export default CustomerCheckIn;