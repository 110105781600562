import serviceApi from "../api/serviceApi";
import {
      CREATE_SERVICE,
      DELETE_SERVICE,
      LIST_SERVICE, OPEN_FORM_EDIT_SERVICE,
      SET_SERVICE_EDIT,
      UPDATE_SERVICE,
} from '../constants/action'

export const getListServices = () => (dispatch) => {
      return new Promise((resolve, reject) => {
            serviceApi.listService().then(
                  (res) => {
                        dispatch({
                              type: LIST_SERVICE,
                              payload: res.data,
                        });
                        resolve(res)
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
}


export const createNewService = (data) => (dispatch) => {
      return new Promise((resolve, reject) => {
            serviceApi.createService(data).then(
                  (res) => {
                        dispatch({
                              type: CREATE_SERVICE,
                              payload: res.data,
                        });
                        resolve(res)
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
}

export const deleteService = (id) => (dispatch) => {
      return new Promise((resolve, reject) => {
            serviceApi.deleteService(id).then(
                  (res) => {
                        dispatch({
                              type: DELETE_SERVICE,
                              payload: id,
                        });
                        resolve(res)
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
}

export const setServiceEdit = (service) => (dispatch) => {
      return dispatch({
            type: SET_SERVICE_EDIT,
            payload: service,
      });
}

export const setOpenFormEditService = (status) => (dispatch) => {
      return dispatch({
            type: OPEN_FORM_EDIT_SERVICE,
            payload: status,
      });
}

export const updateService = (data) => (dispatch) => {
      return new Promise((resolve, reject) => {
            serviceApi.updateService(data).then(
                  (res) => {
                        dispatch({
                              type: UPDATE_SERVICE,
                              payload: res.data,
                        });
                        resolve(res)
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
}

export const changeActiveService = (id) => (dispatch) => {
      return new Promise((resolve, reject) => {
            serviceApi.changeActive(id).then(
                  (res) => {
                        dispatch({
                              type: UPDATE_SERVICE,
                              payload: res.data,
                        });
                        resolve(res)
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
}

export const getListServicesInBooking = (salonId) => {
      return new Promise((resolve, reject) => {
            serviceApi.getListServicesInBooking(salonId).then(
                  (res) => {
                        return resolve(res.data)
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
}