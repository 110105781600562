import React, {useEffect, useState} from 'react';
import {Button, Form, Checkbox, Input, Select, Avatar, Switch, Modal} from "antd";
import {useDispatch, useSelector} from "react-redux";
import AlertCommon from "../../components/alert";
import {
      ALERT_ERROR,
      TYPE_APPOINTMENT_WALK_IN, TYPE_COUPON
} from "../../constants/common";
import {customerCreateAppointment} from "../../actions/appointment";
import ModalCreateAppointmentSuccess from "./components/create-appointment-success";
import moment from 'moment'
import {useNavigate} from "react-router-dom";
import '../../stylesheets/booking.scss'
import {getListStaffInBooking} from "../../actions/staff";
import {getListServicesInBooking} from "../../actions/service";
import CustomerHeader from "./components/customer-header";
import CustomerProfileModal from "./components/customer-profile";
import CustomerChangePasswordModal from "../auth/components/customer-change-password";
import {checkVoucher} from "../../actions/voucher";

const AppointmentFeature = (props) => {
      const navigate = useNavigate()
      const {customer, salon} = useSelector(state => state.auth)
      const dispatch = useDispatch()
      const [form] = Form.useForm();
      const [loading, setLoading] = useState(false)
      const [services, setServices] = useState([])
      const [staffs, setStaffs] = useState([])
      const dayNumber = moment().day()
      const [success, setSuccess] = useState({
            visible : false,
            message : ''
      })
      const [anyStaff, setAnyStaff] = useState(true)
      const [addVoucher, setAddVoucher] = useState(false)

      useEffect(() => {
            if(!customer){
                  return navigate(`/customer-checkin`)
            }
      }, [])

      useEffect(() => {
            window.scrollTo(0, 0)
      }, [])

      useEffect(() => {
            (async () => {
                  const staffInBooking = getListStaffInBooking(salon?.id)
                  const serviceInBooking = getListServicesInBooking(salon?.id)

                  const listStaffs = await staffInBooking
                  const listServices = await serviceInBooking

                  setStaffs(listStaffs)
                  setServices(listServices)
            })()
      }, [])

      const renderListService = () => (
            services.map(item =>
                  <Checkbox key={item.id} value={item.id}><i className="fa-solid fa-check"></i> <Avatar shape="square" size={32} src={item.image} /> <span> {item.name} (${item.price})</span></Checkbox>
            )
      )

      const renderListStaff = () => (
            staffs.map(item =>
                  <Select.Option className='staff-option' value={item.id} key={item.id}>{item.first_name} {item.last_name}</Select.Option>
            )
      )

      const chooseAnyAvailable = () => {
            setAnyStaff(true)
            form.setFieldsValue({ staff_id: null });
      }

      const useVoucher = async () => {
            let dataCheck = {
                  code : form.getFieldValue('voucher'),
                  salon_id : salon.id,
                  type_customer: customer?.type
            }

            try {
                  setLoading(true)
                  const check = await checkVoucher(dataCheck)
                  setLoading(false)
                  if(!check.result){
                        return Modal.error({
                              title: 'Voucher not applicable',
                              content: check.message,
                              className: 'modal-error'
                        });
                  } else{
                        const discount = check.data.type === TYPE_COUPON ? `${check.data.value}%` : `$${check.data.value}`
                        return Modal.success({
                              title: 'Add voucher success',
                              content: `Successfully apply the voucher code, you will get ${discount} off`,
                              className: 'modal-success'
                        });
                  }
            } catch (e) {
                  setLoading(false)
                  return AlertCommon(ALERT_ERROR, e.message)
            }
      }

      const onSubmitAppointment = async (e) => {
            try {
                  setLoading(true)
                  let dateAppointment = moment(new Date()).utc().format('YYYY-MM-DD HH:mm')

                  let formData = {
                        salon_slug : salon?.slug,
                        staff_id : e.staff_id ? e.staff_id : null,
                        services : e.services ? JSON.stringify(e.services) : JSON.stringify([]),
                        service_custom: e.service_custom ? e.service_custom : null,
                        date_appointment: dateAppointment,
                        type: TYPE_APPOINTMENT_WALK_IN,
                        voucher: e.voucher ? e.voucher : null
                  }
                  let response = await dispatch(customerCreateAppointment(formData))
                  setSuccess({
                        visible: true,
                        message: response.message,
                        appointment: response.data
                  })
                  setLoading(false)
            } catch (e) {
                  setLoading(false)
                  return AlertCommon(ALERT_ERROR, e.message, 7000)
            }
      }

      const handleCloseModalComplete = () => {
            setSuccess({
                  visible: false,
                  message: ''
            })
            setAnyStaff(true)
            return form.resetFields()
      }

      const renderScheduleSalon = () => {
            return salon?.schedules.map(item =>
                  <p key={item.id}>
                        <span className={`item ${dayNumber === item.day ? 'today' : ''} `}>{item.day_name}</span>
                        <span className={`item ${dayNumber === item.day ? 'today' : ''} `}>{item.start_time ? `${item.start_time_format} - ${item.end_time_format}` : 'Close'}</span>
                  </p>
            )
      }

      return (
            <div className='appointment-page' id="main-appointment">
                  {customer && <CustomerHeader customer={customer}/>}

                  <div className="wp-inner">
                        <div className="form-appointment">
                              <Form form={form}
                                    name="form-appointment-hooks"
                                    onFinish={onSubmitAppointment}
                                    layout='vertical'
                                    initialValues={{
                                          date_appointment: 'Now'
                                    }}
                              >
                                    <div className="service-request">
                                          <div className="widget service">
                                                <div className="widget-head">
                                                      <h3 className="title">What do you want to get done?</h3>
                                                </div>
                                                <div className="widget-body">
                                                      <Form.Item name="services">
                                                            <Checkbox.Group className='list-services'>
                                                                  {renderListService()}
                                                            </Checkbox.Group>
                                                      </Form.Item>

                                                      <Form.Item name="service_custom">
                                                            <Input.TextArea
                                                                  placeholder='Something else (note)'
                                                                  autoSize={{ minRows: 3}}
                                                            />
                                                      </Form.Item>
                                                      <Form.Item className='submit-appointment'>
                                                            <Button loading={loading}
                                                                    type="primary" htmlType="submit">
                                                                  Done
                                                            </Button>
                                                      </Form.Item>
                                                </div>
                                          </div>
                                          <div className="widget request">
                                                <div className="widget-head">
                                                      <h3 className="title">Request</h3>
                                                </div>
                                                <div className="widget-body">
                                                      <div className="request-body">
                                                            <div className="staff-list block">
                                                                  <p className="label label-staff">Select Staff</p>
                                                                  <div className='choose-staff'>
                                                                        <Button className={`any-staff ${anyStaff && 'selected'}`} onClick={chooseAnyAvailable}>
                                                                              {anyStaff && <i className="fa-solid fa-check"></i> }
                                                                              Anyone Available
                                                                        </Button>
                                                                        <p className="or">Or</p>
                                                                        <Form.Item name="staff_id">
                                                                              <Select placeholder="Select a staff" onChange={() => setAnyStaff(false)}>
                                                                                    {renderListStaff()}
                                                                              </Select>
                                                                        </Form.Item>
                                                                  </div>
                                                            </div>
                                                            <div className="salon-business-hour block">
                                                                  <p className="label label-datetime">Business hour <span>( {salon.tz})</span></p>
                                                                  <div className="business-hour">
                                                                        {renderScheduleSalon()}
                                                                  </div>
                                                            </div>
                                                            <div className="datetime-rq block">
                                                                  <p className="label label-datetime">Appointment time</p>
                                                                  <Form.Item name="date_appointment">
                                                                        <Input className='date-now' disabled/>
                                                                  </Form.Item>

                                                            </div>
                                                            <div className='voucher'>
                                                                  <span className='label'>Add voucher</span><Switch checked={addVoucher} onChange={(checked) => setAddVoucher(checked)} />

                                                                  {addVoucher &&
                                                                  <div className='input-voucher'>
                                                                        <Form.Item name="voucher">
                                                                              <Input />
                                                                        </Form.Item>
                                                                        <Button loading={loading} onClick={useVoucher}>Use voucher</Button>
                                                                  </div>

                                                                  }
                                                            </div>
                                                            <Form.Item className='submit-appointment'>
                                                                  <Button loading={loading}
                                                                          type="primary" htmlType="submit">
                                                                        Done
                                                                  </Button>
                                                            </Form.Item>

                                                      </div>

                                                </div>
                                          </div>
                                    </div>
                              </Form>
                        </div>
                  </div>

                  <CustomerProfileModal/>
                  <CustomerChangePasswordModal/>
                  <ModalCreateAppointmentSuccess visible={success.visible} message={success.message} appointment={success.appointment} user={customer} closeModal = {handleCloseModalComplete} />
            </div>
      );
}

export default AppointmentFeature;