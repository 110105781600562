import React, {useState} from 'react';
import AuthFeature from "../index";
import {Button, Form, Input} from "antd";
import {PhoneOutlined} from '@ant-design/icons'
import AlertCommon from "../../../components/alert";
import {ALERT_ERROR, ALERT_SUCCESS} from "../../../constants/common";
import {Link, useNavigate} from 'react-router-dom'
import {normalizeInput, replaceFormatPhoneLogin} from "../../../utils/helper";
import {resetPassword, verificationCode} from "../../../actions/auth";

const ForgotPassword = (props) => {
      const [form] = Form.useForm();
      const [loading, setLoading] = useState(false)
      const navigate = useNavigate()
      const [phone, setPhone] = useState('')
      const [status, setStatus] = useState('forgot-password')
      const [password, setPassword] = useState('')
      const [code, setCode]  = useState('')
      const [counter, setCounter] = useState(60);

      React.useEffect(() => {
            const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
            return () => clearInterval(timer);
      }, [counter]);

      const onResetPassword = async (e) => {
            try {
                  setLoading(true)
                  const phoneSubmit = replaceFormatPhoneLogin(phone)

                  await resetPassword({phone: phoneSubmit, type: 2})
                  setLoading(false)
                  setStatus('verify-code')
            } catch (e) {
                  setLoading(false)
                  return AlertCommon(ALERT_ERROR, e.message)
            }
      }

      const onChangeCode = (e) => {
            console.log(e.target.value);
            setCode(e.target.value)
      }

      const resendCode = async () => {
            try {
                  setCounter(60)
                  const phoneSubmit = replaceFormatPhoneLogin(phone)
                  const response = await resetPassword({phone: phoneSubmit, type: 2})
                  return AlertCommon(ALERT_SUCCESS, response.message)
            } catch (e) {
                  return AlertCommon(ALERT_ERROR, e.message)
            }
      }

      const verifyCode = async () => {
            try {
                  setLoading(false)
                  const res = await verificationCode(code)
                  setPassword(res.data.new_password_reset)
                  setLoading(false)
                  setStatus('success')
            } catch (e) {
                  setLoading(false)
                  return AlertCommon(ALERT_ERROR, e.message)
            }
      }

      const handleChangePhone = (e) => {
            return setPhone(prevState => normalizeInput(e.target.value, prevState))
      }

      return (
            <AuthFeature>
                  <div className='login-page'>
                        <div className="wp-inner container">
                              <div className='content-login'>
                                    <div className="wp-form">
                                          <div className="widget">
                                                <div className="widget-body">
                                                      {status === 'forgot-password' &&
                                                            <h3 className="title-form">
                                                                  Nhập số điện thoại đã đăng ký để đặt lại mật khẩu
                                                            </h3>
                                                      }
                                                      <div className="form-admin-login">
                                                            <Form form={form}
                                                                  layout='vertical'
                                                                  name="forgot-password-hooks"
                                                                  onFinish={onResetPassword}
                                                            >

                                                                  {status === 'forgot-password' &&
                                                                        <React.Fragment>
                                                                        <div className="content">
                                                                              <p></p>
                                                                        </div>
                                                                        <Form.Item >
                                                                              <Input value={phone} onChange={handleChangePhone} placeholder='Nhập số điện thoại của bạn' prefix={<PhoneOutlined />}  />
                                                                        </Form.Item>
                                                                        <div className="btn-form">
                                                                              <Button loading={loading} type="primary" htmlType="submit">
                                                                                    Tiếp tục
                                                                              </Button>
                                                                              <Link className='link-to-login' to='/'>Quay trở lại đăng nhập</Link>
                                                                        </div>
                                                                  </React.Fragment>
                                                                  }
                                                                  {status === 'verify-code' &&
                                                                        <React.Fragment>
                                                                        <div className="back">
                                                                              <Button onClick={() => setStatus('forgot-password')}><i
                                                                                    className="fa-solid fa-left-long"></i>Trở lại</Button>
                                                                        </div>
                                                                        <div className="content">
                                                                              <p>Bạn cần xác minh số điện thoại này là của bạn. Chúng tôi đã gửi cho bạn một mã OTP qua SMS</p>
                                                                              <p>Vui lòng nhập xác minh để nhận mật khẩu mới</p>
                                                                        </div>
                                                                        <Form.Item>
                                                                              <Input maxLength={6} onChange={onChangeCode} placeholder='Nhập mã otp'/>
                                                                        </Form.Item>
                                                                        <div className="btn-form">
                                                                              <div className="resend">
                                                                                    <Button className='resend-code' onClick={resendCode} disabled={counter > 0}> <i className="fa-solid fa-rotate-right"></i> Gửi lại mã otp<span className="counter">{counter !== 0 ? `(${counter})` : ``}</span></Button>
                                                                              </div>
                                                                              <Button loading={loading} type="primary" onClick={verifyCode} className='submit'>
                                                                                    Đặt lại mật khẩu
                                                                              </Button>
                                                                        </div>

                                                                  </React.Fragment>
                                                                  }

                                                                  {status === 'success' &&
                                                                        <React.Fragment>
                                                                              <div className="content center">
                                                                                    <h3><i className="fa-solid fa-check"></i>Mật khẩu của bạn đã được thiết lập lại!</h3>

                                                                                    <p>Mật khẩu mới của bạn là</p>
                                                                                    <p className="password">{password}</p>
                                                                              </div>
                                                                              <div className="btn-form">
                                                                                    <Button type="primary" className='submit' onClick={() => navigate('/')}>
                                                                                          Đăng nhập
                                                                                    </Button>
                                                                              </div>
                                                                        </React.Fragment>
                                                                  }

                                                            </Form>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </AuthFeature>
      );
}

export default ForgotPassword;