import React, {useEffect} from 'react';
import {Button,  Modal} from "antd";
import '../../../stylesheets/detail.scss'
import {useTranslation} from "react-i18next";

const StaffDetail = ({visible, staff, closeModal}) =>  {
      const {t} = useTranslation()

      useEffect(() => {
            if(!staff){
                  closeModal()
            }
      }, [staff])

      return (
            <Modal
                  footer={null}
                  visible={visible}
                  maskClosable={false}
                  closable={false}
                  centered
                  className='modal-general modal-detail'
            >
                  {staff &&
                        <React.Fragment>
                              <div className="head-modal">
                                    <div className='left'>
                                          <p className="title">{t('modal.title_staff_detail')}</p>
                                    </div>
                                    <Button onClick={() => closeModal()}>{t('button.close')}</Button>
                              </div>
                              <div className="body-modal">
                                    <div className="detail">
                                          <p>
                                                <span>ID</span>
                                                <span>#{staff.id}</span>
                                          </p>
                                          <p>
                                                <span>{t('table.column_name')}</span>
                                                <span>{staff.name}</span>
                                          </p>
                                          <p>
                                                <span>{t('table.column_phone')}</span>
                                                <span>{staff.phone_format}</span>
                                          </p>
                                    </div>
                              </div>
                        </React.Fragment>
                  }
            </Modal>
      );
}

export default StaffDetail;