import React, {useEffect, useState} from 'react';
import {Button, Form, Input, Modal, Upload} from "antd";
import {ALERT_ERROR, ALERT_SUCCESS} from "../../../constants/common";
import {useDispatch, useSelector} from "react-redux";
import AlertCommon from "../../../components/alert";
import {
      setOpenFormEditService,
      setServiceEdit,
      updateService
} from "../../../actions/service";
import '../../../stylesheets/modal-service.scss'
import {getBase64} from "../../../utils/helper";
import {useTranslation} from "react-i18next";

const ModalUpdateService = (props) => {
      const {t} = useTranslation()
      const [loading, setLoading] = useState(false)
      const {serviceEdit, openFormEditService} = useSelector(state => state.service)
      const [form] = Form.useForm()
      const dispatch = useDispatch()
      const [images, setImages] = useState([])
      const [avatar, setAvatar] = useState(null)

      useEffect(() => {
            if(serviceEdit){
                  form.setFieldsValue({
                        name: serviceEdit.name,
                        price: serviceEdit.price,
                  })
                  if(serviceEdit.images.length > 0){
                        let listFiles = []
                        serviceEdit.images.map((item) => {
                              listFiles.push({
                                    uid: item.id,
                                    name: item.image.split('/').pop(),
                                    status: 'done',
                                    url: item.image,
                              })
                        })
                        setImages(listFiles)
                        setAvatar(serviceEdit.image ? {id : serviceEdit.id , image : serviceEdit.image} : null)
                  }
            }
      }, [serviceEdit, openFormEditService])

      const dummyRequest = ({file, onSuccess}) => {
            setTimeout(() => {
                  onSuccess(true);
            }, 0);
      };

      const handleChangeUpload = ({ fileList: newFileList }) => {
            setImages(newFileList)
      }

      const handleChangeUploadAvatar = (info) => {
            if (info.file.status === 'done') {
                  // Get this url from response in real world.
                  getBase64(info.file.originFileObj, (url) => {
                        setLoading(false);
                        let newFile = info.file
                        newFile.image = url
                        setAvatar(info.file)
                  });
            }
      }

      const onSaveService = async (e) => {
            try {
                  if(!serviceEdit) return
                  let formData = new FormData()
                  e.id = serviceEdit.id
                  const uidImageUpload = images.map(item => item.uid)
                  const deleteImageArr = serviceEdit.images.filter(item => !uidImageUpload.includes(item.id)).map(k => k.id)
                  e.delete_images = JSON.stringify(deleteImageArr)

                  if(avatar && avatar.originFileObj){
                        e.avatar = avatar.originFileObj
                  }

                  Object.keys(e).forEach(function (key) {
                        if(typeof e[key] === 'string'){
                              e[key] = e[key] ? e[key].trim() : null;
                        }
                        formData.append(key, e[key])
                  })
                  if(e.price && (parseFloat(e.price) < 0.01 || parseFloat(e.price) > 9999.99)){
                        return AlertCommon(ALERT_ERROR, 'Please input price > 0 & < 9999.99')
                  }
                  const files = []
                  const imageUpload = images.filter(item => item.originFileObj)
                  if(imageUpload.length > 0){
                        imageUpload.map(item => files.push(item.originFileObj))
                  }
                  Object.keys(files).forEach((key, i) => {
                        formData.append('images', files[key]);
                  });
                  let response = await dispatch(updateService(formData))
                  setLoading(false)
                  AlertCommon(ALERT_SUCCESS, response.message)
                  form.resetFields()
                  dispatch(setOpenFormEditService(false))

            } catch (e) {
                  setLoading(false)
                  return AlertCommon(ALERT_ERROR, e.message)
            }
      }

      const handleCloseModal = () => {
            dispatch(setServiceEdit(null))
            return dispatch(setOpenFormEditService(false))
      }

      return (
            <Modal
                  footer={null}
                  visible={openFormEditService}
                  maskClosable={false}
                  closable={false}
                  centered
                  className='modal-general modal-service'
                  zIndex={1004}
            >
                  <div className="head-modal">
                        <p className="title">{t('modal.title_update_service')}</p>
                        <Button onClick={handleCloseModal}>{t('button.close')}</Button>
                  </div>
                  <div className="body-modal">
                        <div className="services">
                              <div className="form-wp">
                                    <Form
                                          form={form}
                                          layout='vertical'
                                          name="control-hooks"
                                          onFinish={onSaveService}
                                    >

                                          <Form.Item>
                                                <Upload
                                                      className='upload-avatar-service'
                                                      listType="picture-card"
                                                      onChange={handleChangeUploadAvatar}
                                                      customRequest={dummyRequest}
                                                      accept=".jpg,.jpeg,.png"
                                                      showUploadList={false}
                                                >
                                                      {avatar ? <img src={avatar.image} alt="avatar" style={{width: '100%',}}
                                                      /> : <div className='upload-btn'>
                                                            <i className="fa-regular fa-image"></i>
                                                            <div className='text-upload-btn'>{t('button.add_image')}</div>
                                                      </div>
                                                      }
                                                </Upload>
                                          </Form.Item>
                                          <Form.Item name="name">
                                                <Input placeholder={t('form.placeholder_service_name')} />
                                          </Form.Item>
                                          <Form.Item name="price">
                                                <Input placeholder={t('form.placeholder_service_price')} />
                                          </Form.Item>
                                          <Form.Item label={t('form.label_more_photos')}>
                                                <Upload
                                                      className='upload-image-salon'
                                                      listType="picture-card"
                                                      fileList={images}
                                                      onChange={handleChangeUpload}
                                                      customRequest={dummyRequest}
                                                      accept=".jpg,.jpeg,.png"
                                                      showUploadList={{showPreviewIcon: false, showRemoveIcon: true}}
                                                >
                                                      {images.length >= 10 ? null : <i className="fa-solid fa-images"></i>}
                                                </Upload>
                                          </Form.Item>

                                          <Form.Item>
                                                <Button loading={loading} type="primary" htmlType="submit">
                                                      {t('button.update')}
                                                </Button>
                                          </Form.Item>
                                    </Form>
                              </div>
                        </div>
                  </div>
            </Modal>
      );
}

export default ModalUpdateService;