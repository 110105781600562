import React, {useRef, useState} from 'react';
import {Button, Input, Modal} from "antd";
import DatatableGeneral from "../../../components/datatable";
import {CUSTOMER_NORMAL, TABLE_CUSTOMER} from "../../../constants/common";
import {useDispatch, useSelector} from "react-redux";
import {toggleModalListAppointmentForCustomer, toggleShowModalCustomer} from "../../../actions/modal";
import { SearchOutlined , FormOutlined} from '@ant-design/icons';
import EmptyData from "../../../components/empty";
import ModalUpdateCustomer from "./update-customer";
import {useTranslation} from "react-i18next";

const ModalListCustomer = (props) =>  {
      const {openCustomer} = useSelector(state => state.modal)
      const {customers} = useSelector(state => state.customer)
      const dispatch = useDispatch()
      const typingTimeOutRef = useRef(null)
      const [txtSearch, setTxtSearch] = useState('')
      let customerList = customers.filter(item => item.name.toLowerCase().includes(txtSearch.toLowerCase()) || item.phone.includes(txtSearch.toLowerCase()) || (item.email && item.email.toLowerCase().includes(txtSearch.toLowerCase())))
      const [customerEdit, setCustomerEdit] = useState(null)
      const [visibleFormUpdate, setVisibleFormUpdate] = useState(false)
      const {t} = useTranslation()

      let columns = [
            {
                  title: 'Id',
                  dataIndex: '_id',
                  key: '_id',
            },
            {
                  title: t('table.column_stt'),
                  dataIndex: 'key',
                  key: 'key',
                  render: text => <p>{text}</p>,
            },
            {
                  title: t('table.column_name'),
                  dataIndex: 'name',
                  key: 'name',
                  render: (text, record) => <p>{text}</p>,
            },
            {
                  title:t('table.column_phone'),
                  dataIndex: 'phone',
                  key: 'phone',
                  render: (text, record) => <p>{text}</p>,
            },
            {
                  title: t('table.column_email'),
                  dataIndex: 'email',
                  key: 'email',
                  render: (text, record) => <p className={`text ${text ? '' : 'no-info'}`}>{text ? text : 'no info'}</p>,
            },
            {
                  title: t('table.column_type'),
                  dataIndex: 'type',
                  key: 'type',
                  render: record => <React.Fragment>
                        <p>
                              {!record.type ?
                                    <span className='customer-type none'>None</span>
                              :
                              <span className={`customer-type ${record.type === CUSTOMER_NORMAL ? 'normal' : 'vip'}`}>{record.type_name}</span>
                              }
                        </p>
                  </React.Fragment>,
            },
            {
                  title: t('table.column_note'),
                  dataIndex: 'note',
                  key: 'note',
                  render: (text, record) => <p>{text}</p>,
            },
            {
                  title: <i className="fa-solid fa-screwdriver-wrench"></i>,
                  dataIndex: 'action',
                  key: 'action',
                  render: (text, record) => <div className='action-row'>
                        <Button onClick={() => getListAppointmentForCustomer(record)} className='btn-detail'>Booking List</Button>
                        <Button className='edit-row-btn' onClick={() => showFormUpdateCustomer(record._id)}><FormOutlined/></Button>
                  </div>,
            }
      ];

      const contentData = () => {
            return customerList.map((item, index) => {
                  return {
                        _id: item.id,
                        key: index + 1,
                        name: item.name,
                        phone: item.phone_format,
                        email: item.email,
                        type: item,
                        note: item.note,
                        action: item
                  }
            })
      }
      let dataSource = {
            totalElements: customerList.length,
            content: contentData(),
      };

      const {
            DataTable,
      } = DatatableGeneral({
            type: TABLE_CUSTOMER,
            columns: columns,
            dataSource: dataSource,
            updateType: 'customer',
      });

      const showFormUpdateCustomer = (id) => {
            const customer = customers.find(item => item.id === id)
            if(customer){
                  setCustomerEdit(customer)
                  setVisibleFormUpdate(true)
            }

      }

      const getListAppointmentForCustomer = (customer) => {
            return dispatch(toggleModalListAppointmentForCustomer(customer, true))
      }

      const onSearch = (e) => {
            const txt = e.target.value || ''
            if(typingTimeOutRef.current){
                  clearTimeout(typingTimeOutRef.current)
            }
            typingTimeOutRef.current = setTimeout(() => {
                  setTxtSearch(txt)
            }, 1000)
      }

      return (
            <Modal
                  footer={null}
                  visible={openCustomer}
                  maskClosable={false}
                  closable={false}
                  centered
                  className='modal-general modal-service modal-table'
            >
                  <div className="head-modal">
                        <p className="title">{t('modal.title_customer')}</p>
                        <Button onClick={() => dispatch(toggleShowModalCustomer(false))}>{t('button.close')}</Button>
                  </div>
                  <div className="body-modal">
                        <div className="customers">
                              <Input className='input-search' placeholder={t('form.placeholder_search_customer')} prefix={<SearchOutlined/>} onChange={onSearch} />
                              <DataTable />
                              {customerList.length === 0 &&
                                    <EmptyData/>
                              }
                        </div>
                  </div>
                  <ModalUpdateCustomer customer={customerEdit} visible={visibleFormUpdate} closeModal={() => {
                        setCustomerEdit(null)
                        setVisibleFormUpdate(false)
                  }} />
            </Modal>
      );
}

export default ModalListCustomer;