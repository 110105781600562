import salonApi from "../api/salonApi";
import {UPDATE_INFO_CUSTOMER, UPDATE_SALON} from "../constants/action";

export const updateSalon = (data) => (dispatch) => {
      return new Promise((resolve, reject) => {
            salonApi.updateSalon(data).then(
                  (res) => {
                        dispatch({
                              type: UPDATE_SALON,
                              payload: res.data
                        })
                        return resolve(res)
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
}

export const updateInfoCustomer = (data) => (dispatch) => {
      return new Promise((resolve, reject) => {
            salonApi.updateCustomerInfo(data).then(
                  (res) => {
                        dispatch({
                              type: UPDATE_INFO_CUSTOMER,
                              payload: res.data
                        })
                        return resolve(res)
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
}