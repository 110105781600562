import React, {useEffect, useState} from 'react';
import {Button, Col, Input, Popconfirm, Row, Upload} from "antd";
import Master from "../../components/layout/master";
import '../../stylesheets/dashboard.scss'
import ModalAppointmentDetail from "./components/appointment-detail";
import {useDispatch, useSelector} from "react-redux";
import {
      ALERT_ERROR, ALERT_SUCCESS,
      APM_ACCEPTED,
      APM_CANCEL, APM_FINISH, APM_IN_PROCESSING,
      APM_PENDING, APM_WAITING,
      TYPE_APPOINTMENT_CUSTOMER,
      TYPE_APPOINTMENT_WALK_IN
} from "../../constants/common";
import {socket} from '../../config/socket'
import {addNewAppointmentFromClient, updateStaffBySocket} from "../../actions/dashboard";
import {
      customerWalkIn, handleAppointment, removeAppointment,
      setAppointmentSelected,
      setOpenAppointmentDetail,
      updateAppointmentByClient, updateAppointmentBySystem, updateStatusAppointment
} from "../../actions/appointment";
import ModalUpdateStaff from "./components/update-staff";
import ModalUpdateService from "./components/update-service";
import AlertCommon from "../../components/alert";
import {
      toggleModalEnterPasswordAdmin,
      toggleModalNotificationAppointment,
      toggleModalRejectAppointment,
      toggleShowModelCancelAppointment
} from "../../actions/modal";
import ModalSalon from "./components/salon";
import {convertTZLocal} from "../../utils/helper";
import ModalChooseStaffAppointment from "./components/choose-staff";
import EmptyData from "../../components/empty";
import CustomerDetail from "./components/customer-detail";
import StaffDetail from "./components/staff-detail";
import {useNavigate} from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';

const DashboardFeature = (props) => {
      const { t } = useTranslation();
      const {appointments} = useSelector(state => state.dashboard)
      const {salon, customer, admin} = useSelector(state => state.auth)
      const dispatch = useDispatch()
      const [loading, setLoading] = useState(false)
      const [btn, setBtn] = useState('')
      const [appointmentIdSelect, setAppointmentIdSelect] = useState(null)
      const [visibleFillDataAppointment, setVisibleFillDataAppointment] = useState(false)
      const [typeHandle, setTypeHandle] = useState(null)
      const [visibleInputPrice, setVisibleInputPrice] = useState(false)
      const [priceTotal, setPriceTotal] = useState(0)
      const [staffSelect, setStaffSelect] = useState(null)
      const [customerSelect, setCustomerSelect] = useState(null)
      const navigate = useNavigate()
      const [imagesBefore, setImagesBefore] = useState([])
      const [imagesAfter, setImagesAfter] = useState([])

      useEffect(() => {
            if(customer){
                  return navigate('/booking')
            }
      }, [customer])

      useEffect(() => {
            if(admin.need_enter_password){
                  dispatch(toggleModalEnterPasswordAdmin(true))
                  navigate('/customer-checkin')
            }
      }, [])

      useEffect(() => {
            const listener = (data) => {
                  if(data.salon_id === salon?.id){
                        dispatch(toggleModalNotificationAppointment(data, true))
                        dispatch(addNewAppointmentFromClient(data))
                  }
            };
            socket.on('new-appointment-client', listener);
            return () => socket.off('new-appointment-client', listener);
      }, ['new-appointment-client', salon]);

      useEffect(() => {
            const listener = (data) => {
                  if(data.salon_id === salon?.id){
                        dispatch(updateStaffBySocket(data))
                  }
            };
            socket.on('change-status-staff', listener);
            return () => socket.off('change-status-staff', listener);
      }, ['change-status-staff']);

      useEffect(() => {
            const listener = (data) => {
                  if(data.salon_id === salon?.id){
                        dispatch(toggleModalNotificationAppointment(data, true))
                        dispatch(updateAppointmentByClient(data))
                  }
            };
            socket.on('cancel-appointment', listener);
            return () => socket.off('cancel-appointment', listener);
      }, ['cancel-appointment']);

      useEffect(() => {
            const listener = (data) => {
                  if(data.salon_id === salon?.id){
                        dispatch(toggleModalNotificationAppointment(data, true))
                        dispatch(updateAppointmentByClient(data))
                  }
            };
            socket.on('feedback-appointment', listener);
            return () => socket.off('feedback-appointment', listener);
      }, ['feedback-appointment']);

      useEffect(() => {
            const listener = (data) => {
                  if(data.salon_id === salon?.id){
                        dispatch(updateAppointmentBySystem(data))
                  }
            };
            socket.on('change-status-appointment', listener);
            return () => socket.off('change-status-appointment', listener);
      }, ['change-status-appointment']);

       const renderListWalkInAppointment = () => {
            return (
                  appointments.filter(item => item.type === TYPE_APPOINTMENT_WALK_IN).map(item => (
                        <li key={item.id} className="item-appointment">
                              <div className="item"  onClick={() => showAppointmentDetail(item)}>
                                    <p className='appointment-key'>ID: #{item.id}</p>
                              </div>
                              <div className="item">
                                    <p className='customer-name' onClick={() => setCustomerSelect(item.customer)}>{item.customer_name}</p>
                                    <p className='date-appointment'>{convertTZLocal(item.date_appointment).format}</p>
                              </div>
                              <div className="item">
                                    <p>{t('appointment.request')}:
                                          {item.staff &&
                                                <span className='staff-name' onClick={() => setStaffSelect(item.staff)}>{item.staff_name}</span>
                                          }
                                          {!item.staff && ` ${item.staff_name}`}
                                    </p>
                                    <p className={`status ${item.status_class}`}>{item.status_name} <i className="fa-solid fa-circle"></i></p>
                              </div>
                              <div className="item border">
                                    <p>{t('appointment.services')}</p>
                                    <p>{item.list_service_names_with_price}</p>
                              </div>
                              {item.service_custom &&
                                    <div className="item border">
                                          <p>{t('appointment.service_note')}</p>
                                          <p>{item.service_custom_name}</p>
                                    </div>
                              }

                              {item.status === APM_FINISH &&
                                    <div className="item border">
                                          <p>{t('appointment.total')}</p>
                                          <p className='price-total'>${item.price_format}</p>
                                    </div>
                              }

                              <div className={`btn-action`}>
                                    {item.status === APM_WAITING &&
                                          <React.Fragment>
                                                <Button loading={loading && btn === 'start-service' && appointmentIdSelect === item.id} className='start-service' onClick={() => {
                                                      if(!item.staff_id || !item.work_time){
                                                            setAppointmentIdSelect(item.id)
                                                            setTypeHandle('start-appointment')
                                                            setVisibleFillDataAppointment(true)
                                                      } else{
                                                            return handleStartService(item)
                                                      }
                                                }}>{t('button.start_service')}</Button>
                                          </React.Fragment>
                                    }
                                    {item.status === APM_IN_PROCESSING &&
                                    <React.Fragment>
                                          <Button className={`finished ${(visibleInputPrice && appointmentIdSelect === item.id) ? 'd-none' : ''}`} loading={loading && btn === 'finished' && appointmentIdSelect === item.id}  onClick={() => {
                                                setAppointmentIdSelect(item.id)
                                                setPriceTotal(item.price ? item.price : item.total_price_service)
                                                setVisibleInputPrice(true)
                                          }}>{t('button.finish')}</Button>

                                          {(visibleInputPrice && appointmentIdSelect === item.id) &&
                                                <div className="total-price">
                                                      <div className="form-input">
                                                            <p className="label">{t('appointment.total')} <span>($)</span></p>
                                                            <Input value={priceTotal} onChange={(e) => setPriceTotal(e.target.value)}/>
                                                      </div>
                                                      <div className="images-booking">
                                                            <div className="form-input">
                                                                  <p className="label">{t('form.label_add_image_before')}</p>
                                                                  <Upload
                                                                        className='upload-image-salon'
                                                                        listType="picture-card"
                                                                        fileList={imagesBefore}
                                                                        onChange={handleChangeUploadImageBefore}
                                                                        customRequest={dummyRequest}
                                                                        accept=".jpg,.jpeg,.png"
                                                                        showUploadList={{showPreviewIcon: false, showRemoveIcon: true}}
                                                                  >
                                                                        {imagesBefore.length >= 5 ? null :
                                                                              <i className="fa-regular fa-images"></i>
                                                                        }
                                                                  </Upload>
                                                            </div>
                                                            <div className="form-input">
                                                                  <p className="label">{t('form.label_add_image_after')}</p>
                                                                  <Upload
                                                                        className='upload-image-salon'
                                                                        listType="picture-card"
                                                                        fileList={imagesAfter}
                                                                        onChange={handleChangeUploadImageAfter}
                                                                        customRequest={dummyRequest}
                                                                        accept=".jpg,.jpeg,.png"
                                                                        showUploadList={{showPreviewIcon: false, showRemoveIcon: true}}
                                                                  >
                                                                        {imagesAfter.length >= 5 ? null :
                                                                              <i className="fa-regular fa-images"></i>
                                                                        }
                                                                  </Upload>
                                                            </div>
                                                      </div>
                                                      <div className="btn-actions">
                                                            <Button loading={loading && btn === 'finish-appointment'} className='finished' onClick={() => handleFinishAppointment(item)}>Submit</Button>
                                                            <Button className='cancel' onClick={() => setVisibleInputPrice(false)}>{t('button.cancel')}</Button>
                                                      </div>
                                                </div>
                                          }

                                    </React.Fragment>
                                    }
                              </div>
                        </li>
                  ))
            )
       }

      const dummyRequest = ({file, onSuccess}) => {
            setTimeout(() => {
                  onSuccess(true);
            }, 0);
      };
      const handleChangeUploadImageBefore = ({ fileList: newFileList }) => {
            setImagesBefore(newFileList)
      }
      const handleChangeUploadImageAfter = ({ fileList: newFileList }) => {
            setImagesAfter(newFileList)
      }
      const renderListAppointmentCustomer = () => {
            return (
                  appointments.filter(item => item.type === TYPE_APPOINTMENT_CUSTOMER).map(item => (
                        <li key={item.id} className="item-appointment">
                              <div className="item"  onClick={() => showAppointmentDetail(item)}>
                                    <p className='appointment-key'>ID: #{item.id}</p>
                              </div>
                              <div className="item">
                                    <p className='customer-name'onClick={() => setCustomerSelect(item.customer)} >{item.customer_name}</p>
                                    <p className='date-appointment'>{convertTZLocal(item.date_appointment).format}</p>
                              </div>
                              <div className="item">
                                    <p>{t('appointment.request')}:
                                          {item.staff &&
                                                <span className='staff-name' onClick={() => setStaffSelect(item.staff)}>{item.staff_name}</span>
                                          }
                                          {!item.staff && ` ${item.staff_name}`}
                                    </p>
                                    <p className={`status ${item.status_class}`}>{item.status_name} <i className="fa-solid fa-circle"></i></p>
                              </div>
                              <div className="item border">
                                    <p>{t('appointment.services')}</p>
                                    <p>{item.list_service_names_with_price}</p>
                              </div>
                              {item.service_custom &&
                                    <div className="item border">
                                          <p>{t('appointment.service_note')}</p>
                                          <p>{item.service_custom_name}</p>
                                    </div>
                              }
                              <div className={`btn-action ${item.status === APM_CANCEL ? 'one' : ''}`}>
                                    {item.status === APM_PENDING &&
                                          <React.Fragment>
                                                <Button loading={loading && btn === 'accepted' && appointmentIdSelect === item.id} className='accepted' onClick={() => handleAcceptRejectAppointment(item, 'accepted')}>{t('button.accepted')}</Button>
                                                <Button loading={loading && btn === 'rejected' && appointmentIdSelect === item.id} className='rejected' onClick={() => handleAcceptRejectAppointment(item, 'rejected')}>{t('button.rejected')}</Button>
                                          </React.Fragment>
                                    }
                                    {item.status === APM_ACCEPTED &&
                                          <React.Fragment>
                                                <Button loading={loading && btn === 'check-in' && appointmentIdSelect === item.id} className='cs-check-in' onClick={() => handleCustomerCheckIn(item)}>{t('button.customer_check_in')}</Button>
                                                <Button loading={loading && btn === 'cancel' && appointmentIdSelect === item.id} onClick={() => handleCancelAppointment(item)} className='cancel'>{t('button.cancel')}</Button>
                                          </React.Fragment>
                                    }

                                    {item.status === APM_CANCEL &&
                                          <React.Fragment>
                                                <Popconfirm
                                                      className='pop-confirm-custom'
                                                      placement="top"
                                                      title={t('alert.title_confirm_delete')}
                                                      onConfirm={() => handleDeleteAppointment(item)}
                                                      okText={t('button.ok')}
                                                      cancelText={t('button.cancel')}
                                                      okButtonProps={{
                                                            loading: loading && btn === 'deleted' && appointmentIdSelect === item.id
                                                      }}
                                                >
                                                      <Button className='deleted'>{t('button.delete')}</Button>
                                                </Popconfirm>

                                          </React.Fragment>
                                    }

                              </div>
                        </li>
                  ))
            )
      }

      const handleCustomerCheckIn = async (appointment) => {
             try {
                   setLoading(true)
                   setBtn('check-in')
                   setAppointmentIdSelect(appointment.id)
                   if(!appointment) return
                   const res = await dispatch(customerWalkIn(appointment.id))
                   AlertCommon(ALERT_SUCCESS, res.message)
                   return setLoading(false)
             } catch (e) {
                   setLoading(false)
                   setBtn('')
                   return AlertCommon(ALERT_ERROR, e.message)
             }
      }

      const handleAcceptRejectAppointment = async (appointment, btn) => {
             try {
                   setLoading(true)
                   setBtn(btn)
                   setAppointmentIdSelect(appointment.id)

                   const is_accepted = btn === 'accepted' ? 1 : 0
                   await dispatch(setAppointmentSelected(appointment))
                   if(is_accepted === 0){
                         dispatch(toggleModalRejectAppointment(true))
                   }
                   if(is_accepted === 1){
                         setTypeHandle('accept-appointment')
                         setVisibleFillDataAppointment(true)
                   }
                   setLoading(false)
             } catch (e) {
                   setLoading(false)
                   setBtn('')
             }
      }

      const handleCancelAppointment = async (appointment) => {
            try {
                  setLoading(true)
                  setBtn('cancel')
                  setAppointmentIdSelect(appointment.id)
                  await dispatch(setAppointmentSelected(appointment))
                  setLoading(false)
                  return dispatch(toggleShowModelCancelAppointment(true))
            } catch (e) {
                  setLoading(false)
                  setBtn('')
            }
      }


      const handleDeleteAppointment = async (appointment) => {
            try {
                  setLoading(true)
                  setBtn('deleted')
                  const res = await dispatch(removeAppointment(appointment.id))
                  AlertCommon(ALERT_SUCCESS, res.message)
                  return setLoading(false)
            } catch (e) {
                  setLoading(false)
                  setBtn('')
                  return AlertCommon(ALERT_ERROR, e.message)
            }
      }

      const showAppointmentDetail = async (appointment) => {
            await dispatch(setAppointmentSelected(appointment))
            dispatch(setOpenAppointmentDetail(true))
      }

      const handleStartService = async (appointment, staffId, workTime) => {
             try {
                   setLoading(true)
                   setBtn('start-service')
                   let formData = {
                         id: appointment.id,
                         status: APM_IN_PROCESSING,
                         work_time: workTime || appointment.work_time,
                         staff_id : staffId || appointment.staff_id
                   }
                   let response = await dispatch(updateStatusAppointment(formData))
                   setLoading(false)
                   setVisibleFillDataAppointment(false)
                   setTypeHandle(null)
                   setBtn('')
                   setVisibleInputPrice(false)
                   AlertCommon(ALERT_SUCCESS, response.message)
             } catch (e) {
                   setLoading(false)
                   return AlertCommon(ALERT_ERROR, e.message)
             }
      }

      const handleFinishAppointment = async (appointment) => {
           try {
                 setLoading(true)
                 setBtn('finish-appointment')
                 let formData = new FormData()
                 let dataUpdate = {
                       id: appointment.id,
                       status: APM_FINISH,
                       price: priceTotal
                 }
                 Object.keys(dataUpdate).forEach(function (key) {
                        formData.append(key, dataUpdate[key])
                 })
                 let fileImageBefore = imagesBefore.filter(item => item.originFileObj).map(el => el.originFileObj)
                 let fileImageAfter = imagesAfter.filter(item => item.originFileObj).map(el => el.originFileObj)

                 Object.keys(fileImageBefore).forEach((key, i) => {
                       formData.append('images_before', fileImageBefore[key]);
                 });

                 Object.keys(fileImageAfter).forEach((key, i) => {
                       formData.append('images_after', fileImageAfter[key]);
                 });
                 let response = await dispatch(updateStatusAppointment(formData))
                 setLoading(false)
                 setBtn('')
                 return AlertCommon(ALERT_SUCCESS, response.message)
           } catch (e) {
                 setLoading(false)
                 return AlertCommon(ALERT_ERROR, e.message)
           }
      }

      const handleStatusAppointment = async (formData) => {
             try {
                   setLoading(true)
                  if(typeHandle === 'accept-appointment'){
                        formData.id = appointmentIdSelect
                        formData.is_accepted = 1
                        let response = await dispatch(handleAppointment(formData))
                        setLoading(false)
                        setVisibleFillDataAppointment(false)
                        setTypeHandle(null)
                        AlertCommon(ALERT_SUCCESS, response.message)
                  }
                  if(typeHandle === 'start-appointment'){
                        await handleStartService({id : appointmentIdSelect}, formData.staff_id, formData.work_time)
                  }
             } catch (e) {
                   setLoading(false)
                   return AlertCommon(ALERT_ERROR, e.message)
             }
      }
      return (
            <Master>
                  <div className='dashboard-page'>
                        <div className="wp-inner">
                              <div className="list-appointment staff-panel">
                                    <Row gutter={[16, 16]}>
                                          <Col sm={24} md={12}>
                                                <div className="widget">
                                                      <div className="widget-head">
                                                            <h3 className="title">
                                                                  {t('dashboard.customer_check_in')}
                                                            </h3>
                                                      </div>
                                                      <div className="widget-body">
                                                            <ul className="appointments">
                                                                  {appointments.filter(item => item.type === TYPE_APPOINTMENT_WALK_IN).length === 0 ?
                                                                        <EmptyData/>
                                                                  :
                                                                        renderListWalkInAppointment()
                                                                  }
                                                            </ul>
                                                      </div>
                                                </div>
                                          </Col>
                                          <Col sm={24} md={12}>
                                                <div className="widget">
                                                      <div className="widget-head">
                                                            <h3 className="title">
                                                                  {t('dashboard.customer_appointment')}
                                                            </h3>
                                                      </div>
                                                      <div className="widget-body">
                                                            <ul className="appointments">
                                                                  {appointments.filter(item => item.type === TYPE_APPOINTMENT_CUSTOMER).length === 0 ?
                                                                        <EmptyData/>
                                                                        :
                                                                        renderListAppointmentCustomer()
                                                                  }
                                                            </ul>
                                                      </div>
                                                </div>
                                          </Col>
                                    </Row>
                              </div>
                        </div>

                        <ModalAppointmentDetail />
                        <ModalUpdateStaff/>
                        <ModalUpdateService/>
                        <ModalSalon/>
                        <CustomerDetail visible={!!customerSelect} customer={customerSelect} closeModal={() => setCustomerSelect(null)}/>
                        <StaffDetail visible={!!staffSelect} staff={staffSelect} closeModal={() => setStaffSelect(null)}/>

                        <ModalChooseStaffAppointment visible={visibleFillDataAppointment} type={typeHandle} handleData={handleStatusAppointment} loading={loading} closeModal={() => setVisibleFillDataAppointment(false)}/>
                  </div>
            </Master>
      );
}

export default DashboardFeature;