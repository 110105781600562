import {
      OPEN_FORM_PROFILE_SALON,
      OPEN_HISTORY_CHECK_IN_OUT_STAFF,
      OPEN_LIST_APPOINTMENT_FOR_CUSTOMER,
      OPEN_MODAL_CHANGE_PASSWORD,
      OPEN_MODAL_CHOOSE_STAFF_APPOINTMENT,
      OPEN_MODAL_CUSTOMER, OPEN_MODAL_ENTER_PASSWORD_ADMIN,
      OPEN_MODAL_NOTIFICATION_APPOINTMENT,
      OPEN_MODAL_REJECT_APPOINTMENT,
      OPEN_MODAL_REPORT,
      OPEN_MODAL_SERVICE,
      OPEN_MODAL_SETTING,
      OPEN_MODAL_STAFF,
      OPEN_MODAL_WORKING,
      OPEN_MODEL_CANCEL_APPOINTMENT,
      TOGGLE_MODAL_CHANGE_PASSWORD_CUSTOMER,
      TOGGLE_MODAL_PROFILE_CUSTOMER, TOGGLE_MODAL_UPDATE_INFO_CUSTOMER_OF_SALON
} from "../constants/action";

export const toggleShowModalStaff = (status) => (dispatch) => {
      return dispatch({
            type: OPEN_MODAL_STAFF,
            payload: status,
      });
}

export const toggleShowModalService = (status) => (dispatch) => {
      return dispatch({
            type: OPEN_MODAL_SERVICE,
            payload: status,
      });
}

export const toggleShowModalCustomer = (status) => (dispatch) => {
      return dispatch({
            type: OPEN_MODAL_CUSTOMER,
            payload: status,
      });
}

export const toggleShowModalSetting = (status) => (dispatch) => {
      return dispatch({
            type: OPEN_MODAL_SETTING,
            payload: status,
      });
}

export const toggleShowModalChangePassword = (status) => (dispatch) => {
      return dispatch({
            type: OPEN_MODAL_CHANGE_PASSWORD,
            payload: status,
      });
}

export const toggleShowModalReport = (status) => (dispatch) => {
      return dispatch({
            type: OPEN_MODAL_REPORT,
            payload: status,
      });
}

export const toggleShowModalWorking = (status) => (dispatch) => {
      return dispatch({
            type: OPEN_MODAL_WORKING,
            payload: status,
      });
}

export const toggleShowModelCancelAppointment = (status) => (dispatch) => {
      return dispatch({
            type: OPEN_MODEL_CANCEL_APPOINTMENT,
            payload: status,
      });
}

export const toggleModalRejectAppointment = (status) => (dispatch) => {
      return dispatch({
            type: OPEN_MODAL_REJECT_APPOINTMENT,
            payload: status,
      });
}

export const toggleModalChooseStaffAppointment = (status) => (dispatch) => {
      return dispatch({
            type: OPEN_MODAL_CHOOSE_STAFF_APPOINTMENT,
            payload: status,
      });
}

export const toggleModalNotificationAppointment = (data, status) => (dispatch) => {
      return dispatch({
            type: OPEN_MODAL_NOTIFICATION_APPOINTMENT,
            payload: {
                  status: status,
                  data: data
            },
      });
}


export const toggleModalListAppointmentForCustomer = (data, status) => (dispatch) => {
      return dispatch({
            type: OPEN_LIST_APPOINTMENT_FOR_CUSTOMER,
            payload: {
                  status: status,
                  data: data
            },
      });
}

export const toggleModelHistoryCheckInOutStaff = (data, status) => (dispatch) => {
      return dispatch({
            type: OPEN_HISTORY_CHECK_IN_OUT_STAFF,
            payload: {
                  status: status,
                  data: data
            },
      });
}

export const toggleModalFormProfileSalon = (status) => (dispatch) => {
      return dispatch({
            type: OPEN_FORM_PROFILE_SALON,
            payload: {
                  status: status
            },
      });
}

export const toggleModalProfileCustomer = (status) => (dispatch) => {
      return dispatch({
            type : TOGGLE_MODAL_PROFILE_CUSTOMER,
            payload: {
                  status
            }
      })
}

export const toggleModalChangePasswordCustomer = (status) => (dispatch) => {
      return dispatch({
            type : TOGGLE_MODAL_CHANGE_PASSWORD_CUSTOMER,
            payload: {
                  status
            }
      })
}

export const toggleModalUpdateInfoCustomerOfSalon = (status) => (dispatch) => {
      return dispatch({
            type : TOGGLE_MODAL_UPDATE_INFO_CUSTOMER_OF_SALON,
            payload: {
                  status
            }
      })
}

export const toggleModalEnterPasswordAdmin = (status) => (dispatch) => {
      return dispatch({
            type : OPEN_MODAL_ENTER_PASSWORD_ADMIN,
            payload: {
                  status
            }
      })
}