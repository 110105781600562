import React, {useEffect, useState} from 'react';
import AuthFeature from "../index";
import {Button, Form, Input, TimePicker, Upload, Modal} from "antd";
import {LockOutlined, UserOutlined, PhoneOutlined, PlusOutlined, MailOutlined, EnvironmentOutlined, CheckCircleOutlined} from '@ant-design/icons'
import AlertCommon from "../../../components/alert";
import {ALERT_ERROR} from "../../../constants/common";
import {signUp} from "../../../actions/auth";
import {useNavigate} from 'react-router-dom'
import {convertToTZ, normalizeInput, replaceFormatPhoneLogin} from "../../../utils/helper";
import '../../../stylesheets/sign-up.scss'
import PlacesAutocomplete, {geocodeByAddress, geocodeByPlaceId, getLatLng} from "react-places-autocomplete";
import {getTimeZoneByLocation} from "../../../actions/common";
import moment from "moment";
import {getValueDefault} from "../../../actions/setting";

const SignUp = (props) => {
      const [form] = Form.useForm();
      const [loading, setLoading] = useState(false)
      const navigate = useNavigate()
      const [phoneLogin, setPhoneLogin] = useState('')
      const [phoneSalon, setPhoneSalon] = useState('')
      const [images, setImages] = useState([])
      const [staffs, setStaffs] = useState([
            {
                  key: 1,
                  name: '',
                  phone: ''
            }
      ])
      const [services, setServices] = useState([
            {
                  key : 1,
                  name: '',
                  price: ''
            }
      ])
      const [location, setLocation] = useState('')
      const [coordinates, setCoordinates] = useState({
            lat : 0,
            lng : 0,
            timezone: '',
            tz: '',
            city: null,
            state: null,
            zipcode: null,
            country: null
      })
      const [schedules, setSchedules] = useState([
            {
                  id : 1,
                  day: 0,
                  day_name: 'Thứ 2',
                  start: '08:00:00',
                  end: '18:30:00'
            },
            {
                  id : 2,
                  day: 1,
                  day_name: 'Thứ 3',
                  start: '08:00:00',
                  end: '18:30:00'
            },
            {
                  id : 3,
                  day: 2,
                  day_name: 'Thứ 4',
                  start: '08:00:00',
                  end: '18:30:00'
            },
            {
                  id : 4,
                  day: 3,
                  day_name: 'Thứ 5',
                  start: '08:00:00',
                  end: '18:30:00'
            },
            {
                  id : 5,
                  day: 4,
                  day_name: 'Thứ 6',
                  start: '08:00:00',
                  end: '18:30:00'
            },
            {
                  id : 6,
                  day: 5,
                  day_name: 'Thứ 7',
                  start: '08:00:00',
                  end: '18:30:00'
            },
            {
                  id : 7,
                  day: 6,
                  day_name: 'Chủ nhật',
                  start: '08:00:00',
                  end: '18:30:00'
            }
      ])
      const [fee, setFee] = useState(0)
      const [email, setEmail] = useState('')

      useEffect(() => {
            (async () => {
                  try {
                        const res = await getValueDefault()
                        const feeService = res.find(item => item.default_key === 'fee_update_info_salon')
                        const emailConfig = res.find(item => item.default_key === 'email_admin')

                        setFee(feeService ? parseFloat(feeService.value) : 0)
                        setEmail(emailConfig ? emailConfig.value : '')
                  } catch (e) {
                      console.log(e);
                  }
            })()
      }, [])

      const renderFunc =  ({ getInputProps, getSuggestionItemProps, suggestions }) => {
            return (
                  <div className="autocomplete-root">
                        <Input prefix={<EnvironmentOutlined className="site-form-item-icon"/>}  {...getInputProps({placeholder: 'Địa chỉ salon' })} />
                        <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map(suggestion => {
                                    const style = {
                                          backgroundColor : suggestion.active ? '#92278f' : '#fff' ,
                                          padding : '10px',
                                          color: suggestion.active ? '#fff' : '#000' ,
                                          cursor: 'pointer'
                                    }
                                    return (
                                          <div key={suggestion.placeId} {...getSuggestionItemProps(suggestion, { style })}>
                                                <span>{suggestion.description}</span>
                                          </div>
                                    );
                              })}
                        </div>
                  </div>
            )
      };

      const handleChangePhone = (e, type) => {
            if(type === 'login'){
                  return setPhoneLogin(prevState => normalizeInput(e.target.value, prevState))
            }
            if(type === 'salon'){
                  return setPhoneSalon(prevState => normalizeInput(e.target.value, prevState))
            }
      }

      const handleChangeUpload = ({ fileList: newFileList }) => {
            setImages(newFileList)
      }

      const dummyRequest = ({file, onSuccess}) => {
            setTimeout(() => {
                  onSuccess(true);
            }, 0);
      };

      const handleSelectAddress = async value => {
            const results = await geocodeByAddress(value);

            const latLng = await getLatLng(results[0]);
            const timezoneByLocation = await getTimeZoneByLocation(latLng.lat, latLng.lng)

            const [place] = await geocodeByPlaceId(results[0].place_id)
            let city = null
            let state = null
            let zipcode = null
            let country = null

            if (place.address_components) {
                  let cityData = place.address_components.filter(item => item.types.join().includes('locality'))
                  if (cityData.length > 0) {
                        city = cityData[0].long_name
                  }
                  let stateData = place.address_components.filter(item => item.types.join().includes('administrative_area_level_1'))
                  if (stateData.length > 0) {
                        state = stateData[0].short_name
                  }
                  let zipcodeData = place.address_components.filter(item => item.types.join().includes('postal_code'))
                  if (zipcodeData.length > 0) {
                        zipcode = zipcodeData[0].short_name
                  }
                  let countryData = place.address_components.filter(item => item.types.join().includes('country'))
                  if (countryData.length > 0) {
                        country = countryData[0].short_name
                  }
            }

            setCoordinates({
                  lat : latLng.lat,
                  lng : latLng.lng,
                  timezone: timezoneByLocation.timeZoneId,
                  tz: `UTC ${timezoneByLocation.rawOffset / 3600}`,
                  city: city,
                  state: state,
                  zipcode: zipcode,
                  country : country
            })
            setLocation(results[0].formatted_address);
      };

      const renderScheduleSalon = () => {
            return schedules.map((item, index) =>
                  <div className="item" key={item.id}>
                        <label className='label-day'>{item.day_name}</label>
                        <div className="input-time">
                              <Form.Item>
                                    <TimePicker minuteStep={15} onChange={(time, timeString) => onChangeTime(time, timeString, item.day, 'start')} defaultValue={item.start ? moment(item.start, 'HH:mm:ss') : null} popupClassName='dropdown-schedule'  showNow={false} placeholder='Giờ mở cửa' format='hh:mm A' />
                              </Form.Item>
                              <span>đến</span>
                              <Form.Item>
                                    <TimePicker minuteStep={15} onChange={(time, timeString) => onChangeTime(time, timeString, item.day, 'end')} defaultValue={item.end ? moment(item.end, 'HH:mm:ss') : null} popupClassName='dropdown-schedule' showNow={false} placeholder='Giờ đóng cửa' format='hh:mm A' />
                              </Form.Item>
                        </div>
                  </div>

            )
      }

      const onChangeTime = (time, timeString, day, type) => {
            const newSchedules = schedules
            let  index = newSchedules.findIndex(item => item.day === day)
            if(type === 'start'){
                  newSchedules[index].start = time ?  moment(time).format('HH:mm:ss') : null
            }
            if(type === 'end'){
                  newSchedules[index].end = time ? moment(time).format('HH:mm:ss') : null
            }
            setSchedules(newSchedules)
      };

      const addMoreService = () => {
            let newKey = Math.max(...services.map(o => o.key)) + 1
            setServices([
                  ...services,
                  {
                        key: newKey,
                        name: '',
                        price: ''
                  }
            ])
      }

      const deleteService = (key) => {
            if(services.length === 1){
                  setServices([
                        {
                              key: 1,
                              name: '',
                              price: ''
                        }
                  ])
                  return
            }
            let newListServices = services.filter(item => item.key !== key)
            setServices(newListServices)
      }

      const addMoreStaff = () => {
            let newKey = Math.max(...staffs.map(o => o.key)) + 1
            setStaffs([
                  ...staffs,
                  {
                        key: newKey,
                        name: '',
                        phone: ''
                  }
            ])
      }

      const deleteStaff = (key) => {
            if(staffs.length === 1){
                  setStaffs([
                        {
                              key: 1,
                              name: '',
                              phone: ''
                        }
                  ])
                  return
            }
            let newListStaff = staffs.filter(item => item.key !== key)
            setStaffs(newListStaff)
      }

      const onChangeInputText = (e, type, data) => {
            if(type === 'service'){
                  setServices(services.map(item => {
                        if (item.key === data.key) {
                              return data;
                        }
                        return item
                  }));
            }
            if(type === 'staff'){
                  setStaffs(staffs.map(item => {
                        if (item.key === data.key) {
                              return data;
                        }
                        return item
                  }));
            }
      }

      const onSignUp = async (e) => {
            try {
                  setLoading(true)
                  let formData = new FormData()

                  const files = []
                  e.phone = phoneLogin ?  replaceFormatPhoneLogin(phoneLogin) : ''
                  e.name = e.name.trim()
                  e.salon_phone = phoneSalon ? replaceFormatPhoneLogin(phoneSalon) : ''
                  e.salon_name = e.salon_name.trim()
                  e.salon_address = location
                  e.salon_lat = coordinates.lat
                  e.salon_lng = coordinates.lng
                  e.salon_timezone = coordinates.timezone
                  e.salon_tz =  coordinates.tz
                  e.salon_city =  coordinates.city
                  e.salon_state =  coordinates.state
                  e.salon_zipcode =  coordinates.zipcode
                  e.salon_country =  coordinates.country

                  const scheduleData = []
                  const dateNow = moment().utc().format('YYYY-MM-DD')
                  schedules.map(item => {
                        if(!item.start || !item.end){
                              scheduleData.push({
                                    day : item.day,
                                    start_time: null,
                                    end_time: null
                              })
                        } else{
                              const convertStartTimeToUtc = convertToTZ(new Date(`${dateNow} ${item.start}`), 'UTC')
                              const convertEndTimeToUtc = convertToTZ(new Date(`${dateNow} ${item.end}`), 'UTC')
                              scheduleData.push({
                                    day : item.day,
                                    start_time: convertStartTimeToUtc.hour,
                                    end_time: convertEndTimeToUtc.hour
                              })
                        }
                  })
                  e.schedules = JSON.stringify(scheduleData)
                  let listStaff = staffs.map(el => {return {...el, phone : replaceFormatPhoneLogin(el.phone)}})

                  console.log(listStaff);

                  e.staffs = JSON.stringify(listStaff)
                  e.services = JSON.stringify(services)

                  const imageUpload = images.filter(item => item.originFileObj)
                  if(imageUpload.length > 0){
                        imageUpload.map(item => files.push(item.originFileObj))
                  }
                  Object.keys(e).forEach(item => formData.append(item, e[item]))
                  Object.keys(files).forEach((key, i) => {
                        formData.append('images', files[key]);
                  });
                  await signUp(formData)
                  Modal.success({
                        icon: <CheckCircleOutlined />,
                        footer: 'Đòng ý',
                        content:
                              <div className='content-sign-up-success'>
                                    <h4>Đăng ký thành công! Vui lòng đợi quản trị viên phê duyệt</h4>
                                    <p>Trong thời gian xét duyệt, bạn vẫn có thể đăng nhập để chỉnh sửa thông tin thẩm mỹ viện, thêm dịch vụ, thêm nhân viên và nhận thông báo phê duyệt từ quản trị viên. Tuy nhiên, thẩm mỹ viện của bạn sẽ không xuất hiện trong danh sách 123 giải pháp tốt nhất mà khách hàng có thể nhìn thấy và sẽ không nhận được đặt chỗ từ khách hàng.</p>
                                    <p>Khi tài khoản của bạn được phê duyệt, chúng tôi sẽ thông báo cho bạn qua ứng dụng</p>
                              </div>,
                        className: 'modal-confirm-sign-up-success',
                        onOk() {
                              return navigate('/')
                        }
                  });
                  setLoading(false)
            } catch (e) {
                  setLoading(false)
                  return AlertCommon(ALERT_ERROR, e.message)
            }
      }

      return (
            <AuthFeature>
                  <div className='sign-up-page'>
                        <div className="wp-inner container">
                              <div className="head">
                                    <Button onClick={() => navigate('/')}><i className="fa-solid fa-left-long"></i>Trở lại</Button>
                                    <h3>Đăng kí</h3>
                              </div>
                              <div className="wp-form">
                                    <Form form={form}
                                          layout='vertical'
                                          name="sign-up-hooks"
                                          onFinish={onSignUp}
                                          initialValues={{
                                                name: '',
                                                phone: '',
                                                password: '',
                                                salon_name: '',
                                                salon_phone: '',
                                                salon_email: '',
                                                salon_description: '',
                                          }}
                                    >
                                          <div className="widget">
                                                <div className="widget-head">
                                                      <h4>Cung cấp thông tin để tạo tài khoản</h4>
                                                </div>
                                                <div className="widget-body">
                                                      <Form.Item name='name'>
                                                            <Input placeholder="Tên chủ salon"  prefix={<UserOutlined
                                                                  className="site-form-item-icon"/>}/>
                                                      </Form.Item>
                                                      <Form.Item >
                                                            <Input placeholder="Số điện thoại dùng để đăng nhập" value={phoneLogin} onChange={(e) => handleChangePhone(e, 'login')} prefix={<PhoneOutlined
                                                                  className="site-form-item-icon"/>}/>
                                                      </Form.Item>
                                                      <Form.Item name="password" >
                                                            <Input.Password
                                                                  placeholder="Mật khẩu"
                                                                  iconRender={visible => (visible ? 'Hide' : 'Show')}
                                                                  prefix={<LockOutlined
                                                                        className="site-form-item-icon"/>}
                                                            />
                                                      </Form.Item>
                                                </div>
                                          </div>

                                          <div className="widget">
                                                <div className="widget-head">
                                                      <h4>Cung cấp thông tin salon</h4>
                                                </div>
                                                <div className="widget-body">
                                                      <Form.Item name='salon_name'>
                                                            <Input placeholder="Tên salon"  prefix={<UserOutlined
                                                                  className="site-form-item-icon"/>}/>
                                                      </Form.Item>
                                                      <Form.Item>
                                                            <PlacesAutocomplete value={location}
                                                                                onChange={value =>  {
                                                                                      setLocation(value)
                                                                                }}
                                                                                searchOptions={{
                                                                                      types: ["geocode", "establishment"],
                                                                                      componentRestrictions: { country: ['us'] },

                                                                                }}
                                                                                onSelect={handleSelectAddress}
                                                            >
                                                                  {renderFunc}
                                                            </PlacesAutocomplete>
                                                      </Form.Item>
                                                      <Form.Item >
                                                            <Input placeholder="Số điện thoại liên hệ" value={phoneSalon} onChange={(e) => handleChangePhone(e, 'salon')} prefix={<PhoneOutlined
                                                                  className="site-form-item-icon"/>}/>
                                                      </Form.Item>
                                                      <Form.Item className='mb-0' name='salon_email'>
                                                            <Input placeholder="Email"  prefix={<MailOutlined
                                                                  className="site-form-item-icon"/>}/>
                                                      </Form.Item>
                                                      <p className="desc mb-2">( Không bắt buộc, dùng để nhận thông báo từ 123BestSolution và feedback từ khách hàng )</p>
                                                      <Form.Item className='mb-0'>
                                                            <Form.Item className='mb-0' label='Hình ảnh của salon ( không gian tiệm, biển hiệu, menu ..)'>
                                                                  <p className="desc mt-0">
                                                                        ( Không bắt buộc, bạn có thể thêm vào sau )
                                                                  </p>
                                                            </Form.Item>
                                                            <Form.Item>
                                                                  <Upload
                                                                        className='upload-image-salon'
                                                                        listType="picture-card"
                                                                        fileList={images}
                                                                        onChange={handleChangeUpload}
                                                                        customRequest={dummyRequest}
                                                                        accept=".jpg,.jpeg,.png"
                                                                        showUploadList={{showPreviewIcon: false, showRemoveIcon: true}}
                                                                  >
                                                                        {images.length >= 10 ? null : <PlusOutlined />}
                                                                  </Upload>
                                                            </Form.Item>
                                                      </Form.Item>
                                                      <Form.Item label='Giờ mở cửa - Cập nhật'>
                                                            <div className="business-hour">
                                                                  {renderScheduleSalon()}
                                                            </div>
                                                      </Form.Item>
                                                      <Form.Item name="salon_description">
                                                            <Input.TextArea
                                                                  placeholder="Thông tin giới thiệu về salon ( Không bắt buộc, có thể thêm vào sau )"
                                                                  autoSize={{ minRows: 4}}
                                                            />
                                                      </Form.Item>
                                                </div>
                                          </div>

                                          <h4>Cung cấp thông tin dịch vụ và thợ</h4>
                                          <div className="widget">
                                                <div className="widget-head">
                                                      <h4>Thêm danh sách các dịch vụ của salon</h4>
                                                      <p className="desc mt-0">( Không bắt buộc, bạn có thể thêm vào sau )</p>
                                                </div>
                                                <div className="widget-body">
                                                      <div className="wd-title">
                                                            <div>Dịch vụ</div>
                                                            <div>Giá tiền</div>
                                                            <div>
                                                                  <Button onClick={addMoreService}> <i className="fa-regular fa-square-plus"></i>Thêm</Button>
                                                            </div>
                                                      </div>
                                                      <div className="wd-content">
                                                            {services.length > 0 &&
                                                                  services.map(service =>
                                                                        <div className='item' key={service.key}>
                                                                              <div key={service.key}>
                                                                                    <Form.Item>
                                                                                          <Input placeholder="Tên dịch vụ"
                                                                                                 onChange={(e) => onChangeInputText(e, 'service', {...service, name: e.target.value})}
                                                                                                 value={service.name}/>
                                                                                    </Form.Item>
                                                                              </div>
                                                                              <div>
                                                                                    <Form.Item>
                                                                                          <Input placeholder="Giá dịch vụ"
                                                                                                 onChange={(e) => onChangeInputText(e, 'service', {...service, price: e.target.value})}
                                                                                                 value={service.price}/>
                                                                                    </Form.Item>
                                                                              </div>
                                                                              <div>
                                                                                    <Form.Item>
                                                                                          <Button onClick={() => deleteService(service.key)}>Xóa</Button>
                                                                                    </Form.Item>
                                                                              </div>
                                                                        </div>
                                                                  )
                                                            }
                                                      </div>
                                                </div>
                                          </div>

                                          <div className="widget">
                                                <div className="widget-head">
                                                      <h4>Thêm danh sách thợ của salon</h4>
                                                      <p className="desc mt-0">( Không bắt buộc, bạn có thể thêm vào sau )</p>
                                                </div>
                                                <div className="widget-body">
                                                      <div className="wd-title">
                                                            <div>Tên thợ</div>
                                                            <div>Số điện thoại</div>
                                                            <div>
                                                                  <Button onClick={addMoreStaff}> <i className="fa-regular fa-square-plus"></i>Thêm</Button>
                                                            </div>
                                                      </div>
                                                      <div className="wd-content">
                                                            {staffs.map(staff =>
                                                                  <div key={staff.key} className='item'>
                                                                        <div>
                                                                              <div className="staff-name">
                                                                                    <Form.Item>
                                                                                          <Input placeholder="Họ tên"
                                                                                                 onChange={(e) => onChangeInputText(e, 'staff', {...staff, name: e.target.value})}
                                                                                                 value={staff.name}
                                                                                          />
                                                                                    </Form.Item>
                                                                              </div>
                                                                        </div>
                                                                        <div>
                                                                              <Form.Item >
                                                                                    <Input placeholder="Số điện thoại" value={staff.phone}
                                                                                           onChange={(e) => onChangeInputText(e, 'staff', {...staff, phone: normalizeInput(e.target.value, staff.phone)})}
                                                                                    />
                                                                              </Form.Item>
                                                                        </div>
                                                                        <div>
                                                                              <Form.Item>
                                                                                    <Button onClick={() => deleteStaff(staff.key)}>Xóa</Button>
                                                                              </Form.Item>
                                                                        </div>
                                                                  </div>
                                                            )}
                                                      </div>
                                                </div>
                                          </div>
                                          <div className="more-desc">
                                                <p className="desc">
                                                      Nếu bạn không có thời gian để nhập, hãy gửi danh sách dịch vụ và danh sách thợ của bạn, chúng tôi sẽ nhập vào cho bạn với mức phí là ${fee}
                                                </p>
                                                <p className="desc">
                                                      Thêm danh sách dịch vụ và thợ hoặc gửi hình ảnh về địa chỉ email bên dưới
                                                </p>
                                                <p className="email">{email}</p>
                                          </div>

                                          <div className="btn-form">
                                                <Button loading={loading} type="primary"
                                                        htmlType="submit">
                                                      Đăng kí
                                                </Button>
                                                <p className="desc">
                                                      Sau khi đăng kí, bạn cần đợi 123BestSolutions kiểm duyệt thông tin salon. Thời gian phê duyệt trong vòng 8 giờ
                                                </p>
                                          </div>
                                    </Form>

                              </div>
                        </div>
                  </div>
            </AuthFeature>
      );
}

export default SignUp;