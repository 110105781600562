import React, {useEffect, useState} from 'react';
import {Button, Modal, Spin} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {toggleModelHistoryCheckInOutStaff} from "../../../actions/modal";
import { LoadingOutlined } from '@ant-design/icons';
import AlertCommon from "../../../components/alert";
import {ALERT_ERROR} from "../../../constants/common";
import {convertTZLocal, getFirstDayWeek, getLastDayWeek} from "../../../utils/helper";
import moment from "moment";
import { LeftOutlined , RightOutlined} from '@ant-design/icons';
import {historyCheckInOut} from "../../../actions/staff";
import {useTranslation} from "react-i18next";
import LoadingData from "../../../components/load";

const ModalHistoryCheckInOut = (props) =>  {
      const {staffSelect, openHistoryCheckInCheckOut} = useSelector(state => state.modal)
      const [histories, setHistories] = useState([])
      const [loadData, setLoadData] = useState(true)
      const [startDate , setStartDate] = useState(getFirstDayWeek())
      const [endDate, setEndDate] = useState(getLastDayWeek())
      const dispatch = useDispatch()
      const {t} = useTranslation()

      useEffect(() => {
            (async () => {
                  try {
                        setLoadData(true)
                        if(!staffSelect) return
                        const paramSearch = {
                              id : staffSelect.id,
                              date: startDate,
                              to_date: endDate
                        }
                        const res = await historyCheckInOut(paramSearch)
                        setHistories(res.data)
                        setLoadData(false)
                  } catch (e) {
                      return AlertCommon(ALERT_ERROR, e.message)
                  }
            })()
      }, [staffSelect, startDate, endDate])

      const renderHistories = () => {
            return histories.map(item =>
                  <li className="item" key={item.id}>
                        <div className="day">
                              <p>{item.day_name}</p>
                        </div>
                        <div className="list-check-in-out">
                              {item.list_check_in_out.length > 0 &&
                                    <>
                                          <div className='in'>
                                                <p className='title'>{t('form.label_in')}</p>
                                                {item.list_check_in_out.filter(i => i.status === 1).map(k =>
                                                      <p key={item.id}>
                                                            <span>{convertTZLocal(k.date_time).hour}</span>
                                                      </p>
                                                )}
                                          </div>
                                          <div className='out'>
                                                <p className='title'>{t('form.label_out')}</p>
                                                {item.list_check_in_out.filter(i => i.status === 2).map(k =>
                                                      <p key={item.id}>
                                                            <span>{convertTZLocal(k.date_time).hour}</span>
                                                      </p>
                                                )}
                                          </div>
                                    </>
                              }
                        </div>
                  </li>
            )
      }

      const changeDateFilter = (day) => {
            setStartDate(moment(new Date(startDate), "DD-MM-YYYY").add(day, 'days').format('YYYY-MM-DD'))
            setEndDate(moment(new Date(endDate), "DD-MM-YYYY").add(day, 'days').format('YYYY-MM-DD'))
      }

      const closeModal = () => {
            setStartDate(getFirstDayWeek())
            setEndDate(getLastDayWeek())
            dispatch(toggleModelHistoryCheckInOutStaff(null, false))
      }

      return (
            <Modal
                  footer={null}
                  visible={openHistoryCheckInCheckOut}
                  maskClosable={false}
                  closable={false}
                  centered
                  className='modal-general modal-history-check-in-out modal-table'
            >
                  <div className="head-modal">
                        <p className="title">{t('modal.title_check_in_out_history')}</p>
                        <Button onClick={closeModal}>{t('button.close')}</Button>
                  </div>
                  <div className="body-modal">
                        {loadData ?
                             <LoadingData/>
                        :
                              <div className='wp-history'>
                                    <div className="date-filter">
                                          <Button onClick={() => changeDateFilter(-7)}><LeftOutlined/></Button>
                                          <h6>{moment(startDate).format('LL')} <span>-</span> {moment(endDate).format('LL')}</h6>
                                          <Button onClick={() => changeDateFilter(7)} disabled={endDate >= moment(new Date()).format('YYYY-MM-DD')}><RightOutlined/></Button>
                                    </div>
                                    <ul className="histories">
                                          {renderHistories()}
                                    </ul>
                              </div>
                        }
                  </div>
            </Modal>
      );
}

export default ModalHistoryCheckInOut;