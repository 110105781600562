import axios from "axios";

const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
            'Content-Type': 'application/json',
      }
});

axiosInstance.interceptors.request.use(function (config) {
      config.headers.common.Authorization = `Bearer ${localStorage.getItem('access_token')}`
      config.headers.common.lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'vi'

      const urlCustomer = [
            '/user/update-profile',
            '/appointment/customer-create-appointment',
            '/auth/change-password',
      ]
      if(urlCustomer.includes(config.url)){
            config.headers.common.Authorization = `Bearer ${localStorage.getItem('customer_access_token')}`
      }
      return config;
}, function (error) {
      return Promise.reject(error);
});

axiosInstance.interceptors.response.use(function (response) {
      return response.data;
}, function (error) {
      return Promise.reject(error.response.data);
});
export default axiosInstance;
