import notificationApi from "../api/notificationApi";
import {
      DELETE_ALL_NOTIFICATION, DELETE_NOTIFICATION,
      LIST_NOTIFICATION,
      NEW_NOTIFICATION,
      READ_ALL_NOTIFICATION, UPDATE_NOTIFICATION,
} from "../constants/action";
import {NOT_READ, READ} from "../constants/common";

export const getListNotifications = (data) => (dispatch) => {
      return new Promise((resolve, reject) => {
            notificationApi.getListNotification(data).then(
                  (res) => {
                        dispatch({
                              type: LIST_NOTIFICATION,
                              payload: res.data,
                        });
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
}

export const addNewNotification = (data) => (dispatch) => {
      return dispatch({
            type: NEW_NOTIFICATION,
            payload: data,
      });
}

export const readNotification = (id) => (dispatch) => {
      return new Promise((resolve, reject) => {
            notificationApi.readNotification(id).then(
                  (res) => {
                        return dispatch({
                              type: UPDATE_NOTIFICATION,
                              payload: {
                                    id : id,
                                    status: READ
                              },
                        });
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
}

export const unreadNotification = (id) => (dispatch) => {
      return new Promise((resolve, reject) => {
            notificationApi.unreadNotification(id).then(
                  (res) => {
                        return dispatch({
                              type: UPDATE_NOTIFICATION,
                              payload: {
                                    id : id,
                                    status: NOT_READ
                              },
                        });
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
}

export const deleteNotification = (arr, numberUnread) => (dispatch) => {
      return new Promise((resolve, reject) => {
            notificationApi.deleteNotification(arr).then(
                  (res) => {
                        dispatch({
                              type: DELETE_NOTIFICATION,
                              payload: {
                                    arr : JSON.parse(arr),
                                    number_unread : numberUnread
                              },
                        });
                        return resolve(res)
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
}

export const readAllNotification = () => (dispatch) => {
      return new Promise((resolve, reject) => {
            notificationApi.readAllNotification().then(
                  (res) => {
                        return dispatch({
                              type: READ_ALL_NOTIFICATION,
                              payload: res.data,
                        });
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
}

export const deleteAllNotification = () => (dispatch) => {
      return new Promise((resolve, reject) => {
            notificationApi.deleteAllNotification().then(
                  (res) => {
                        dispatch({
                              type: DELETE_ALL_NOTIFICATION,
                              payload: res.data,
                        });
                        return resolve(res)
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
}