import React from 'react';
import {Button, Drawer} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {STAFF_AVAILABLE, STAFF_BREAK, STAFF_WORKING} from "../../../constants/common";
import {setOpenStaffPanel, setStaffSelected} from "../../../actions/staff";
import {toggleShowModalWorking} from "../../../actions/modal";
import {useTranslation} from "react-i18next";


const ModalWorkingRight = (props) =>  {
      const {t} = useTranslation()
      const {openWorking} = useSelector(state => state.modal)
      const placement = 'right'
      const {staffs} = useSelector(state => state.staff)
      const dispatch = useDispatch()

      const handleShowStaffPanel = async (staff) => {
            await dispatch(setStaffSelected(staff))
            dispatch(setOpenStaffPanel(true))
            return dispatch(toggleShowModalWorking(false))
      }

      const renderListStaff = () => {
            return staffs.map(item => (
                  <div key={item.id} className="item">
                        <div className="staff">
                              <p className="name" onClick={() => handleShowStaffPanel(item)}>{item.first_name} {item.last_name}</p>
                              <p className="check-in">
                                   <span className={`time ${item.status === STAFF_AVAILABLE && 'available'} ${item.status === STAFF_WORKING && 'working'} ${item.status === STAFF_BREAK && 'break'}`}>
                                         <span>
                                               <i className="fa-solid fa-circle"></i>
                                               {item.status === STAFF_AVAILABLE && item.time_check_in}
                                               {(item.status === STAFF_WORKING || item.status === STAFF_BREAK) && item.status_name}
                                         </span>
                                   </span>
                              </p>
                        </div>
                        {(item.appointment_processing && item.status === STAFF_WORKING) &&
                        <div className="customer">
                              <p className="name">{item.appointment_processing.customer_name}</p>
                              <p className="time">#{item.appointment_processing.id} - {item.appointment_processing.date_appointment_format}</p>
                        </div>
                        }
                  </div>
            ))
      }

      return (
            <Drawer
                  placement={placement}
                  closable={false}
                  visible={openWorking}
                  key={placement}
                  mask={false}
                  width={300}
                  className='main-menu'
            >
                  <div className="head-drawer">
                        <h3 className="title">{t('slider.title_staff_list')}</h3>
                        <Button onClick={() => dispatch(toggleShowModalWorking(false))}>x</Button>
                  </div>
                  <div className="body-drawer">
                        <div className="list-staff-working">
                              <div className="head">
                                    <p className="staff">{t('slider.title_column_staff')}</p>
                                    <p className="customer">{t('slider.title_column_customer')}</p>
                              </div>
                              <div className="body">
                                    {renderListStaff()}
                              </div>
                        </div>
                  </div>
            </Drawer>
      );
}

export default ModalWorkingRight;