import axiosInstance from "./axiosInstance";

const base_path = '/appointment'
const appointmentApi = {
      createAppointment(data){
            const url = `${base_path}/admin-create-appointment`
            return axiosInstance.post(url, data)
      },
      updateAppointment(data){
            const url = `${base_path}/admin-update-appointment`
            return axiosInstance.post(url, data)
      },
      changeStaffInAppointment(data){
            const url = `${base_path}/update-staff-in-appointment`
            return axiosInstance.post(url, data)
      },
      removeAppointment(id){
            const url = `${base_path}/remove-appointment`
            return axiosInstance.post(url, {id})
      },
      cancelAppointment(data){
            const url = `${base_path}/cancel-appointment-by-admin`
            return axiosInstance.post(url, data)
      },
      listAppointmentForStaff(id, date){
            const url = `${base_path}/list-appointment-for-staff/${id}`
            return axiosInstance.get(url, {params : {date}})
      },
      updateStatusAppointment(data){
            const url = `${base_path}/update-status-appointment`
            return axiosInstance.post(url, data)
      },
      customerWalkIn(id) {
            const url = `${base_path}/customer-walk-in`
            return axiosInstance.post(url, {appointment_id : id})
      },
      handleAppointment(data) {
            const url = `${base_path}/admin-handle-appointment`
            return axiosInstance.post(url, data)
      },
      customerCreateAppointment(formData){
            const url = `${base_path}/customer-create-appointment`
            return axiosInstance.post(url, formData)
      },
}

export default appointmentApi