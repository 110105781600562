import axiosInstance from "./axiosInstance";

const base_path = '/dashboard'
const dashboardApi = {
      listAppointmentInDashboard(){
            const url = `${base_path}/list-appointment-in-dashboard`
            return axiosInstance.get(url)
      },
      reportByDate(date){
            const url = `${base_path}/report`
            return axiosInstance.get(url, {params: {date}})
      }
}

export default dashboardApi