import React from 'react';

import {Route, Routes } from "react-router-dom";
import Login from "../features/auth/pages/login";
import DashboardFeature from "../features/dashboard";
import ForgotPassword from "../features/auth/pages/forgot-password";
import PolicyPage from "../features/support/pages/policy";
import FaqsPage from "../features/support/pages/faqs";
import SignUp from "../features/auth/pages/sign-up";
import CustomerCheckIn from "../features/auth/pages/customer-checkin";
import AppointmentFeature from "../features/booking";

function ListRouters(props) {
      return (
            <>
                  <Routes>
                        <Route exact path='/' element={<Login/>}/>
                        <Route exact path='/sign-up' element={<SignUp/>}/>
                        <Route exact path='/forgot-password' element={<ForgotPassword/>}/>
                        <Route path="/dashboard" element={<DashboardFeature/>}/>
                        <Route path="/policy" element={<PolicyPage/>}/>
                        <Route path="/support-faq" element={<FaqsPage/>}/>
                        <Route path="/customer-checkin" element={<CustomerCheckIn/>}/>
                        <Route path="/booking" element={<AppointmentFeature/>}/>
                  </Routes>
            </>
      );
}

export default ListRouters;