import axiosInstance from "./axiosInstance";

const base_path = '/service'
const serviceApi = {
      listService(){
            const url = `${base_path}/list-service`
            return axiosInstance.get(url)
      },
      createService(data){
            const url = `${base_path}/create-service`
            return axiosInstance.post(url, data)
      },
      deleteService(id){
            const url = `${base_path}/delete-service`
            return axiosInstance.post(url, {id})
      },
      updateService(data){
            const url = `${base_path}/update-service`
            return axiosInstance.post(url, data)
      },
      changeActive(id) {
            const url = `${base_path}/change-active`
            return axiosInstance.post(url, {id})
      },
      getListServicesInBooking(salonId){
            const url = `${base_path}/list-service-booking`
            return axiosInstance.post(url, {salon_id : salonId})
      },
}

export default serviceApi