import appointmentApi from "../api/appointmentApi";
import {
      CREATE_APPOINTMENT, OPEN_APPOINTMENT_DETAIL,
      REMOVE_APPOINTMENT, SET_APPOINTMENT_SELECTED, CUSTOMER_ADD_NEW_APPOINTMENT,
      UPDATE_APPOINTMENT, UPDATE_STAFF
} from '../constants/action'

export const addNewAppointment = (data) => (dispatch) => {
      return new Promise((resolve, reject) => {
            appointmentApi.createAppointment(data).then(
                  (res) => {
                        dispatch({
                              type: CREATE_APPOINTMENT,
                              payload: res.data,
                        });
                        resolve(res)
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
}

export const changeStaffInAppointment = (data) => (dispatch) => {
      return new Promise((resolve, reject) => {
            appointmentApi.changeStaffInAppointment(data).then(
                  (res) => {
                        dispatch({
                              type: UPDATE_APPOINTMENT,
                              payload: res.data,
                        });
                        resolve(res)
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
}

export const updateAppointment = (data) => (dispatch) => {
      return new Promise((resolve, reject) => {
            appointmentApi.updateAppointment(data).then(
                  (res) => {
                        dispatch({
                              type: UPDATE_APPOINTMENT,
                              payload: res.data,
                        });
                        resolve(res)
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
}

export const removeAppointment = (id) => (dispatch) => {
      return new Promise((resolve, reject) => {
            appointmentApi.removeAppointment(id).then(
                  (res) => {
                        dispatch({
                              type: REMOVE_APPOINTMENT,
                              payload: id,
                        });
                        resolve(res)
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
}

export const cancelAppointment = (data) => (dispatch) => {
      return new Promise((resolve, reject) => {
            appointmentApi.cancelAppointment(data).then(
                  (res) => {
                        dispatch({
                              type: UPDATE_APPOINTMENT,
                              payload: res.data,
                        });
                        resolve(res)
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
}

export const getListAppointmentForStaff = (id, date) => {
      return new Promise((resolve, reject) => {
            appointmentApi.listAppointmentForStaff(id, date).then(
                  (res) => {
                        return resolve(res)
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
};

export const setOpenAppointmentDetail = (status) => (dispatch) => {
      return dispatch({
            type: OPEN_APPOINTMENT_DETAIL,
            payload: status,
      });
}

export const setAppointmentSelected = (appointment) => (dispatch) => {
      return dispatch({
            type: SET_APPOINTMENT_SELECTED,
            payload: appointment,
      });
}

export const updateStatusAppointment = (data) => (dispatch) => {
      return new Promise((resolve, reject) => {
            appointmentApi.updateStatusAppointment(data).then(
                  (res) => {
                        dispatch({
                              type: UPDATE_APPOINTMENT,
                              payload: res.data.appointment,
                        });
                        dispatch({
                              type: UPDATE_STAFF,
                              payload: res.data.staff,
                        });
                        resolve(res)
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
}

export const customerWalkIn = (id) => (dispatch) => {
      return new Promise((resolve, reject) => {
            appointmentApi.customerWalkIn(id).then(
                  (res) => {
                        dispatch({
                              type: UPDATE_APPOINTMENT,
                              payload: res.data
                        });
                        resolve(res)
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
}

export const handleAppointment = (data) => (dispatch) => {
      return new Promise((resolve, reject) => {
            appointmentApi.handleAppointment(data).then(
                  (res) => {
                        dispatch({
                              type: UPDATE_APPOINTMENT,
                              payload: res.data
                        });
                        resolve(res)
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
}

export const updateAppointmentByClient = (data) => (dispatch) => {
      return dispatch({
            type: UPDATE_APPOINTMENT,
            payload: data,
      });
}

export const updateAppointmentBySystem = (data) => (dispatch) => {
      return dispatch({
            type: UPDATE_APPOINTMENT,
            payload: data,
      });
}

export const customerCreateAppointment = (formData) => (dispatch) => {
      return new Promise((resolve, reject) => {
            appointmentApi.customerCreateAppointment(formData).then(
                  (res) => {
                        dispatch({
                              type: CUSTOMER_ADD_NEW_APPOINTMENT,
                              payload: res.data,
                        });
                        resolve(res)
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
}