import React, {useState} from 'react';
import {Button, Form, Input, Modal} from "antd";
import AlertCommon from "../../../components/alert";
import {ALERT_ERROR, ALERT_SUCCESS} from "../../../constants/common";
import {LockOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {toggleModalEnterPasswordAdmin} from "../../../actions/modal";
import '../../../stylesheets/customer-change-password.scss'
import {updateAdminNeedEnterPassword, verifyAdmin} from "../../../actions/auth";
import {useNavigate} from "react-router-dom";

const AdminEnterPasswordModal = (props) =>  {
      const [form] = Form.useForm()
      const {admin} = useSelector(state => state.auth)
      const [loading, setLoading] = useState(false)
      const dispatch = useDispatch()
      const {openEnterPasswordAdmin} = useSelector(state => state.modal)
      const navigate = useNavigate()

      const onVerifyAdmin = async (e) => {
            try {
                  setLoading(true)
                  const res = await dispatch(verifyAdmin({
                        phone : admin.phone,
                        password: e.password
                  }))
                  AlertCommon(ALERT_SUCCESS, res.message)
                  form.resetFields()
                  setLoading(false)
                  dispatch(toggleModalEnterPasswordAdmin(false))
                  dispatch(updateAdminNeedEnterPassword(false))
                  return navigate('/dashboard')
            } catch (e) {
                  setLoading(false)
                  return AlertCommon(ALERT_ERROR, e.message)
            }
      }

      const handleCloseModal = () => {
            form.resetFields()
            return dispatch(toggleModalEnterPasswordAdmin(false))
      }

      return (
            <Modal
                  footer={null}
                  visible={openEnterPasswordAdmin}
                  maskClosable={false}
                  closable={false}
                  centered
                  className='modal-change-password-customer modal-general'

            >
                  <div className="head-modal">
                        <p className="title">Verify Admin</p>
                        <Button onClick={handleCloseModal}>Close</Button>
                  </div>
                  <div className="body-modal">
                        <div className="form-wp">
                              <Form
                                    form={form}
                                    layout='vertical'
                                    name="form-enter-password-admin-hooks"
                                    onFinish={onVerifyAdmin}
                              >
                                    <Form.Item name="password" label="You need to provide a password to be able to access the Owner role">
                                          <Input.Password  iconRender={visible => (visible ? 'Hide' : 'Show')}
                                                           prefix={<LockOutlined className="site-form-item-icon"/>}/>
                                    </Form.Item>
                                    <Form.Item>
                                          <Button loading={loading} type="primary" htmlType="submit">
                                                Submit
                                          </Button>
                                    </Form.Item>
                              </Form>
                        </div>
                  </div>
            </Modal>
      );
}

export default AdminEnterPasswordModal;