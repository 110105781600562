import React, {useState} from 'react';
import {Button, Image, Modal, Popconfirm, Rate} from "antd";
import {useDispatch, useSelector} from "react-redux";
import AlertCommon from "../../../components/alert";
import {
      ALERT_ERROR,
      ALERT_SUCCESS, APM_CANCEL,
      TYPE_APPOINTMENT_CUSTOMER,
      APM_FINISH, TYPE_VOUCHER, TYPE_COUPON
} from "../../../constants/common";
import {
      removeAppointment,
      setAppointmentSelected,
      setOpenAppointmentDetail
} from "../../../actions/appointment";
import {setAppointmentUpdate, toggleFormSaveAppointment} from "../../../actions/dashboard";
import {convertTZLocal} from "../../../utils/helper";
import '../../../stylesheets/appointment-detail.scss'
import {useTranslation} from "react-i18next";
// import {toggleModalRejectAppointment} from "../../../actions/modal";

const ModalAppointmentDetail = (props) => {
      const {appointmentSelected, openAppointmentDetail} = useSelector(state => state.dashboard)
      const [loading, setLoading] = useState(false)
      const dispatch = useDispatch()
      const {t} = useTranslation()
      // const [btn, setBtn] = useState(null)


      const showFormSaveAppointment = async () => {
            await dispatch(setAppointmentUpdate(appointmentSelected))
            dispatch(toggleFormSaveAppointment(true))
            dispatch(setOpenAppointmentDetail(false))
      }

      const handleCloseModal = async () => {
            dispatch(setOpenAppointmentDetail(false))
            return dispatch(setAppointmentSelected(null))
      }

      const handleRemoveAppointment = async () => {
            if (!appointmentSelected) return
            try {
                  setLoading(true)
                  let response = await dispatch(removeAppointment(appointmentSelected.id))
                  setLoading(false)
                  AlertCommon(ALERT_SUCCESS, response.message)
                  dispatch(setOpenAppointmentDetail(false))
            } catch (e) {
                  setLoading(false)
                  return AlertCommon(ALERT_ERROR, e.message)
            }
      }

      // const handleAcceptRejectAppointment = async (type) => {
      //       if(!appointmentSelected) return
      //       try {
      //             setLoading(true)
      //             setBtn(type)
      //
      //             const is_accepted = type === 'accepted' ? 1 : 0
      //             await dispatch(setAppointmentSelected(appointmentSelected))
      //             if(is_accepted === 0){
      //                   dispatch(toggleModalRejectAppointment(true))
      //             }
      //             // if(is_accepted === 1){
      //             //       setTypeHandle('accept-appointment')
      //             //       setVisibleFillDataAppointment(true)
      //             // }
      //             setLoading(false)
      //       } catch (e) {
      //             setLoading(false)
      //             setBtn('')
      //       }
      // }

      // const handleCancelAppointment = async () => {
      //
      // }
      //
      // const handleCustomerCheckIn = async () => {
      //
      // }

      return (
            <Modal
                  footer={null}
                  visible={openAppointmentDetail}
                  maskClosable={false}
                  closable={false}
                  centered
                  className='modal-general modal-appointment-detail'
            >
                  <div className="head-modal">
                        <p className="title">{t('modal.title_appointment_detail')}</p>
                        <Button onClick={handleCloseModal}>{t('button.close')}</Button>
                  </div>

                  <div className="body-modal">
                        {appointmentSelected &&
                              <div className="appointment-detail">
                              <div className="detail">

                                    <div className="widget">
                                          <p>
                                                <span>ID: <span>#{appointmentSelected.id}</span></span>
                                                <span className={`status ${appointmentSelected.status_class}`}>{appointmentSelected.status_name} <i className="fa-solid fa-circle"></i> </span>
                                          </p>
                                          <p>
                                                <span>{t('appointment.customer_name')}</span>
                                                <span>{appointmentSelected.customer_name} <span>( {appointmentSelected.customer.phone_format} )</span></span>
                                          </p>
                                          <p>
                                                <span>{t('appointment.request')}</span>
                                                <span>{appointmentSelected.staff_name} <span>{(appointmentSelected.staff && appointmentSelected.staff?.phone )? `( ${appointmentSelected.staff.phone_format} )` : ''}</span></span>
                                          </p>
                                          <p>
                                                <span>{t('appointment.appointment_time')}</span>
                                                <span>{appointmentSelected.date_appointment ? convertTZLocal(appointmentSelected.date_appointment).format : ''}</span>
                                          </p>
                                    </div>

                                    <div className='widget'>
                                          <p>
                                                <span>{t('appointment.services')}</span>
                                                <span>{appointmentSelected.list_service_names_with_price}</span>
                                          </p>
                                    </div>

                                    <div className='widget'>
                                         <p>
                                               <span>{t('appointment.service_note')}</span>
                                               <span className={`text ${appointmentSelected.service_custom ? '' : 'no-info'}`}>{appointmentSelected.service_custom_name ? appointmentSelected.service_custom_name: t('alert.no_info')}</span>
                                         </p>
                                    </div>

                                    <div className='widget'>
                                          <p>
                                                <span>{t('appointment.voucher')}</span>
                                                <span className={`text ${appointmentSelected.voucher_code ? '' : 'no-info'}`} >{appointmentSelected.voucher_code ? appointmentSelected.voucher_code : t('alert.no_info')}</span>
                                          </p>
                                          <p>
                                                <span>{t('appointment.discount')}</span>
                                                {appointmentSelected.voucher_code ?
                                                      <span>{appointmentSelected.type_discount === TYPE_VOUCHER ? `$${appointmentSelected.total_discount_format}` : `${appointmentSelected.discount}%`}</span>
                                                :
                                                      <span>$0.00</span>
                                                }

                                          </p>
                                          <p>
                                                <span>{t('appointment.price')}</span>
                                                <span>${appointmentSelected.total_price_format}</span>
                                          </p>
                                          <p>
                                                <span>{t('appointment.total_discount')}</span>
                                                <span>${appointmentSelected.total_discount_format}</span>
                                          </p>
                                         <p>
                                               <span>{t('appointment.total')}</span>
                                               <span className='price'>${appointmentSelected.price ? appointmentSelected.price_format : appointmentSelected.total_price_service_format}</span>
                                         </p>
                                    </div>
                                    <div className='widget'>
                                          <p>
                                                <span>{t('appointment.appointment_note')}</span>
                                                <span className={`text ${appointmentSelected.notes ? '' : 'no-info'}`}>{appointmentSelected.notes ? appointmentSelected.notes : t('alert.no_info')}</span>
                                          </p>
                                    </div>

                                    {(appointmentSelected.status === APM_FINISH) &&
                                          <div className='widget'>
                                                {appointmentSelected.feedback &&
                                                      <p>
                                                            <span>{t('appointment.rating')}</span>
                                                            <span>
                                                                  <Rate allowHalf disabled  defaultValue={appointmentSelected.feedback.rating} />
                                                            </span>
                                                      </p>
                                                }
                                                <p>
                                                      <span>{t('appointment.feedback')}</span>
                                                      <span className={`text ${appointmentSelected.feedback ? '' : 'no-review'}`}>{appointmentSelected.feedback ? appointmentSelected.feedback.content : t('alert.empty_review')}</span>
                                                </p>

                                                {(appointmentSelected.feedback?.images.length > 0) &&
                                                      <ul className='images'>
                                                            {appointmentSelected.feedback.images.map(el =>
                                                                  <li key={el.id}>
                                                                        <Image src={el.image} alt=""/>
                                                                  </li>
                                                            )}
                                                      </ul>
                                                }
                                          </div>
                                    }

                                    {(appointmentSelected.status === APM_CANCEL) &&
                                          <div className='widget'>
                                                <p>
                                                      <span>{t('appointment.cancel_by')}</span>
                                                      <span>
                                                            {appointmentSelected.canceled_by_name}
                                                      </span>
                                                </p>
                                                <p>
                                                      <span>{t('appointment.reason_cancel')}</span>
                                                      <span>{appointmentSelected.reason_cancel}</span>
                                                </p>
                                          </div>
                                    }

                                    {(appointmentSelected?.type === TYPE_APPOINTMENT_CUSTOMER && appointmentSelected?.status !== APM_FINISH && appointmentSelected?.status !== APM_CANCEL) &&
                                          <div className="action">
                                                <Button onClick={showFormSaveAppointment}>
                                                      <i className="fa-solid fa-pen-to-square"></i>{t('button.edit_appointment')}
                                                </Button>
                                          </div>
                                    }
                              </div>

                              <div className="btn-actions">
                                    {(appointmentSelected?.status === APM_FINISH || appointmentSelected?.status === APM_CANCEL) &&
                                          <Popconfirm
                                                className='pop-confirm-custom'
                                                placement="top"
                                                title={t('alert.title_confirm_delete')}
                                                onConfirm={handleRemoveAppointment}
                                                okText={t('button.ok')}
                                                cancelText={t('button.cancel')}
                                                okButtonProps={{
                                                      loading: loading,
                                                }}
                                          >
                                                <Button className='delete-btn'><i className="fa-solid fa-trash"></i>{t('button.delete_appointment')}</Button>
                                          </Popconfirm>
                                    }

                                    {/*{appointmentSelected?.type === TYPE_APPOINTMENT_CUSTOMER &&*/}
                                    {/*      <React.Fragment>*/}
                                    {/*            {appointmentSelected.status === APM_PENDING &&*/}
                                    {/*            <React.Fragment>*/}
                                    {/*                  <Button loading={loading && btn === 'accepted'} className='accepted' onClick={() => handleAcceptRejectAppointment('accepted')}>Accepted</Button>*/}
                                    {/*                  <Button loading={loading && btn === 'rejected'} className='rejected' onClick={() => handleAcceptRejectAppointment('rejected')}>Rejected</Button>*/}
                                    {/*            </React.Fragment>*/}
                                    {/*            }*/}
                                    {/*            {appointmentSelected.status === APM_ACCEPTED &&*/}
                                    {/*            <React.Fragment>*/}
                                    {/*                  <Button loading={loading && btn === 'check-in'} className='cs-check-in' onClick={handleCustomerCheckIn}>Customer Check-in</Button>*/}
                                    {/*                  <Button loading={loading && btn === 'cancel'} onClick={handleCancelAppointment} className='cancel'>Cancel</Button>*/}
                                    {/*            </React.Fragment>*/}
                                    {/*            }*/}
                                    {/*      </React.Fragment>*/}
                                    {/*}*/}
                              </div>
                        </div>
                        }
                  </div>

            </Modal>
      );
}

export default ModalAppointmentDetail;