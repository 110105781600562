import staffApi from "../api/staffApi";
import {
      CREATE_STAFF,
      DELETE_STAFF,
      LIST_STAFF, OPEN_FORM_EDIT_STAFF, SET_STAFF_EDIT,
      SET_STAFF_SELECTED,
      SHOW_STAFF_PANEL, UPDATE_STAFF
} from '../constants/action'

export const getListStaff = () => (dispatch) => {
      return new Promise((resolve, reject) => {
            staffApi.listStaff().then(
                  (res) => {
                        dispatch({
                              type: LIST_STAFF,
                              payload: res.data,
                        });
                        resolve(res)
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
}

export const createNewStaff = (data) => (dispatch) => {
      return new Promise((resolve, reject) => {
            staffApi.createStaff(data).then(
                  (res) => {
                        dispatch({
                              type: CREATE_STAFF,
                              payload: res.data,
                        });
                        resolve(res)
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
}

export const changeStatusStaff = (data) => (dispatch) => {
      return new Promise((resolve, reject) => {
            staffApi.changeStatus(data).then(
                  (res) => {
                        dispatch({
                              type: UPDATE_STAFF,
                              payload: res.data,
                        });
                        resolve(res)
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
}

export const deleteStaff = (id) => (dispatch) => {
      return new Promise((resolve, reject) => {
            staffApi.deleteStaff(id).then(
                  (res) => {
                        dispatch({
                              type: DELETE_STAFF,
                              payload: id,
                        });
                        resolve(res)
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
}

export const setStaffSelected = (staff) => (dispatch) => {
      return dispatch({
            type: SET_STAFF_SELECTED,
            payload: staff,
      });
}

export const setOpenStaffPanel = (status) => (dispatch) => {
      return dispatch({
            type: SHOW_STAFF_PANEL,
            payload: status,
      });
}

export const setStaffEdit = (staff) => (dispatch) => {
      return dispatch({
            type: SET_STAFF_EDIT,
            payload: staff,
      });
}

export const setOpenFormEditStaff = (status) => (dispatch) => {
      return dispatch({
            type: OPEN_FORM_EDIT_STAFF,
            payload: status,
      });
}

export const updateStaff = (data) => (dispatch) => {
      return new Promise((resolve, reject) => {
            staffApi.updateStaff(data).then(
                  (res) => {
                        dispatch({
                              type: UPDATE_STAFF,
                              payload: res.data,
                        });
                        resolve(res)
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
}

export const changeActiveStaff = (id) => (dispatch) => {
      return new Promise((resolve, reject) => {
            staffApi.changeActive(id).then(
                  (res) => {
                        dispatch({
                              type: UPDATE_STAFF,
                              payload: res.data,
                        });
                        resolve(res)
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
}

export const historyCheckInOut = (params) => {
      return new Promise((resolve, reject) => {
            staffApi.historyCheckInOut(params).then(
                  (res) => {
                        return resolve(res)
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
}

export const getListStaffInBooking = (salonId) => {
      return new Promise((resolve, reject) => {
            staffApi.getListStaffInBooking(salonId).then(
                  (res) => {
                        return resolve(res.data)
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
}