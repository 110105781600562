import {LIST_CUSTOMER, UPDATE_INFO_CUSTOMER} from '../constants/action';

const initialState = {customers: []}

const customerReducer = (state = initialState, action) =>  {
      const {type, payload} = action
      switch (type) {
            case LIST_CUSTOMER:
                  return {
                        ...state,
                        customers: payload,
                  };
            case UPDATE_INFO_CUSTOMER:
                  let newList = state.customers
                  let index = newList.findIndex(item => item.id === payload.customer_id)

                  if(index !== -1){
                        newList[index].type = payload.type
                        newList[index].type_name = payload.type_name
                        newList[index].note = payload.note
                  }

                  return {
                        ...state,
                        customers: newList
                  }
            default:
                  return state;
      }
}

export default customerReducer;
