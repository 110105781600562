import salonApi from "../api/salonApi";
import authApi from "../api/authApi";
import settingApi from "../api/settingApi";
import {
      LOGIN_SUCCESS,
      LOGOUT,
      CHANGE_EMAIL_RECEIVE_FEEDBACK,
      LIST_STAFF,
      LIST_SERVICE,
      LIST_STAFF_IN_BOOKING, LIST_SERVICE_IN_BOOKING,
      CUSTOMER_LOGOUT, CUSTOMER_CHECK_IN, UPDATE_ADMIN_NEED_ENTER_PASSWORD
} from '../constants/action'

export const login = (formData) => (dispatch) => {
      return new Promise((resolve, reject) => {
            salonApi.login(formData).then(
                  (res) => {
                        dispatch({
                              type: LOGIN_SUCCESS,
                              payload: res.data,
                        });
                        let salonData = res.data.admin.salon
                        dispatch({
                              type: LIST_STAFF,
                              payload: salonData.staffs,
                        });
                        dispatch({
                              type: LIST_SERVICE,
                              payload: salonData.services,
                        });
                        dispatch({
                              type: LIST_STAFF_IN_BOOKING,
                              payload: salonData.staffInBooking,
                        });
                        dispatch({
                              type: LIST_SERVICE_IN_BOOKING,
                              payload: salonData.serviceInBooking,
                        });
                        localStorage.setItem('access_token', res.data.access_token);
                        resolve(res)
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
}

export const signUp = (formData)  => {
      return new Promise((resolve, reject) => {
            salonApi.signUp(formData).then(
                  (res) => {
                        resolve(res)
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
}

export const  changePassword = (formData) => {
      return new Promise((resolve, reject) => {
            salonApi.changePassword(formData).then(
                  (res) => {
                        return resolve(res)
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
};

export const logout = () => (dispatch) => {
      localStorage.removeItem("access_token");
      localStorage.removeItem("persist:root");
      dispatch({
            type: LOGOUT,
      });
};

export const updateAdminNeedEnterPassword = (status) => (dispatch) => {
      dispatch({
            type: UPDATE_ADMIN_NEED_ENTER_PASSWORD,
            payload: status
      });
};

export const verifyAdmin = (data) => (dispatch) => {
      return new Promise((resolve, reject) => {
            salonApi.verifyAdmin(data).then(
                  (res) => {
                        dispatch({
                              type: UPDATE_ADMIN_NEED_ENTER_PASSWORD,
                              payload: false,
                        });
                        resolve(res)
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
}

export const changeEmailReceiveFeedback = (email) => (dispatch) => {
      return new Promise((resolve, reject) => {
            settingApi.changeEmailReceiveFeedback(email).then(
                  (res) => {
                        dispatch({
                              type: CHANGE_EMAIL_RECEIVE_FEEDBACK,
                              payload: email,
                        });
                        resolve(res)
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
}


export const resetPassword = (data) => {
      return new Promise((resolve, reject) => {
            authApi.resetPassword(data).then(
                  (res) => {
                        return resolve(res)
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
}

export const verificationCode = (code) => {
      return new Promise((resolve, reject) => {
            authApi.verifyCode(code).then(
                  (res) => {
                        return resolve(res)
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
}

export const customerCheckIn = (formData) => (dispatch) => {
      return new Promise((resolve, reject) => {
            authApi.customerCheckIn(formData).then(
                  (res) => {
                        dispatch({
                              type: CUSTOMER_CHECK_IN,
                              payload: res.data,
                        });
                        localStorage.setItem('customer_access_token', res.data.access_token);
                        resolve(res)
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
}

export const customerLogout = () => (dispatch) => {
      localStorage.removeItem("customer_access_token");
      dispatch({
            type: CUSTOMER_LOGOUT,
      });
};

export const customerChangePassword = (formData)=> {
      return new Promise((resolve, reject) => {
            authApi.customerChangePassword(formData).then(
                  (res) => {
                        return resolve(res)
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
}