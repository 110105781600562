import axiosInstance from "./axiosInstance";

const base_path = '/customer'
const customerApi = {
      listCustomers(){
            const url = `${base_path}/list-customer`
            return axiosInstance.get(url)
      },
      deleteCustomer(id){
            const url = `${base_path}/delete-customer`
            return axiosInstance.post(url, {id})
      },
      listAppointmentForCustomer(data) {
            const url = `${base_path}/list-appointment-customer`
            return axiosInstance.post(url, data)
      }
}

export default customerApi