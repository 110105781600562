import React, {useState} from 'react';
import {Button, Form, Input, Modal} from "antd";
import {useDispatch, useSelector} from "react-redux";
import AlertCommon from "../../../components/alert";
import {ALERT_ERROR, ALERT_SUCCESS} from "../../../constants/common";
import {changeEmailReceiveFeedback} from "../../../actions/auth";
import {toggleShowModalSetting} from "../../../actions/modal";
import {useTranslation} from "react-i18next";

const ModalConfig = ({email}) => {
      const {openSetting} = useSelector(state => state.modal)
      const [loading, setLoading] = useState(false)
      const dispatch = useDispatch()
      const {t} = useTranslation()

      const onChangeConfig = async ({email}) => {
            try {
                  setLoading(true)
                  let res = await dispatch(changeEmailReceiveFeedback(email))
                  AlertCommon(ALERT_SUCCESS, res.message)
                  setLoading(false)
                  return dispatch(toggleShowModalSetting(false))
            } catch (e) {
                  setLoading(false)
                  return AlertCommon(ALERT_ERROR, e.message)
            }
      }
      return (
            <Modal
                  footer={null}
                  visible={openSetting}
                  maskClosable={false}
                  closable={false}
                  centered
                  className='modal-general modal-config'
            >
                  <div className="head-modal">
                        <p className="title">{t('modal.title_email_receive_feedback')}</p>
                        <Button onClick={() => dispatch(toggleShowModalSetting(false))}>{t('button.close')}</Button>
                  </div>
                  <div className="body-modal">
                        <div className="form-wp">
                              <Form
                                    layout='vertical'
                                    name="control-hooks"
                                    initialValues={{email: email}}
                                    onFinish={onChangeConfig}
                              >
                                    <Form.Item name="email" label={t('form.label_email')}>
                                          <Input/>
                                    </Form.Item>

                                    <Form.Item>
                                          <Button loading={loading} type="primary" htmlType="submit">
                                                {t('button.save')}
                                          </Button>
                                    </Form.Item>
                              </Form>
                        </div>
                  </div>
            </Modal>
      );
}

export default ModalConfig;