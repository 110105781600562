import React, {useEffect, useState} from 'react';
import {Badge, Button, Dropdown,  Layout, Modal} from "antd";
import Header from "./header";
import SideBar from "./slider";
import {connect, useDispatch, useSelector} from "react-redux";
import MainMenu from "../../features/dashboard/components/menu";
import '../../stylesheets/layout.scss'
import ModalFormSaveAppointment from "../../features/dashboard/components/appointment";
import ModalConfig from "../../features/dashboard/components/config";
import ModalListStaff from "../../features/dashboard/components/staff";
import ModalListCustomer from "../../features/dashboard/components/customer";
import ModalChangePassword from "../../features/dashboard/components/password";
import ModalReport from "../../features/dashboard/components/report";
import ModalManageService from "../../features/dashboard/components/service";
import ModalWorkingRight from "../../features/dashboard/components/working";
import ModalStaffPanel from "../../features/dashboard/components/staff-panel";
import {useNavigate} from 'react-router-dom'
import {getListAppointment} from "../../actions/dashboard";
import ModalCancelAppointment from "../../features/dashboard/components/cancel-appointment";
import ModalRejectAppointment from "../../features/dashboard/components/reject-appointment";
import {socket} from '../../config/socket'
import ModalNotification from "../modal/noti";
import ModalListAppointmentOfCustomer from "../../features/dashboard/components/appointment-of-customer";
import ModalHistoryCheckInOut from "../../features/dashboard/components/history-check-in-out";
import Notifications from "../notifications";
import {changeLanguageDefault} from "../../actions/config";
import { useTranslation } from 'react-i18next';
import LoadingData from "../load";
import {addNewNotification} from "../../actions/notification";
import {getListStaff} from "../../actions/staff";

function Master({children, is_logged, admin, salon, appointmentEdit}) {
      const { t } = useTranslation();
      const [showMenu, setShowMenu] = useState(false);
      const navigate = useNavigate()
      const dispatch = useDispatch()
      const [visibleNotification, setVisibleNotification] = useState(false)
      const {language} = useSelector(state => state.config)
      const {i18n } = useTranslation();
      const [loading, setLoading] = useState(false)

      useEffect(() => {
            if (!is_logged) {
                  return navigate('/')
            }
      }, [])

      // notification
      useEffect(() => {
            const listener = (data) => {
                  if(!data || data.salon_id !== salon?.id) return
                  data.title = language === 'vi' ? data.titleVi : data.titleEn
                  data.body = language === 'vi' ? data.bodyVi : data.bodyEn

                  delete data.titleEn
                  delete data.titleVi
                  delete data.bodyEn
                  delete data.bodyVi
                  dispatch(addNewNotification(data))
            };
            socket.on('notification', listener);
            return () => socket.off('notification', listener);
      }, ['notification']);

      useEffect(() => {
            (async () => {
                  try {
                        return await dispatch(getListAppointment())
                  } catch (e) {
                        console.log(e);
                  }
            })()
      }, [])

      const onShowMenu = () => {
            setShowMenu(prevState => !prevState)
      }

      const changeLanguage = async (lang) => {
            setLoading(true)
            await i18n.changeLanguage(lang)
            localStorage.setItem('lang', lang)
            dispatch(changeLanguageDefault(lang))
            await dispatch(getListStaff())
            await new Promise(res => setTimeout(res, 1000));
            setLoading(false)
      }

      return (
            <Layout className='main-layout'>
                  {salon &&
                        <div className="header-top">
                              <p>
                                    {t('header.welcome_owner')}  <span>{salon.name}</span>
                                    <Badge count={admin.number_unread_notification} className='block'>
                                          <Button className='notification'>
                                                <Dropdown placement="bottomRight"
                                                          overlayClassName='dropdown-notification'
                                                          visible={visibleNotification}
                                                          onVisibleChange={(e) => setVisibleNotification(e)}
                                                          trigger={['click']}
                                                          overlay={<Notifications closeDropdown={() => setVisibleNotification(false)} />}
                                                >
                                                      <i className="fa-solid fa-bell"></i>
                                                </Dropdown>
                                          </Button>
                                    </Badge>
                              </p>

                              <div className="change-language">
                                    <Button onClick={() => changeLanguage(language === 'vi' ? 'en' : 'vi')}>{language === 'vi' ? 'English' : 'Tiếng Việt'}</Button>
                              </div>
                        </div>
                  }
                  <Header showMenu={showMenu}
                          onShowMenu={onShowMenu}
                          salon={salon}
                  />
                  <Layout className='sub-layout'>
                        <Layout.Content style={{margin: '15px'}} className='content-page'>
                              <div>
                                    {children}
                              </div>
                        </Layout.Content>
                        <SideBar
                              onShowMenu={onShowMenu}
                        />
                  </Layout>

                  <Modal
                        footer={null}
                        visible={loading}
                        maskClosable={false}
                        closable={false}
                        centered
                        className='modal-loading'
                  >
                        <LoadingData/>
                  </Modal>

                  {/*component*/}
                  <MainMenu
                        visible={showMenu}
                        onCloseMenu={() => setShowMenu(false)}
                  />
                  <ModalFormSaveAppointment appointmentEdit={appointmentEdit} />
                  <ModalConfig email={salon?.email}/>
                  <ModalListStaff />
                  <ModalListCustomer/>
                  <ModalChangePassword/>
                  <ModalReport/>
                  <ModalManageService/>
                  <ModalWorkingRight/>
                  <ModalStaffPanel/>
                  <ModalCancelAppointment/>
                  <ModalRejectAppointment/>
                  <ModalNotification/>
                  <ModalListAppointmentOfCustomer/>
                  <ModalHistoryCheckInOut/>
            </Layout>
      );
}

function mapStateToProps(state) {
      const {admin, salon, is_logged} = state.auth;
      const {appointmentEdit} = state.dashboard;
      return {
            admin, salon, is_logged, appointmentEdit
      };
}

export default connect(mapStateToProps)(Master);