import React, {useEffect, useState} from 'react';
import {Button, Col,  Modal, Row} from "antd";
import moment from 'moment'
import {
      ALERT_ERROR,
      ALERT_SUCCESS,
      TYPE_APPOINTMENT_CUSTOMER,
      TYPE_APPOINTMENT_WALK_IN,
      STAFF_AVAILABLE,
      STAFF_BREAK,
      STAFF_WORKING
} from "../../../constants/common";
import {
      getListAppointmentForStaff,
      setAppointmentSelected,
      setOpenAppointmentDetail
} from "../../../actions/appointment";
import {useDispatch, useSelector} from "react-redux";
import {changeStatusStaff, setOpenStaffPanel, setStaffSelected} from "../../../actions/staff";
import AlertCommon from "../../../components/alert";
import {useTranslation} from "react-i18next";

const ModalStaffPanel = (props) =>  {
      const {t} = useTranslation()
      const [loading, setLoading] = useState(false)
      const dateNow = moment().format('YYYY-MM-DD')
      const [appointments, setAppointments] = useState([])
      const {showStaffPanel, staffSelected} = useSelector(state => state.staff)
      const dispatch = useDispatch()

      useEffect(() => {
            if(!staffSelected) return
            const initAppointmentsForStaff = async () => {
                  try {
                        let response = await getListAppointmentForStaff(staffSelected.id, dateNow)
                         setAppointments(response.data)
                  } catch (e) {
                      console.log(e);
                  }
            }
            initAppointmentsForStaff()
      }, [staffSelected])

      const handleAppointment = async (appointment) => {
            await dispatch(setAppointmentSelected(appointment))
            dispatch(setOpenAppointmentDetail(true))
            return await closeStaffPanel()
      }

      const renderListWalkInAppointment = () => {
            return (
                  appointments.filter(item => item.type === TYPE_APPOINTMENT_WALK_IN).map(item => (
                        <li key={item.id} className="item-appointment">
                              <div className="customer">
                                    <p className="name" onClick={() => handleAppointment(item)}>{item.customer_name}</p>
                                    <p className="service">{item.list_service_names}</p>
                              </div>
                              <div className="time">
                                    <p>{item.date_appointment_format}</p>
                              </div>
                              <div className="staff">
                                    <p><span className="request-title">Request: <span className={`name ${!item.staff_id && 'not'}`}>{item.staff_name}</span></span></p>
                                    <p className={`status ${item.status_name}`}>{item.status_name} <i className="fa-solid fa-circle"></i></p>
                              </div>
                        </li>
                  ))
            )
      }

      const renderListAppointmentCustomer = () => {
            return (
                  appointments.filter(item => item.type === TYPE_APPOINTMENT_CUSTOMER).map(item => (
                        <li key={item.id} className="item-appointment">
                              <div className="customer">
                                    <p className="name" onClick={() => handleAppointment(item)}>{item.customer_name}</p>
                                    <p className="service">{item.list_service_names}</p>
                              </div>
                              <div className="time">
                                    <p>{item.date_appointment_format}</p>
                              </div>
                              <div className="staff">
                                    <p><span className="request-title">Request: <span className={`name ${!item.staff_id && 'not'}`}>{item.staff_name}</span></span></p>
                                    <p className={`status ${item.status_name}`}>{item.status_name} <i className="fa-solid fa-circle"></i></p>
                              </div>
                        </li>
                  ))
            )
      }

      const closeStaffPanel = async () => {
            await dispatch(setStaffSelected(null))
            return dispatch(setOpenStaffPanel(false))
      }

      const handleChangeStatusStaff = async (status) => {
            try {
                  let data = {
                        id : staffSelected.id,
                        status
                  }
                  setLoading(true)
                  let res = await dispatch(changeStatusStaff(data))
                  AlertCommon(ALERT_SUCCESS, res.message)
                  setLoading(false)
            } catch (e) {
                  setLoading(false)
                  return AlertCommon(ALERT_ERROR, e.message)
            }
      }

      return (
            <Modal
                  footer={null}
                  visible={showStaffPanel}
                  maskClosable={false}
                  closable={false}
                  centered
                  className='modal-general modal-staff-panel modal-table'
            >
                  <div className="head-modal">
                        <p className="title">Staff Panel -- <span className="date">{dateNow}</span></p>
                        <Button onClick={closeStaffPanel}>Close</Button>
                  </div>
                  <div className="body-modal">
                        <div className="wp-inner">
                              <div className="list-appointment staff-panel">
                                    <Row gutter={[16, 16]}>
                                          <Col sm={24} md={12}>
                                                <div className="widget">
                                                      <div className="widget-head">
                                                            <h3 className="title">
                                                                  Walk-in Customers
                                                            </h3>
                                                      </div>
                                                      <div className="widget-body">
                                                            <ul className="appointments">
                                                                  {renderListWalkInAppointment()}
                                                            </ul>
                                                      </div>
                                                </div>
                                          </Col>
                                          <Col sm={24} md={12}>
                                                <div className="widget">
                                                      <div className="widget-head">
                                                            <h3 className="title">
                                                                  Appointment Customers
                                                            </h3>
                                                      </div>
                                                      <div className="widget-body">
                                                            <ul className="appointments">
                                                                  {renderListAppointmentCustomer()}
                                                            </ul>
                                                      </div>
                                                </div>
                                          </Col>
                                    </Row>
                              </div>
                        </div>
                  </div>
                  <div className="footer-modal">
                        {staffSelected?.status === STAFF_BREAK &&
                              <Button loading={loading} className='btn-check-in' onClick={() => handleChangeStatusStaff(STAFF_AVAILABLE)}>Check In</Button>
                        }
                        {(staffSelected?.status === STAFF_AVAILABLE || staffSelected?.status === STAFF_WORKING) &&
                              <Button  loading={loading} onClick={() => handleChangeStatusStaff(STAFF_BREAK)} className='btn-check-out'>Check Out</Button>
                        }
                  </div>
            </Modal>
      );
}

export default ModalStaffPanel;