import React, {useEffect, useState} from 'react';
import {Button, DatePicker, Form, Input, Modal, Radio, Space, Select, Checkbox} from "antd";
import AlertCommon from "../../../components/alert";
import {ALERT_ERROR, ALERT_SUCCESS} from "../../../constants/common";
import {useDispatch, useSelector} from "react-redux";
import moment from 'moment'
import {addNewAppointment, updateAppointment} from "../../../actions/appointment";
import {setAppointmentUpdate, toggleFormSaveAppointment} from "../../../actions/dashboard";
import {formatPhoneNumber, normalizeInput, replaceFormatPhoneLogin} from "../../../utils/helper";
import './../../../stylesheets/create-appointment.scss'
import {useTranslation} from "react-i18next";

const ModalFormSaveAppointment = ({appointmentEdit}) => {
      const ANYONE_AVAILABLE_STAFF = 0
      const CHOOSE_STAFF = 1
      const [loading, setLoading] = useState(false)
      const [form] = Form.useForm()
      const {staffInBooking} = useSelector(state => state.staff)
      const {serviceInBooking} = useSelector(state => state.service)
      const {language} = useSelector(state => state.config)
      const dispatch = useDispatch()
      const {openFormSaveAppointment} = useSelector(state => state.dashboard)
      const [disableSelectStaff, setDisableSelectStaff] = useState(!(appointmentEdit || appointmentEdit?.staff_id))
      const [phone, setPhone] = useState('')
      const {t} = useTranslation()


      useEffect(() => {
            if(appointmentEdit){
                  const phoneFormat = formatPhoneNumber(appointmentEdit.customer.phone)
                  setPhone(phoneFormat)
            }
            form.setFieldsValue({
                  phone: (appointmentEdit && appointmentEdit.customer) ? appointmentEdit.customer.phone : null,
                  name: (appointmentEdit && appointmentEdit.customer) ? appointmentEdit.customer.name : null,
                  staff: appointmentEdit?.staff_id ? 1 : 0,
                  staff_id: (appointmentEdit && appointmentEdit.staff_id) ? appointmentEdit.staff_id : null,
                  date_appointment: (appointmentEdit && appointmentEdit.date_appointment) ? moment(appointmentEdit.date_appointment, 'YYYY-MM-DD HH:mm') : null,
                  services: (appointmentEdit && appointmentEdit.services) ? appointmentEdit.services.map(item => item.id) : [],
                  service_custom: (appointmentEdit && appointmentEdit.service_custom) ? appointmentEdit.service_custom.name : null,
                  work_time: (appointmentEdit && appointmentEdit.work_time) ? appointmentEdit.work_time : null,
            })
      }, [appointmentEdit])

      const handleChangePhone = (e) => {
            return setPhone(prevState => normalizeInput(e.target.value, prevState))
      }

      const onSaveAppointment = async (e) => {
            try {
                  setLoading(true)
                  e.phone = replaceFormatPhoneLogin(phone)
                  e.date_appointment = e.date_appointment ? moment(e.date_appointment).utc().format('YYYY-MM-DD HH:mm') : null
                  let formData = e

                  formData.services = JSON.stringify(formData.services)

                  if (!appointmentEdit) {
                        let response = await dispatch(addNewAppointment(formData))
                        setLoading(false)
                        form.resetFields()
                        setPhone('')
                        AlertCommon(ALERT_SUCCESS, response.message)
                        dispatch(toggleFormSaveAppointment(false))
                  } else {
                        formData.id = appointmentEdit.id
                        let response = await dispatch(updateAppointment(formData))
                        setLoading(false)
                        AlertCommon(ALERT_SUCCESS, response.message)
                        dispatch(setAppointmentUpdate(null))
                        dispatch(toggleFormSaveAppointment(false))
                  }

            } catch (e) {
                  setLoading(false)
                  return AlertCommon(ALERT_ERROR, e.message, 7000)
            }
      }

      const renderListStaff = () => {
            return staffInBooking.map(item => (
                  <Select.Option key={item.id} value={item.id}>{item.first_name} {item.last_name}</Select.Option>
            ))
      }

      const renderListService = () => {
            return serviceInBooking.map(item => (
                  <Checkbox key={item.id} value={item.id}>{item.name} <span>(${item.price_format})</span></Checkbox>
            ))
      }

      const disabledDate = (current) => {
            // Can not select days before today and today
            return current && current < moment().startOf('day');
      };

      const onCloseModal = () => {
            form.resetFields()
            setPhone('')
            dispatch(setAppointmentUpdate(null))
            dispatch(toggleFormSaveAppointment(false))
      }

      const changeOptionStaff = (e) => {
            let value = e.target.value
            form.setFieldsValue({
                  staff_id : null
            })
            setDisableSelectStaff(value === ANYONE_AVAILABLE_STAFF)
      }

      return (
            <Modal
                  footer={null}
                  visible={openFormSaveAppointment}
                  maskClosable={false}
                  closable={false}
                  centered
                  className='modal-general modal-create-appointment'

            >
                  <div className="head-modal">
                        <p className="title">{appointmentEdit ? `${t('button.update_appointment')}` : `${t('button.create_appointment')}`}</p>
                        <Button onClick={onCloseModal}>{t('button.close')}</Button>
                  </div>
                  <div className="body-modal">
                        <div className="form-wp">
                              <Form
                                    form={form}
                                    layout='vertical'
                                    name="control-hooks"
                                    onFinish={onSaveAppointment}
                              >
                                    <div className="customer-info">
                                          <p className="title">{t('form.customer_info')}</p>
                                          <Form.Item >
                                                <Input disabled={appointmentEdit} value={phone} onChange={handleChangePhone} placeholder={t('form.placeholder_phone')} />
                                          </Form.Item>
                                          <Form.Item name="name">
                                                <Input placeholder={t('form.placeholder_name')} />
                                          </Form.Item>
                                    </div>
                                    <div className="request-info">
                                          <p className="title">{t('form.request_info')}</p>
                                          <Form.Item name="staff" className='choose-staff' label={t('form.label_staff')}>
                                                <Radio.Group onChange={changeOptionStaff}>
                                                      <Space direction="vertical">
                                                            <Radio value={ANYONE_AVAILABLE_STAFF}>{language === 'en' ? 'ANYONE AVAILABLE' : 'Nhân viên bất kì'}</Radio>
                                                            <Radio value={CHOOSE_STAFF}>{language === 'en' ? 'Choose staff' : 'Chọn nhân viên'}</Radio>
                                                      </Space>
                                                </Radio.Group>
                                          </Form.Item>
                                          <Form.Item name="staff_id">
                                                <Select placeholder={t('form.placeholder_staff')}
                                                        disabled={disableSelectStaff}>
                                                      {renderListStaff()}
                                                </Select>
                                          </Form.Item>

                                          <Form.Item name="date_appointment" label={t('form.label_appointment_time')}>
                                                <DatePicker
                                                      disabledDate={disabledDate}
                                                      showTime format="YYYY-MM-DD HH:mm"
                                                      minuteStep={5}
                                                      showNow={false}
                                                      dropdownClassName='date-rq-dropdown'
                                                      placeholder={t('form.placeholder_appointment_time')}
                                                />
                                          </Form.Item>
                                          <Form.Item name="services" label={t('form.label_services')}>
                                                <Checkbox.Group className='list-services'>
                                                      {renderListService()}
                                                </Checkbox.Group>
                                          </Form.Item>
                                          <Form.Item name="service_custom" label={t('form.label_note_service')}>
                                                <Input/>
                                          </Form.Item>
                                          <Form.Item name="work_time" label={t('form.label_work_time')}>
                                                <Input/>
                                          </Form.Item>
                                    </div>

                                    <Form.Item>
                                          <Button loading={loading} type="primary" htmlType="submit">
                                                {appointmentEdit ? t('button.update') : t('button.add')}
                                          </Button>
                                    </Form.Item>
                              </Form>
                        </div>
                  </div>
            </Modal>
      );
}

export default ModalFormSaveAppointment;