import axios from "axios";

export const getTimeZoneByLocation = (lat, lng) => {
      return new Promise((resolve, reject) => {
            const url = `https://maps.googleapis.com/maps/api/timezone/json?location=${lat}%2C${lng}&timestamp=0&key=${process.env.REACT_APP_GOOGLE_KEY_API}`
            axios.get(url).then(
                  (res) => {
                        return resolve(res.data)
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
}