import React from 'react';
import {Button, Drawer, Menu} from "antd";
import {useDispatch} from "react-redux";
import {getListCustomer} from "../../../actions/customer";
import {
      toggleModalFormProfileSalon,
      toggleShowModalChangePassword,
      toggleShowModalCustomer,
      toggleShowModalService, toggleShowModalSetting,
      toggleShowModalStaff
} from "../../../actions/modal";
import {useTranslation} from "react-i18next";

const MainMenu = ({visible,  onCloseMenu}) =>  {
      const placement = 'left'
      const dispatch = useDispatch()
      const {t} = useTranslation()

      const items = [
            { label: t('menu.manage_staff'), key: 'staff' },
            { label: t('menu.manage_service'), key: 'service' },
            { label: t('menu.manage_customer'), key: 'customer' },
            { label: t('menu.manage_salon'), key: 'salon' },
            { label: t('menu.email_receive_feedback'), key: 'config' },
            { label: t('menu.change_password'), key: 'change-password' },
      ];

      const handleMenu = async (e) => {
            let key = e.key
            onCloseMenu()
            switch (key) {
                  case 'staff':
                        dispatch(toggleShowModalStaff(true))
                        break;
                  case 'service':
                        dispatch(toggleShowModalService(true))
                        break;
                  case 'change-password':
                        dispatch(toggleShowModalChangePassword(true))
                        break;
                  case 'salon':
                        dispatch(toggleModalFormProfileSalon(true))
                        break;
                  case 'customer':
                        await dispatch(getListCustomer())
                        dispatch(toggleShowModalCustomer(true))
                        break;
                  case 'config':
                        dispatch(toggleShowModalSetting(true))
                        break;
                  default:
                        break;
            }
      }

      return (
            <Drawer
                  placement={placement}
                  closable={false}
                  visible={visible}
                  key={placement}
                  mask={false}
                  className='main-menu'
            >
                  <div className="head-drawer">
                        <h3 className="title">{t('menu.title')}</h3>
                        <Button onClick={() => onCloseMenu()}>x</Button>
                  </div>
                  <div className="body-drawer">
                        <Menu
                              items={items}
                              className='menu-sidebar'
                              onClick={handleMenu}
                        />
                  </div>

            </Drawer>
      );
}

export default MainMenu;