import {
      LOGIN_SUCCESS,
      LOGOUT,
      CHANGE_EMAIL_RECEIVE_FEEDBACK,
      UPDATE_SALON,
      LIST_NOTIFICATION,
      NEW_NOTIFICATION,
      UPDATE_NOTIFICATION,
      DELETE_NOTIFICATION,
      READ_ALL_NOTIFICATION,
      DELETE_ALL_NOTIFICATION,
      CUSTOMER_LOGOUT,
      CUSTOMER_CHECK_IN,
      UPDATE_PROFILE_CUSTOMER,
      UPDATE_ADMIN_NEED_ENTER_PASSWORD
} from '../constants/action';
import {READ} from "../constants/common";

const initialState = {is_logged: false, admin: {}, customer : null, salon : {}, notifications: []}

const authReducer = (state = initialState, action) =>  {
      const {type, payload} = action
      switch (type) {
            case LOGIN_SUCCESS:
                  return {
                        ...state,
                        is_logged: true,
                        admin: payload.admin,
                        salon : payload.admin.salon
                  };
            case LOGOUT:
                  return {
                        is_logged: false,
                        admin: null,
                        salon: null,
                        notifications: []
                  };
            case CHANGE_EMAIL_RECEIVE_FEEDBACK:
                  let adminUpdate = state.admin
                  adminUpdate.email = payload
                  return {
                        ...state,
                        admin: adminUpdate
                  };
            case UPDATE_SALON:
                  const newDataAdmin = state.admin
                  newDataAdmin.salon = payload

                  return {
                        ...state,
                        admin: newDataAdmin,
                        salon: payload
                  }
            case LIST_NOTIFICATION:
                  return {
                        ...state,
                        notifications: payload.concat(state.notifications).filter((set => f => !set.has(f.id) && set.add(f.id))(new Set)),
                  };
            case NEW_NOTIFICATION:
                  return {
                        ...state,
                        notifications: [payload].concat(state.notifications),
                        admin : {
                              ...state.admin,
                              number_unread_notification: state.admin.number_unread_notification + 1,
                              number_notification: state.admin.number_notification + 1,
                        }
                  };
            case UPDATE_NOTIFICATION:
                  const newListNotification = state.notifications
                  const indexChange = state.notifications.findIndex(item => item.id === payload.id)
                  if(indexChange !== -1){
                        newListNotification[indexChange].is_read = payload.status
                  }
                  return {
                        ...state,
                        notifications: newListNotification,
                        admin : {
                              ...state.admin,
                              number_unread_notification: payload.status === READ ? state.admin.number_unread_notification - 1 : state.admin.number_unread_notification + 1,
                        }
                  };

            case DELETE_NOTIFICATION:
                  return {
                        ...state,
                        notifications: state.notifications.filter(item => !payload.arr.includes(item.id)),
                        admin : {
                              ...state.admin,
                              number_unread_notification: state.admin.number_unread_notification - payload.number_unread,
                              number_notification: state.admin.number_notification - (payload.arr.length),
                        }
                  };
            case READ_ALL_NOTIFICATION:
                  return {
                        ...state,
                        notifications: state.notifications.map(item => {
                              item.is_read = READ
                              return item
                        }),
                        admin : {
                              ...state.admin,
                              number_unread_notification: 0,
                        }
                  };
            case DELETE_ALL_NOTIFICATION:
                  return {
                        ...state,
                        notifications: [],
                        admin : {
                              ...state.admin,
                              number_unread_notification: 0,
                              number_notification: 0,
                        }
                  };
            case CUSTOMER_CHECK_IN:
                  return {
                        ...state,
                        customer: payload.user,
                  };
            case CUSTOMER_LOGOUT:
                  return {
                        ...state,
                        customer: null,
                  };
            case UPDATE_PROFILE_CUSTOMER:
                  return {
                        ...state,
                        customer: payload,
                  };
            case UPDATE_ADMIN_NEED_ENTER_PASSWORD:
                  let newAdmin = state.admin
                  newAdmin.need_enter_password = payload
                  return {
                        ...state,
                        admin: newAdmin,
                  };
            default:
                  return state;
      }
}

export default authReducer;
