import axiosInstance from "./axiosInstance";

const base_path = '/voucher'
const voucherApi = {
      checkVoucher(formData){
            const url = `${base_path}/check-voucher`
            return axiosInstance.post(url, formData)
      },
}

export default voucherApi