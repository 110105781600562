import React, {useState} from 'react';
import {Button, Form, Input, Modal} from "antd";
import AlertCommon from "../../../components/alert";
import {ALERT_ERROR, ALERT_SUCCESS} from "../../../constants/common";
import {LockOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {toggleModalChangePasswordCustomer} from "../../../actions/modal";
import '../../../stylesheets/customer-change-password.scss'
import {customerChangePassword} from "../../../actions/auth";

const CustomerChangePasswordModal = (props) =>  {
      const [form] = Form.useForm()
      const [loading, setLoading] = useState(false)
      const dispatch = useDispatch()
      const {openFormChangePasswordCustomer} = useSelector(state => state.modal)

      const onChangePassword = async (e) => {
            try {
                  setLoading(true)
                  const res = await customerChangePassword(e)
                  AlertCommon(ALERT_SUCCESS, res.message)
                  form.resetFields()
                  setLoading(false)
                  return dispatch(toggleModalChangePasswordCustomer(false))
            } catch (e) {
                  setLoading(false)
                  return AlertCommon(ALERT_ERROR, e.message)
            }
      }

      const handleCloseModal = () => {
            form.resetFields()
            return dispatch(toggleModalChangePasswordCustomer(false))
      }

      return (
            <Modal
                  footer={null}
                  visible={openFormChangePasswordCustomer}
                  maskClosable={false}
                  closable={false}
                  centered
                  className='modal-change-password-customer modal-general'

            >
                  <div className="head-modal">
                        <p className="title">Change password</p>
                        <Button onClick={handleCloseModal}>Close</Button>
                  </div>
                  <div className="body-modal">
                        <div className="form-wp">
                              <Form
                                    form={form}
                                    layout='vertical'
                                    name="form-change-password-hooks"
                                    onFinish={onChangePassword}
                              >
                                    <Form.Item name="old_password" label="Old password">
                                          <Input.Password  iconRender={visible => (visible ? 'Hide' : 'Show')}
                                                           prefix={<LockOutlined className="site-form-item-icon"/>}/>
                                    </Form.Item>
                                    <Form.Item name="new_password" label="New password">
                                          <Input.Password  iconRender={visible => (visible ? 'Hide' : 'Show')}
                                                          prefix={<LockOutlined className="site-form-item-icon"/>}/>
                                    </Form.Item>
                                    <Form.Item name="confirm_password" label="Confirm New Password">
                                          <Input.Password  iconRender={visible => (visible ? 'Hide' : 'Show')}
                                                           prefix={<LockOutlined className="site-form-item-icon"/>}/>
                                    </Form.Item>
                                    <Form.Item>
                                          <Button loading={loading} type="primary" htmlType="submit">
                                                Submit
                                          </Button>
                                    </Form.Item>
                              </Form>
                        </div>
                  </div>
            </Modal>
      );
}

export default CustomerChangePasswordModal;