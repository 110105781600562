import dashboardApi from "../api/dashboardApi";
import {
      LIST_APPOINTMENT_IN_DASHBOARD,
      RECEIVE_APPOINTMENT_FROM_CLIENT,
      SET_APPOINTMENT_UPDATE,
      TOGGLE_FORM_SAVE_APPOINTMENT, UPDATE_STAFF
} from '../constants/action'

export const getListAppointment = () => (dispatch) => {
      return new Promise((resolve, reject) => {
            dashboardApi.listAppointmentInDashboard().then(
                  (res) => {
                        dispatch({
                              type: LIST_APPOINTMENT_IN_DASHBOARD,
                              payload: res.data,
                        });
                        resolve(res)
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
}

export const getReportByDate = (date) => {
      return new Promise((resolve, reject) => {
            dashboardApi.reportByDate(date).then(
                  (res) => {
                        return resolve(res)
                  },
                  (err) => {
                        return reject(err)
                  }
            )
      })
};

export const toggleFormSaveAppointment = (status) => (dispatch) => {
      return dispatch({
            type: TOGGLE_FORM_SAVE_APPOINTMENT,
            payload: status,
      });
}

export const setAppointmentUpdate = (appointment) => (dispatch) => {
      return dispatch({
            type: SET_APPOINTMENT_UPDATE,
            payload: appointment,
      });
}

export const addNewAppointmentFromClient = (appointment) => (dispatch) => {
      return dispatch({
            type: RECEIVE_APPOINTMENT_FROM_CLIENT,
            payload: appointment,
      });
}

export const updateStaffBySocket = (staff) => (dispatch) => {
      dispatch({
            type: UPDATE_STAFF,
            payload: staff
      });
}