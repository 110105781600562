import React, {useEffect, useState} from 'react';
import {Button, Dropdown, Menu, Modal, Skeleton} from "antd";
import { MoreOutlined } from '@ant-design/icons';
import {useDispatch, useSelector} from "react-redux";
import AlertCommon from "../alert";
import {ALERT_SUCCESS, NOT_READ, READ} from "../../constants/common";
import {
      deleteAllNotification,
      deleteNotification, getListNotifications,
      readAllNotification,
      readNotification,
      unreadNotification
} from "../../actions/notification";
import {convertTZLocal} from "../../utils/helper";
import InfiniteScroll from "react-infinite-scroll-component";
import {useTranslation} from "react-i18next";

function Notifications({closeDropdown}) {
      const {notifications} = useSelector(state => state.auth)
      const dispatch = useDispatch()
      const [btnSelect, setBtnSelect] = useState(false)
      const [page, setPage] = useState(1)
      const [loading, setLoading] = useState(false)
      const {t} = useTranslation()

      useEffect(() => {
            (async () => {
                  try {
                        setLoading(true)
                        await dispatch(getListNotifications({num_per_page: 15, page : page}))
                        setLoading(false)
                  } catch (e) {
                        console.log(e);
                  }
            })()
      }, [page])

      const renderListNotification = () => {
            return <InfiniteScroll
                  dataLength={notifications.length}
                  next={() => setPage(page + 1)}
                  hasMore={loading}
                  loader={<Skeleton active paragraph={{ rows: 2 }}/>}
                  scrollableTarget='list-notifications'
                  initialScrollY={0}
                  // style={{ display: 'flex', flexDirection: 'column-reverse' }}
            >
                  {notifications.map(item => (
                        <div className={`item ${btnSelect ? 'item-checkbox' : ''}`} key={item.id}>
                              <span className={`icon ${(!item.is_read || item.is_read === 0) ? 'un-read' : ''  }`}><i className="fa-solid fa-circle"></i></span>
                              <div className='detail'>
                                    <p className='title' onClick={() => viewNotification(item)}>{item.body}</p>
                                    <p className='date'>{convertTZLocal(item.created_at).format}</p>
                              </div>
                              <Dropdown className='action-btn' overlay={() => menuItem(item)} trigger={['click']} placement='bottomLeft'>
                                    <a onClick={e => e.preventDefault()}>
                                          <MoreOutlined />
                                    </a>
                              </Dropdown>
                        </div>
                  ))
                  }
            </InfiniteScroll>
      }
      const menu = (
            <Menu
                  onClick={(e) => handleMenu(e)}
                  className='menu-notification'
                  items={[
                        {
                              label: <Button>{t('notification.read_all')}</Button>,
                              key: 'read-all',
                        },
                        {
                              label: <Button>{t('notification.delete_all')}</Button>,
                              key: 'delete-all',
                        },
                  ]}
            />
      );

      const menuItem = (item) => {
            let action = [
                  {
                        label: <Button>{t('notification.read')}</Button>,
                        key: 'read',
                  },
                  {
                        label: <Button>{t('notification.unread')}</Button>,
                        key: 'unread',
                  },
                  {
                        label: <Button>{t('notification.delete')}</Button>,
                        key: 'delete',
                  }
            ]
            if(item.is_read === READ){
                  action = action.filter(item => item.key !== 'read')
            }
            if(item.is_read !== READ){
                  action = action.filter(item => item.key !== 'unread')
            }
            return (
                  <Menu
                        onClick={(e) => handleMenu(e, item)}
                        className='menu-notification menu-item-notification'
                        items={action}
                  />
            )
      }

      const handleMenu = async (e, item) => {
            const key = e.key
            let response = null
            let numberUnread = 0
            switch (key) {
                  case 'read' :
                        if(item.is_read === NOT_READ){
                              await dispatch(readNotification(item.id))
                        }
                        break
                  case 'unread' :
                        if(item.is_read === READ){
                              await dispatch(unreadNotification(item.id))
                        }
                        break
                  case 'delete':
                        numberUnread = item.is_read === NOT_READ ? 1 : 0
                        response = await dispatch(deleteNotification(JSON.stringify([item.id]) , numberUnread))
                        AlertCommon(ALERT_SUCCESS, response.message)
                        break
                  case 'read-all':
                        await dispatch(readAllNotification())
                        break
                  case 'delete-all':
                        if(notifications.length === 0) break
                        Modal.confirm({
                              title: t('alert.title_confirm_delete_all'),
                              okText: t('button.submit'),
                              cancelText: t('button.cancel'),
                              className: 'modal-confirm-delete',
                              zIndex: 100000,
                              onOk() {
                                    handleDeleteAll()
                              },
                              onCancel() {
                                    return
                              },
                        });
                        break
                  default:
                        break
            }

      }

      const handleDeleteAll = async () => {
            const res = await dispatch(deleteAllNotification())
            return AlertCommon(ALERT_SUCCESS, res.message)
      }

      const viewNotification = (notification) => {
            localStorage.setItem('txt_search_appointments', notification.data_id)
            setBtnSelect(false)
            closeDropdown()
      }

      return (
            <div className="wp-notifications">
                  <div className="wp-inner">
                        <div className="head">
                              <h3 className="title">{t('notification.title')}</h3>
                              <Dropdown  overlay={menu} trigger={['click']} placement='topRight'>
                                    <a onClick={e => e.preventDefault()}>
                                          <MoreOutlined />
                                    </a>
                              </Dropdown>

                        </div>
                        <div  id='list-notifications' className="list-notification">
                              {notifications?.length > 0 ?
                                    renderListNotification()
                                    :
                                    <p className='no-item'>{t('alert.empty_notification')}</p>
                              }
                        </div>
                  </div>
            </div>
      );
}

export default Notifications;