const vi = {
      translation: {
            // here we will place our translations...
            header: {
                  welcome_owner : 'Chào mừng, quản lí',
            },
            dashboard: {
                  customer_check_in : 'Khách hàng check in',
                  customer_appointment : 'Khách hàng đặt hẹn',
            },
            slider: {
                  title_staff_list: 'Danh sách nhân viên',
                  title_column_staff: 'Tên',
                  title_column_customer: 'Khách hàng',
            },
            button: {
                  customer_check_in: 'Khách hàng check in',
                  add_appointment: 'Thêm mới lịch hẹn',
                  create_appointment: 'Tạo mới lịch hẹn',
                  update_appointment: 'Cập nhật lịch hẹn',
                  delete_appointment: 'Xóa lịch hẹn',
                  edit_appointment: 'Chỉnh sửa lịch hẹn',
                  menu: 'Menu',
                  report: 'Báo cáo',
                  check_in_out: 'Check in / Check out',
                  logout: 'Đăng xuất',
                  accepted: 'Chấp nhận',
                  rejected: 'Từ chối',
                  ok: 'Đồng ý',
                  cancel: 'Hủy bỏ',
                  delete: 'Xóa',
                  finish: 'Hoàn thành',
                  submit: 'Gửi',
                  start_service: 'Bắt đầu dịch vụ',
                  add: 'Thêm',
                  booking_list: 'Danh sách lịch hẹn',
                  update: 'Cập nhật',
                  save: 'Lưu',
                  check_in: 'Check in',
                  check_out: 'Check out',
                  close: 'Đóng',
                  add_image: 'Thêm hình ảnh',
            },
            status: {
                  online: 'Trực tuyến',
                  offline: 'Ngoại tuyến',
                  pending: 'Chờ phê duyệt',
                  waiting: 'Chờ dịch vụ',
                  serving: 'Phục vụ',
                  finished: 'Hoàn thành',
                  canceled: 'Đã hủy',
                  deleted: 'Đã xóa',
                  accepted: 'Đã chấp nhận',
                  in_active: 'Không hoạt động',
            },
            modal: {
                  title_confirm_switch_client_mode: 'Chuyển sang chế độ máy khách',
                  content_confirm_switch_client_mode: 'Khách hàng sẽ chỉ có thể sử dụng chức năng đặt dịch vụ.\n' +
                        'Khi muốn chuyển sang vai trò chủ sở hữu, bạn sẽ cần nhập mật khẩu. Bạn có chắc chắn muốn chuyển đổi vai trò? ',
                  title_email_receive_feedback : 'Quản lý email nhận phản hồi từ khách hàng',
                  title_check_in_out_history: 'Lịch sử check in - out nhân viên',
                  title_update_staff: 'Cập nhật nhân viên',
                  title_update_service: 'Cập nhật dịch vụ',
                  title_choose_staff: 'Chọn nhân viên',
                  title_report: 'Tổng quan',
                  title_cancel_appointment: 'Hủy bỏ lịch hẹn',
                  title_staff_detail: 'Thông tin nhân viên',
                  title_customer_detail: 'Thông tin khách hàng',
                  title_staff: 'Quản lí nhân viên',
                  title_service: 'Quản lí dịch vụ',
                  title_customer: 'Quản lí khách hàng',
                  title_salon: 'Quản lí salon',
                  title_appointment_detail: 'Chi tiết lịch hẹn',
                  title_appointment_for_customer: 'Danh sách lịch hẹn của khách hàng',
                  title_add_appointment : 'Tạo mới lịch hẹn',
                  title_update_appointment : 'Cập nhật lịch hẹn',
                  title_change_password : 'Thay đổi mật khẩu',
                  title_update_customer : 'Cập nhật thông tin khách hàng',

            },
            tab: {
                  add_new_staff: 'Thêm mới nhân viên',
                  staff_list: 'Danh sách nhân viên',
                  add_new_service: 'Thêm mới dịch vụ',
                  service_list: 'Danh sách dịch vụ',
            },
            appointment: {
                  request : 'Yêu cầu',
                  services : 'Dịch vụ',
                  total : 'Tổng',
                  customer_name : 'Tên khách hàng',
                  appointment_time : 'Thời gian hẹn',
                  service_note : 'Lưu ý dịch vụ',
                  voucher : 'Mã giảm giá',
                  discount : 'Giảm giá',
                  price: 'Giá',
                  total_discount: 'Tổng giảm giá',
                  appointment_note: 'Lưu ý lịch hẹn',
                  feedback: 'Phản hồi',
                  rate: 'Đánh giá',
                  cancel_by: 'Cancel by',
                  reason_cancel: 'Lí do hủy',
                  time_and_date: 'Ngày và thời gian',
            },
            form: {
                  customer_info: 'Thông tin khách hàng',
                  request_info: 'Thông tin yêu cầu',
                  placeholder_phone: 'Nhập số điện thoại',
                  placeholder_name: 'Nhập tên',
                  label_staff: 'Chọn nhân viên',
                  label_appointment_time: 'Thời gian hẹn',
                  placeholder_staff: 'Chọn nhân viên',
                  placeholder_appointment_time: 'Chọn thời gian hẹn',
                  label_services: 'Dịch vụ',
                  label_note_service: 'Thứ gì khác',
                  label_work_time: 'Ước tính thời gian hoàn thành',
                  placeholder_search_staff: 'Tìm kiếm theo tên hoặc điện thoại ...',
                  placeholder_search_service: 'Tìm kiếm theo tên',
                  placeholder_search_customer: 'Tìm kiếm theo tên hoặc điện thoại hoặc email..',
                  label_salon_name: 'Tên salon',
                  label_email: 'Email',
                  label_phone: 'Số điện thoại',
                  label_address: 'Địa chỉ',
                  label_images: 'Hình ảnh',
                  label_business: 'Giờ làm việc',
                  label_description: 'Miêu tả',
                  placeholder_description: 'Miêu tả',
                  placeholder_open_time: 'Nhập giờ mở cửa',
                  placeholder_close_time: 'Nhập giờ đóng cửa',
                  placeholder_salon_name: 'Nhập tên salon',
                  placeholder_salon_email: 'Nhập email salon',
                  placeholder_salon_phone: 'Nhập số điện thoại salon',
                  placeholder_salon_address: 'Nhập địa chỉ salon',
                  label_old_password: 'Mật khẩu cũ',
                  label_new_password: 'Mật khẩu mới',
                  label_confirm_password: 'Nhập lại mật khẩu',
                  placeholder_service_name: 'Tên dịch vụ',
                  placeholder_service_price: 'Giá tiền',
                  label_more_photos: 'Thêm hình ảnh',
                  placeholder_add_image: 'Thêm hình ảnh',
                  label_reason_cancel : 'Lí do hủy?',
                  label_reason_reject : 'Lí do từ chối?',
                  placeholder_work_time: 'Nhập phút',
                  label_in: 'Vào',
                  label_out: 'Ra',
                  label_total: 'Tổng',
                  label_note: 'Lưu ý',
                  placeholder_first_name: 'Nhập họ',
                  placeholder_last_name: 'Nhập tên',
                  label_type: 'Loại',
                  label_add_image_before: 'Thêm ảnh trước lịch hẹn',
                  label_add_image_after: 'Thêm hình ảnh sau lịch hẹn',
            },
            menu: {
                  title: 'Menu',
                  manage_staff: 'Quản lí nhân viên',
                  manage_service: 'Quản lí dịch vụ',
                  manage_customer: 'Quản lí khách hàng',
                  manage_salon: 'Quản lí tiệm',
                  email_receive_feedback: 'Email nhận phản hồi',
                  change_password: 'Thay đổi mật khẩu',
            },
            table: {
                  column_stt : 'Stt',
                  column_name: 'Tên',
                  column_phone: 'Số điện thoại',
                  column_check_in: 'Check in',
                  column_check_out: 'Check out',
                  column_status: 'Trạng thái',
                  column_price: 'Giá tiền',
                  column_email: 'Email',
                  column_type: 'Loại',
                  column_note: 'Lưu ý',
                  column_customer: 'Khách hàng',
                  column_staff: 'Nhân viên',
                  column_services: 'Dịch vụ',
                  column_service_note: 'Lưu ý dịch vụ',
                  column_address: 'Địa chỉ',
            },
            notification: {
                  title : 'Thông báo',
                  read_all: 'Đọc tất cả',
                  delete_all: 'Xóa tất cả',
                  read: 'Đọc',
                  unread: 'Đánh dấu là chưa đọc',
                  delete: 'Xóa'
            },
            alert: {
                  empty_notification: 'Hiện có 0 thông báo',
                  empty_review: 'Chưa có đánh giá',
                  empty_data: 'Không có dữ liệu',
                  loading_data: 'Đang tải ...',
                  title_confirm_delete: 'Bạn có chắc chắn xóa?',
                  title_confirm_delete_all: 'Bạn có chắc xóa tất cả?',
                  no_info: 'Không có thông tin',
                  lock_service: 'Bạn có chắc chắn muốn khóa dịch vụ này?',
                  unlock_service: 'Bạn có chắc chắn muốn mở khóa dịch vụ này?',
                  lock_staff: 'Bạn có chắc chắn muốn khóa nhân viên này?',
                  unlock_staff: 'Bạn có chắc chắn muốn mở khóa nhân viên này?'
            }
      }
}
export default vi