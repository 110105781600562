import axiosInstance from "./axiosInstance";

const base_path = '/staff'
const serviceApi = {
      listStaffCheckIn(){
            const url = `${base_path}/list-staff-check-in`
            return axiosInstance.get(url)
      },
      checkStatusStaff(id){
            const url = `${base_path}/check-staff`
            return axiosInstance.post(url, {id})
      },
      listStaff() {
            const url = `${base_path}/list-all-staff`
            return axiosInstance.post(url)
      },
      createStaff(data) {
            const url = `${base_path}/create-staff`
            return axiosInstance.post(url, data)
      },
      updateStaff(data) {
            const url = `${base_path}/update-staff`
            return axiosInstance.post(url, data)
      },
      changeStatus(data) {
            const url = `${base_path}/change-status`
            return axiosInstance.post(url, data)
      },
      deleteStaff(id) {
            const url = `${base_path}/delete-staff`
            return axiosInstance.post(url, {id})
      },
      changeActive(id) {
            const url = `${base_path}/change-active`
            return axiosInstance.post(url, {id})
      },
      historyCheckInOut(param) {
            const url = `${base_path}/history-check-in-out`
            return axiosInstance.get(url, {params: param})
      },
      getListStaffInBooking(salonId){
            const url = `${base_path}/list-staff-booking`
            return axiosInstance.post(url, {salon_id : salonId})
      },

}

export default serviceApi