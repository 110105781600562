import React, {useRef, useState} from 'react';
import {Avatar, Button, Form, Input, Modal, Popconfirm, Tabs, Upload} from "antd";
import {DeleteOutlined, FormOutlined, LockOutlined, UnlockOutlined, SearchOutlined} from '@ant-design/icons';
import DatatableGeneral from "../../../components/datatable";
import {ACTIVE, ALERT_ERROR, ALERT_SUCCESS, TABLE_SERVICE} from "../../../constants/common";
import {useDispatch, useSelector} from "react-redux";
import AlertCommon from "../../../components/alert";
import {
      changeActiveService,
      createNewService,
      deleteService,
      setOpenFormEditService,
      setServiceEdit
} from "../../../actions/service";
import {toggleShowModalService} from "../../../actions/modal";
import EmptyData from "../../../components/empty";
import {getBase64} from "../../../utils/helper";
import {useTranslation} from "react-i18next";

const ModalManageService = (props) => {
      const {t} = useTranslation()
      const {openService} = useSelector(state => state.modal)
      const [tab, setTab] = useState('list-service')
      const [loading, setLoading] = useState(false)
      const {services} = useSelector(state => state.service)
      const [form] = Form.useForm()
      const dispatch = useDispatch()
      const typingRef = useRef(null)
      const [txtSearch, setTxtSearch] = useState('')
      const serviceList = services.filter(item => item.name.toLowerCase().includes(txtSearch.toLowerCase()))
      const [images, setImages] = useState([])
      const [avatar, setAvatar] = useState(null)

      const handleChangeTab = (key) => {
            setTab(key)
      }

      const openEditService = (service) => {
            dispatch(setServiceEdit(service))
            dispatch(setOpenFormEditService(true))
      }

      let columns = [
            {
                  title: 'Id',
                  dataIndex: '_id',
                  key: '_id',
            },
            {
                  title: t('table.column_stt'),
                  dataIndex: 'key',
                  key: 'key',
                  render: text => <p>{text}</p>,
            },
            {
                  title: t('table.column_name'),
                  dataIndex: 'name',
                  key: 'name',
                  render: record => <React.Fragment><Avatar shape="square" size={32} src={record.image} /><span>{record.name}</span></React.Fragment>,
            },
            {
                  title: t('table.column_price'),
                  dataIndex: 'price',
                  key: 'price',
                  render: (text, record) => <p className='price'><span className="currency">$</span>{text}</p>,
            },
            {
                  title: t('table.column_status'),
                  dataIndex: 'status',
                  key: 'status',
                  render: record => <p className={`status ${record.active === ACTIVE ? 'active' : 'inactive'}`}>{record.active === ACTIVE ? 'Active': 'Inactive'}</p>,
            },
            {
                  title: <i className="fa-solid fa-screwdriver-wrench"></i>,
                  dataIndex: 'action',
                  key: 'action',
                  render: (record) => <div className='action-row'>
                        <Button className='edit-row-btn' onClick={() => openEditService(record)}><FormOutlined/></Button>
                        <Popconfirm
                              title={t('alert.title_confirm_delete')}
                              placement="topRight"
                              onConfirm={() => handleDeleteService(record.id)}
                              okText={t('button.delete')}
                              cancelText={t('button.cancel')}
                        >
                              <Button className='delete-row-btn'><DeleteOutlined/></Button>
                        </Popconfirm>
                        <React.Fragment>
                              {record.active ===  ACTIVE ?
                                    <Popconfirm
                                          title={t('alert.lock_service')}
                                          placement="topRight"
                                          onConfirm={() => handleChangeActive(record.id)}
                                          okText={t('button.submit')}
                                          cancelText={t('button.cancel')}
                                    >
                                          <Button className='edit-row-btn'><LockOutlined/></Button>
                                    </Popconfirm>

                                    :
                                    <Popconfirm
                                          title={t('alert.unlock_service')}
                                          placement="topRight"
                                          onConfirm={() => handleChangeActive(record.id)}
                                          okText={t('button.submit')}
                                          cancelText={t('button.cancel')}
                                    >
                                          <Button className='edit-row-btn'><UnlockOutlined/></Button>
                                    </Popconfirm>

                              }
                        </React.Fragment>
                  </div>
            },
      ];

      const contentData = () => {
            return serviceList.map((item, index) => {
                  return {
                        _id: item.id,
                        key: index + 1,
                        name: item,
                        price: item.price_format,
                        action: item,
                        status: item
                  }
            })
      }
      let dataSource = {
            totalElements: serviceList.length,
            content: contentData(),
      };

      const {
            DataTable,
      } = DatatableGeneral({
            type: TABLE_SERVICE,
            columns: columns,
            dataSource: dataSource,
            updateType: TABLE_SERVICE,
      });

      const onSaveService = async (e) => {
            try {
                  let formData = new FormData()
                  if(avatar && avatar.originFileObj){
                        e.avatar = avatar.originFileObj
                  }
                  Object.keys(e).forEach( function (key) {
                        if(typeof e[key] === 'string'){
                              e[key] = e[key] ? e[key].trim() : null;
                        }
                        formData.append(key, e[key])
                  });
                  if(e.price && (parseFloat(e.price) < 0.01 || parseFloat(e.price) > 9999.99)){
                        return AlertCommon(ALERT_ERROR, 'Please input price > 0 & < 9999.99')
                  }
                  const files = []
                  const imageUpload = images.filter(item => item.originFileObj)
                  if(imageUpload.length > 0){
                        imageUpload.map(item => files.push(item.originFileObj))
                  }
                  Object.keys(files).forEach((key, i) => {
                        formData.append('images', files[key]);
                  });
                  let response = await dispatch(createNewService(formData))
                  setLoading(false)
                  AlertCommon(ALERT_SUCCESS, response.message)
                  form.resetFields()
                  setImages([])
                  setAvatar(null)
                  setTab('list-service')
            } catch (e) {
                  setLoading(false)
                  return AlertCommon(ALERT_ERROR, e.message)
            }
      }
      const handleDeleteService = async (id) => {
            try {
                  let res = await dispatch(deleteService(id))
                  return AlertCommon(ALERT_SUCCESS, res.message)
            } catch (e) {
                return AlertCommon(ALERT_ERROR, e.message)
            }
      }

      const handleChangeActive = async (id) => {
            try {
                  let res = await dispatch(changeActiveService(id))
                  return AlertCommon(ALERT_SUCCESS, res.message)
            } catch (e) {
                  return AlertCommon(ALERT_ERROR, e.message)
            }
      }

      const dummyRequest = ({file, onSuccess}) => {
            setTimeout(() => {
                  onSuccess(true);
            }, 0);
      };

      const handleChangeUpload = ({ fileList: newFileList }) => {
            setImages(newFileList)
      }

      const handleChangeUploadAvatar = (info) => {
            if (info.file.status === 'done') {
                  // Get this url from response in real world.
                  getBase64(info.file.originFileObj, (url) => {
                        setLoading(false);
                        let newFile = info.file
                        newFile.image = url
                        setAvatar(info.file)
                  });
            }
      }


      const handleCloseModal = () => {
            setTab('list-service')
            form.resetFields()
            setImages([])
            setAvatar(null)
            return dispatch(toggleShowModalService(false))
      }

      const onSearch = (e) => {
            const value = e.target.value || ''
            if(typingRef.current){
                  clearTimeout(typingRef.current)
            }
            typingRef.current = setTimeout(() => {
                  setTxtSearch(value)
            }, 1000)
      }

      return (
            <Modal
                  footer={null}
                  visible={openService}
                  maskClosable={false}
                  closable={false}
                  centered
                  className='modal-general modal-service modal-table'
            >
                  <div className="head-modal">
                        <p className="title">{t('modal.title_service')}</p>
                        <Button onClick={handleCloseModal}>{t('button.close')}</Button>
                  </div>
                  <div className="body-modal">
                        <div className="services">
                              <Tabs activeKey={tab} onChange={handleChangeTab}>
                                    <Tabs.TabPane tab={t('tab.add_new_service')} key="new-service">
                                          <div className="form-wp">
                                                <Form
                                                      form={form}
                                                      layout='vertical'
                                                      name="control-hooks"
                                                      onFinish={onSaveService}
                                                >

                                                      <Form.Item>
                                                            <Upload
                                                                  className='upload-avatar-service'
                                                                  listType="picture-card"
                                                                  onChange={handleChangeUploadAvatar}
                                                                  customRequest={dummyRequest}
                                                                  accept=".jpg,.jpeg,.png"
                                                                  showUploadList={false}
                                                            >
                                                                  {avatar ? <img src={avatar.image} alt="avatar" style={{width: '100%',}}
                                                                  /> : <div className='upload-btn'>
                                                                              <i className="fa-regular fa-image"></i>
                                                                              <div className='text-upload-btn'>{t('button.add_image')}</div>
                                                                        </div>
                                                                  }
                                                            </Upload>
                                                      </Form.Item>

                                                      <Form.Item name="name">
                                                            <Input placeholder={t('form.placeholder_service_name')}/>
                                                      </Form.Item>
                                                      <Form.Item name="price">
                                                            <Input placeholder={t('form.placeholder_service_price')} />
                                                      </Form.Item>
                                                      <Form.Item label={t('form.label_more_photos')}>
                                                            <Upload
                                                                  className='upload-image-salon'
                                                                  listType="picture-card"
                                                                  fileList={images}
                                                                  onChange={handleChangeUpload}
                                                                  customRequest={dummyRequest}
                                                                  accept=".jpg,.jpeg,.png"
                                                                  showUploadList={{showPreviewIcon: false, showRemoveIcon: true}}
                                                            >
                                                                  {images.length >= 10 ? null : <i className="fa-solid fa-images"></i>}
                                                            </Upload>
                                                      </Form.Item>

                                                      <Form.Item>
                                                            <Button loading={loading} type="primary" htmlType="submit">
                                                                  {t('button.add')}
                                                            </Button>
                                                      </Form.Item>
                                                </Form>
                                          </div>
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab={t('tab.service_list')} key="list-service">
                                          <Input className='input-search' placeholder={t('form.placeholder_search_service')} prefix={<SearchOutlined/>} onChange={onSearch} />
                                          <DataTable/>
                                          {serviceList.length === 0 &&
                                                <EmptyData/>
                                          }
                                    </Tabs.TabPane>
                              </Tabs>
                        </div>
                  </div>
            </Modal>
      );
}

export default ModalManageService;