import axiosInstance from "./axiosInstance";

const base_path = '/auth'
const authApi = {
      resetPassword(data){
            const url = `${base_path}/reset-password`
            return axiosInstance.post(url, data)
      },
      verifyCode(code){
            const url = `${base_path}/verify-code`
            return axiosInstance.post(url, {code: code})
      },
      customerCheckIn(data){
            const url = `${base_path}/check-in`
            return axiosInstance.post(url, data)
      },
      customerChangePassword(data){
            const url = `${base_path}/change-password`
            return axiosInstance.post(url, data)
      },
}

export default authApi