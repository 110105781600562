import React, {useState} from 'react';
import AuthFeature from "../index";
import {Button, Form, Input} from "antd";
import {LockOutlined, UserOutlined} from '@ant-design/icons'
import {useDispatch} from "react-redux";
import AlertCommon from "../../../components/alert";
import {ALERT_ERROR, ALERT_SUCCESS} from "../../../constants/common";
import {login} from "../../../actions/auth";
import {Link, useNavigate} from 'react-router-dom'
import {normalizeInput, replaceFormatPhoneLogin} from "../../../utils/helper";
import logo from '../../../assets/images/app/logo-white.png'

const Login = (props) => {
      const [form] = Form.useForm();
      const [loading, setLoading] = useState(false)
      const dispatch = useDispatch()
      const navigate = useNavigate()
      const [phone, setPhone] = useState('')

      const onLogin = async (e) => {
            try {
                  setLoading(true)
                  e.phone = replaceFormatPhoneLogin(phone)
                  let res = await dispatch(login(e))
                  AlertCommon(ALERT_SUCCESS, res.message)
                  return navigate('/dashboard')
            } catch (e) {
                  setLoading(false)
                  return AlertCommon(ALERT_ERROR, e.message)
            }
      }

      const handleChangePhone = (e) => {
            return setPhone(prevState => normalizeInput(e.target.value, prevState))
      }

      return (
            <AuthFeature>
                  <div className='login-page'>
                        <div className="wp-inner container">
                              <div className='content-login'>
                                    <div className="wp-form">
                                          <div className="widget">
                                                <div className="widget-body">
                                                      <div className="thumb">
                                                            <img src={logo} alt=""/>
                                                      </div>
                                                      <h3 className='title-form'>Chủ salon - Đăng nhập</h3>
                                                      <div className='or-sign-up'>
                                                            <Link to='/sign-up'>Nhấn vào để đăng kí</Link>
                                                      </div>
                                                      <div className="form-admin-login">
                                                            <Form form={form}
                                                                  layout='vertical'
                                                                  name="login-hooks"
                                                                  onFinish={onLogin}
                                                                  initialValues={{}}
                                                            >
                                                                  <Form.Item >
                                                                        <Input placeholder="Nhập vào số điện thoại" value={phone} onChange={handleChangePhone} prefix={<UserOutlined
                                                                              className="site-form-item-icon"/>}/>
                                                                  </Form.Item>
                                                                  <Form.Item name="password" >
                                                                        <Input.Password
                                                                              placeholder="Mật khẩu"
                                                                              iconRender={visible => (visible ? 'Hide' : 'Show')}
                                                                              prefix={<LockOutlined
                                                                                    className="site-form-item-icon"/>}
                                                                        />
                                                                  </Form.Item>
                                                                  <div className="btn-form">

                                                                        <Button loading={loading} type="primary"
                                                                                htmlType="submit">
                                                                              Đăng nhập
                                                                        </Button>
                                                                        <div className="or-forgot-password">
                                                                              <p>Bạn đã quên mật khẩu? <Link to='/forgot-password'> Lấy lại mật khẩu tại đây</Link></p>
                                                                        </div>

                                                                  </div>
                                                            </Form>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </AuthFeature>
      );
}

export default Login;