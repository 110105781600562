import React, {useState} from 'react';
import {Table} from 'antd';
import useActionMenu from './action';
import {TABLE_STAFF} from "../../constants/common";
const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE_NUMBER = 0;

function DatatableGeneral({type , columns, dataSource, updateType}) {
      const [selectedRowKeys, setSelectedRowKeys] = useState([]);
      const [selectedRow, setSelectedRow] = useState(null);
      const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_NUMBER);
      const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
      const [actionColumnView] = useActionMenu({type, selectedRow, updateType});
      const hasSelected = selectedRowKeys.length > 0;

      let updatedColumns = [
            ...columns,
            {
                  title: <i className="fa-solid fa-screwdriver-wrench"></i>,
                  key: 'action',
                  render: () => actionColumnView,
            },
      ];

      const resetPagination = () => {
            setCurrentPage(DEFAULT_PAGE_NUMBER);
      };

      const handleTableChange = pagination => {
            setCurrentPage(pagination.current - 1);
      };

      const DataTable = () => (
            <Table
                  className='table-general'
                  rowKey={record => record._id}
                  // columns={type === TABLE_STAFF ? columns : updatedColumns}
                  columns = {columns}
                  dataSource={dataSource.content}
                  scroll={{ x: 500 }}
                  onChange={handleTableChange}
                  pagination={{
                        pageSize: DEFAULT_PAGE_SIZE,
                        current: currentPage + 1,
                        total: dataSource.totalElements,
                        showTotal: (total, range) => {
                              return `${range[0]}-${range[1]} of ${total} items`;
                        },
                  }}
                  // onRow={record => {
                  //       return {
                  //             onClick: () => {
                  //                   console.log(`record:: ${record._id}`);
                  //                   setSelectedRow(record);
                  //             },
                  //       };
                  // }}
            />
      );

      return {
            DataTable,
            hasSelected,
            selectedRow,
            selectedRowKeys,
            currentPage,
            pageSize,
            resetPagination,
      };
}

export default DatatableGeneral;
