import axiosInstance from "./axiosInstance";

const base_path = '/setting'
const settingApi = {
      changeEmailReceiveFeedback(email){
            const url = `${base_path}/setting-email-receive-feedback`
            return axiosInstance.post(url, {email})
      },
      getValueDefault(key){
            const url = `${base_path}/get-value-default`
            return axiosInstance.post(url, {key})
      },
}

export default settingApi