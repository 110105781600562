import React from 'react';
import {Button, Modal} from "antd";
import {customerLogout} from "../../../actions/auth";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import '../../../stylesheets/customer-header.scss'
import {toggleModalChangePasswordCustomer, toggleModalProfileCustomer} from "../../../actions/modal";

function CustomerHeader({customer}) {
      const navigate = useNavigate()
      const dispatch = useDispatch()

      const openFormUpdateProfile = () => {
            dispatch(toggleModalProfileCustomer(true))
      }
      const openListAppointment = () => {
            // return dispatch(toggleModalListAppointment(true))
      }
      const openFormChangePassword = () => {
            console.log(100);
            dispatch(toggleModalChangePasswordCustomer(true))
      }
      const handleLogout = async () => {
            Modal.confirm({
                  title: `Are you sure you want to log out of the customer account? `,
                  className: 'modal-confirm-logout',
                  okText: 'Submit',
                  onOk() {
                        return acceptLogout()
                  },
                  onCancel() {
                        return
                  },
            });
      }

      const acceptLogout = async () => {
            await dispatch(customerLogout())
            return navigate(`/customer-checkin`)
      }

      return (
            <div className='customer-header'>
                  <div className="wp-inner">
                        <div className="name block">
                              <p className="sub-title">Welcome, <span>{customer.name}</span></p>
                        </div>
                        <div className="btn-profile block">
                              {customer ?
                                    <>
                                          <Button onClick={openFormUpdateProfile}>Profile</Button>
                                          {/*<Button onClick={openListAppointment}>Your appointment</Button>*/}
                                          <Button className='change-password' onClick={openFormChangePassword}>Change password</Button>
                                          <Button className='logout' onClick={handleLogout}>Logout</Button>
                                    </>
                                    :
                                    <Button className='owner-logout' onClick={handleLogout}>
                                          <i className="fa-solid fa-right-from-bracket"></i>
                                    </Button>
                              }


                        </div>
                  </div>
            </div>
      );
}

export default CustomerHeader;