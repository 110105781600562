import {
      OPEN_MODAL_STAFF,
      OPEN_MODAL_SETTING,
      OPEN_MODAL_SERVICE,
      OPEN_MODAL_CUSTOMER,
      OPEN_MODAL_CHANGE_PASSWORD,
      OPEN_MODAL_REPORT,
      OPEN_MODAL_WORKING,
      OPEN_MODEL_CANCEL_APPOINTMENT,
      OPEN_MODAL_REJECT_APPOINTMENT,
      OPEN_MODAL_CHOOSE_STAFF_APPOINTMENT,
      OPEN_MODAL_NOTIFICATION_APPOINTMENT,
      OPEN_LIST_APPOINTMENT_FOR_CUSTOMER,
      OPEN_HISTORY_CHECK_IN_OUT_STAFF,
      OPEN_FORM_PROFILE_SALON,
      TOGGLE_MODAL_PROFILE_CUSTOMER,
      TOGGLE_MODAL_CHANGE_PASSWORD_CUSTOMER,
      TOGGLE_MODAL_UPDATE_INFO_CUSTOMER_OF_SALON, OPEN_MODAL_ENTER_PASSWORD_ADMIN
} from '../constants/action';

const initialState = {
      openStaff : false,
      openService: false,
      openCustomer: false,
      openSetting: false,
      openChangePassword: false,
      openReport: false,
      openWorking: false,
      openCancelAppointment: false,
      openReject: false,
      openChooseStaff: false,
      openModalNotify : false,
      dataNotify : null,
      customerSelect: null,
      staffSelect: null,
      openListAppointmentForCustomer: false,
      openHistoryCheckInCheckOut: false,
      openProfileSalon: false,
      openProfileCustomer: false,
      openFormChangePasswordCustomer: false,
      openFormUpdateInfoCustomerOfSalon: false,
      openEnterPasswordAdmin: false,
}

const authReducer = (state = initialState, action) =>  {
      const {type, payload} = action
      switch (type) {
            case OPEN_MODAL_STAFF:
                  return {
                        ...state,
                        openStaff: payload,
                  };
            case OPEN_MODAL_SERVICE:
                  return {
                        ...state,
                        openService: payload,
                  };
            case OPEN_MODAL_CUSTOMER:
                  return {
                        ...state,
                        openCustomer: payload
                  };
            case OPEN_MODAL_SETTING:
                  return {
                        ...state,
                        openSetting: payload
                  };
            case OPEN_MODAL_CHANGE_PASSWORD:
                  return {
                        ...state,
                        openChangePassword: payload
                  };
            case OPEN_MODAL_REPORT:
                  return {
                        ...state,
                        openReport: payload
                  };
            case OPEN_MODAL_WORKING:
                  return {
                        ...state,
                        openWorking: payload
                  };
            case OPEN_MODEL_CANCEL_APPOINTMENT:
                  return {
                        ...state,
                        openCancelAppointment: payload
                  };
            case OPEN_MODAL_REJECT_APPOINTMENT:
                  return {
                        ...state,
                        openReject: payload
                  };
            case OPEN_MODAL_CHOOSE_STAFF_APPOINTMENT:
                  return {
                        ...state,
                        openChooseStaff: payload
                  };
            case OPEN_MODAL_NOTIFICATION_APPOINTMENT:
                  return {
                        ...state,
                        openModalNotify: payload.status,
                        dataNotify: payload.data
                  };
            case OPEN_LIST_APPOINTMENT_FOR_CUSTOMER:
                  return {
                        ...state,
                        customerSelect: payload.data,
                        openListAppointmentForCustomer: payload.status
                  };
            case OPEN_HISTORY_CHECK_IN_OUT_STAFF:
                  return {
                        ...state,
                        staffSelect: payload.data,
                        openHistoryCheckInCheckOut: payload.status
                  };
            case OPEN_FORM_PROFILE_SALON:
                  return {
                        ...state,
                        openProfileSalon: payload.status
                  }
            case TOGGLE_MODAL_PROFILE_CUSTOMER:
                  return {
                        ...state,
                        openProfileCustomer: payload.status
                  }
            case TOGGLE_MODAL_CHANGE_PASSWORD_CUSTOMER:
                  return {
                        ...state,
                        openFormChangePasswordCustomer: payload.status
                  }
            case TOGGLE_MODAL_UPDATE_INFO_CUSTOMER_OF_SALON:
                  return {
                        ...state,
                        openFormUpdateInfoCustomerOfSalon: payload.status
                  }
            case OPEN_MODAL_ENTER_PASSWORD_ADMIN:
                  return {
                        ...state,
                        openEnterPasswordAdmin: payload.status
                  }
            default:
                  return state;
      }
}

export default authReducer;
