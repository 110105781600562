import {CHANGE_LANGUAGE_DEFAULT} from "../constants/action";

const initialState = {language: 'vi'}

const configReducer = (state = initialState , action) => {
      const {type, payload} = action

      switch (type) {
            case CHANGE_LANGUAGE_DEFAULT:
                  return {
                        ...state,
                        language: payload
                  }
            default:
                  return state
      }
}

export default configReducer