export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGOUT = "LOGOUT"
export const CHANGE_EMAIL_RECEIVE_FEEDBACK = 'CHANGE_EMAIL_RECEIVE_FEEDBACK'
export const UPDATE_ADMIN_NEED_ENTER_PASSWORD = 'UPDATE_ADMIN_NEED_ENTER_PASSWORD'

export const LIST_APPOINTMENT_IN_DASHBOARD = 'LIST_APPOINTMENT_IN_DASHBOARD'
export const RECEIVE_APPOINTMENT_FROM_CLIENT = 'RECEIVE_APPOINTMENT_FROM_CLIENT'


export const LIST_STAFF = 'LIST_STAFF'
export const CREATE_STAFF = 'CREATE_STAFF'
export const UPDATE_STAFF = 'UPDATE_STAFF'
export const CHANGE_STATUS_STAFF = 'CHANGE_STATUS_STAFF'
export const DELETE_STAFF = 'DELETE_STAFF'
export const SET_STAFF_SELECTED = 'SET_STAFF_SELECTED'
export const SHOW_STAFF_PANEL = 'SHOW_STAFF_PANEL'
export const SET_STAFF_EDIT = 'SET_STAFF_EDIT'
export const OPEN_FORM_EDIT_STAFF = 'OPEN_FORM_EDIT_STAFF'
export const LIST_STAFF_IN_BOOKING = 'LIST_STAFF_IN_BOOKING'
export const UPDATE_STAFF_IN_BOOKING = 'UPDATE_STAFF_IN_BOOKING'


export const LIST_SERVICE = 'LIST_SERVICE'
export const CREATE_SERVICE = 'CREATE_SERVICE'
export const DELETE_SERVICE = 'DELETE_SERVICE'
export const SET_SERVICE_EDIT = 'SET_SERVICE_EDIT'
export const OPEN_FORM_EDIT_SERVICE = 'OPEN_FORM_EDIT_SERVICE'
export const UPDATE_SERVICE = 'UPDATE_SERVICE'
export const LIST_SERVICE_IN_BOOKING = 'LIST_SERVICE_IN_BOOKING'
export const UPDATE_SERVICE_IN_BOOKING = 'UPDATE_SERVICE_IN_BOOKING'

export const LIST_CUSTOMER = 'LIST_CUSTOMER'
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER'

export const CREATE_APPOINTMENT = 'CREATE_APPOINTMENT'
export const UPDATE_APPOINTMENT = 'UPDATE_APPOINTMENT'
export const REMOVE_APPOINTMENT = 'REMOVE_APPOINTMENT'
export const TOGGLE_FORM_SAVE_APPOINTMENT = 'TOGGLE_FORM_SAVE_APPOINTMENT'
export const SET_APPOINTMENT_UPDATE = 'SET_APPOINTMENT_UPDATE'
export const SET_APPOINTMENT_SELECTED = 'SET_APPOINTMENT_SELECTED'
export const OPEN_APPOINTMENT_DETAIL = 'OPEN_APPOINTMENT_DETAIL'

export const CHANGE_STAFF_IN_APPOINTMENT = 'CHANGE_STAFF_IN_APPOINTMENT'

export const OPEN_MODAL_STAFF = 'OPEN_MODAL_STAFF'
export const OPEN_MODAL_SERVICE = 'OPEN_MODAL_SERVICE'
export const OPEN_MODAL_CUSTOMER = 'OPEN_MODAL_CUSTOMER'
export const OPEN_MODAL_SETTING = 'OPEN_MODAL_SETTING'
export const OPEN_MODAL_CHANGE_PASSWORD = 'OPEN_MODAL_CHANGE_PASSWORD'
export const OPEN_MODAL_REPORT = 'OPEN_MODAL_REPORT'
export const OPEN_MODAL_WORKING = 'OPEN_MODAL_WORKING'
export const OPEN_MODEL_CANCEL_APPOINTMENT = 'OPEN_MODEL_CANCEL_APPOINTMENT'
export const OPEN_MODAL_REJECT_APPOINTMENT = 'OPEN_MODAL_REJECT_APPOINTMENT'
export const OPEN_MODAL_CHOOSE_STAFF_APPOINTMENT = 'OPEN_MODAL_CHOOSE_STAFF_APPOINTMENT'
export const OPEN_MODAL_NOTIFICATION_APPOINTMENT = 'OPEN_MODAL_NOTIFICATION_APPOINTMENT'
export const OPEN_LIST_APPOINTMENT_FOR_CUSTOMER = 'OPEN_LIST_APPOINTMENT_FOR_CUSTOMER'
export const OPEN_HISTORY_CHECK_IN_OUT_STAFF = 'OPEN_HISTORY_CHECK_IN_OUT_STAFF'
export const OPEN_FORM_PROFILE_SALON = 'OPEN_FORM_PROFILE_SALON'

export const LIST_NOTIFICATION = "LIST_NOTIFICATION"
export const NEW_NOTIFICATION = "NEW_NOTIFICATION"
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION"
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION"
export const READ_ALL_NOTIFICATION = "READ_ALL_NOTIFICATION"
export const DELETE_ALL_NOTIFICATION = "DELETE_ALL_NOTIFICATION"

export const UPDATE_SALON = 'UPDATE_SALON'

export const TOGGLE_MODAL_PROFILE_CUSTOMER = 'TOGGLE_MODAL_PROFILE_CUSTOMER'
export const TOGGLE_MODAL_CHANGE_PASSWORD_CUSTOMER = 'TOGGLE_MODAL_CHANGE_PASSWORD_CUSTOMER'
export const TOGGLE_MODAL_UPDATE_INFO_CUSTOMER_OF_SALON = 'TOGGLE_MODAL_UPDATE_INFO_CUSTOMER_OF_SALON'
export const CUSTOMER_CHECK_IN = 'CUSTOMER_CHECK_IN'
export const CUSTOMER_LOGOUT = 'CUSTOMER_LOGOUT'
export const CUSTOMER_ADD_NEW_APPOINTMENT = 'CUSTOMER_ADD_NEW_APPOINTMENT'
export const UPDATE_PROFILE_CUSTOMER = 'UPDATE_PROFILE_CUSTOMER'
export const UPDATE_INFO_CUSTOMER = 'UPDATE_INFO_CUSTOMER'
export const OPEN_MODAL_ENTER_PASSWORD_ADMIN = 'OPEN_MODAL_ENTER_PASSWORD_ADMIN'

export const CHANGE_LANGUAGE_DEFAULT = 'CHANGE_LANGUAGE_DEFAULT'




