import React, {useEffect, useState} from 'react';
import {Button, Modal, Spin} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {toggleModalListAppointmentForCustomer} from "../../../actions/modal";
import { LoadingOutlined } from '@ant-design/icons';
import AlertCommon from "../../../components/alert";
import {ALERT_ERROR, TYPE_VOUCHER} from "../../../constants/common";
import {listAppointmentForCustomer} from "../../../actions/customer";
import {useTranslation} from "react-i18next";
import LoadingData from "../../../components/load";

const ModalListAppointmentOfCustomer = (props) =>  {
      const {customerSelect, openListAppointmentForCustomer} = useSelector(state => state.modal)
      const [appointments, setAppointments] = useState([])
      const [loadData, setLoadData] = useState(true)
      // const [loading, setLoading] = useState(false)
      const dispatch = useDispatch()
      const {t} = useTranslation()

      useEffect(() => {
            (async () => {
                  try {
                        setLoadData(true)
                        if(!customerSelect) return
                        const res = await listAppointmentForCustomer({id : customerSelect?._id})
                        setAppointments(res.data)
                        setLoadData(false)
                  } catch (e) {
                      return AlertCommon(ALERT_ERROR, e.message)
                  }
            })()
      }, [customerSelect])

      const renderListAppointment = () => {
            return appointments.map(item =>
                  <li className="item" key={item.id}>
                        <div className="detail">
                              <p>
                                    <span>ID: <span className='appointment-id'>#{item.id}</span></span>
                                    <span className={`status ${item.status_class}`}>{item.status_name} <i className="fa-solid fa-circle"></i> </span>
                              </p>
                              <p>
                                    <span>{t('appointment.time_and_date')}</span>
                                    <span>{item.date_appointment_format}</span>
                              </p>
                              <p>
                                    <span>{t('appointment.services')}</span>
                                    <span>{item.list_service_names_with_price}</span>
                              </p>
                              <p>
                                    <span>{t('appointment.request')}</span>
                                    <span>{item.staff_name}</span>
                              </p>
                              <p>
                                    <span>{t('appointment.voucher')}</span>
                                    <span className={`text ${item.voucher_code ? '' : 'no-info'}`} >{item.voucher_code ? item.voucher_code : t('alert.no_info')}</span>
                              </p>
                              <p>
                                    <span>{t('appointment.discount')}</span>
                                    {item.voucher_code ?
                                          <span>{item.type_discount === TYPE_VOUCHER ? `$${item.total_discount_format}` : `${item.discount}%`}</span>
                                          :
                                          <span>$0.00</span>
                                    }
                              </p>
                              <p>
                                    <span>{t('appointment.price')}</span>
                                    <span>${item.total_price_format}</span>
                              </p>
                              <p>
                                    <span>{t('appointment.total_discount')}</span>
                                    <span>${item.total_discount_format}</span>
                              </p>
                              <p>
                                    <span>{t('appointment.total')}</span>
                                    <span className='price'>${item.price ? item.price_format : item.total_price_service_format}</span>
                              </p>
                        </div>
                  </li>
            )
      }

      return (
            <Modal
                  footer={null}
                  visible={openListAppointmentForCustomer}
                  maskClosable={false}
                  closable={false}
                  centered
                  className='modal-general modal-appointment-of-customer modal-table'
            >
                  <div className="head-modal">
                        <p className="title">{t('modal.title_appointment_for_customer')}</p>
                        <Button onClick={() => dispatch(toggleModalListAppointmentForCustomer(null, false))}>{t('button.close')}</Button>
                  </div>
                  <div className="body-modal">
                        {loadData ?
                              <LoadingData/>
                        :
                              <ul className="appointments-customer">
                                    {renderListAppointment()}
                              </ul>
                        }

                  </div>
            </Modal>
      );
}

export default ModalListAppointmentOfCustomer;