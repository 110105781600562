import React from 'react';
import {Button, Col, Layout, Row, Modal} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from 'react-router-dom'
import {logout, updateAdminNeedEnterPassword} from "../../actions/auth";
import AlertCommon from "../alert";
import {ALERT_SUCCESS, STAFF_WORKING, STAFF_AVAILABLE, STAFF_BREAK} from "../../constants/common";
import {toggleFormSaveAppointment} from "../../actions/dashboard";
import {setOpenStaffPanel, setStaffSelected} from "../../actions/staff";
import {toggleShowModalReport, toggleShowModalStaff} from "../../actions/modal";
import { ExclamationCircleFilled } from '@ant-design/icons';
import {useTranslation} from "react-i18next";

function SideBar ({onShowMenu}) {
      const {t} = useTranslation()
      const theme = 'light';
      const  dispatch = useDispatch()
      const navigate = useNavigate()
      const {staffs} = useSelector(state => state.staff)

      const onLogout = async () => {
            await dispatch(logout())
            AlertCommon(ALERT_SUCCESS, 'Logout success')
            return navigate('/')
      }

      const showFormSaveAppointment = () => {
            dispatch(toggleFormSaveAppointment(true))
      }

      const showStaffPanel = async (staff) => {
            await dispatch(setStaffSelected(staff))
            dispatch(setOpenStaffPanel(true))
      }

      const renderListStaff = () => {
            return staffs.map(item => (
                  <div key={item.id} className="item">
                        <div className="staff">
                              <p className="name" onClick={() => showStaffPanel(item)}>{item.first_name} {item.last_name}</p>
                              <p className="check-in">
                                   <span className={`time ${item.status === STAFF_AVAILABLE && 'available'} ${item.status === STAFF_WORKING && 'working'} ${item.status === STAFF_BREAK && 'break'}`}>
                                         <span>
                                               <i className="fa-solid fa-circle"></i>
                                               {item.status === STAFF_AVAILABLE && item.time_check_in}
                                               {(item.status === STAFF_WORKING || item.status === STAFF_BREAK) && item.status_name}
                                         </span>
                                   </span>
                              </p>
                        </div>
                        {(item.appointment_processing && item.status === STAFF_WORKING) &&
                              <div className="customer">
                                    <p className="name">{item.appointment_processing.customer_name}</p>
                                    <p className="time">#{item.appointment_processing.id} - {item.appointment_processing.date_appointment_format}</p>
                              </div>
                        }
                  </div>
            ))
      }

      const redirectCustomerCheckIn = () => {
            Modal.confirm({
                  className: 'modal-confirm-switch',
                  title: t('modal.title_confirm_switch_client_mode'),
                  icon: <ExclamationCircleFilled />,
                  content: t('modal.content_confirm_switch_client_mode'),
                  okText: t('button.ok'),
                  cancelText: t('button.cancel'),
                  onOk() {
                        dispatch(updateAdminNeedEnterPassword(true))
                        navigate('/customer-checkin')
                  },
                  onCancel() {
                        return
                  },
            });
      }

      return (
            <Layout.Sider
                  breakpoint="lg"
                  collapsedWidth="300"
                  theme={theme}
                  className='sidebar-right'
            >
                 <div className="content-sidebar">
                       <div className="widget">
                             <div className="widget-head">
                                   <Button onClick={redirectCustomerCheckIn}>{t('button.customer_check_in')}</Button>
                                   <Button onClick={showFormSaveAppointment}>{t('button.add_appointment')}</Button>
                                   <Row gutter={16}>
                                         <Col span={12}>
                                               <Button onClick={() => onShowMenu()}>{t('button.menu')}</Button>
                                         </Col>
                                         <Col span={12}>
                                               <Button onClick={() => dispatch(toggleShowModalReport(true))}>{t('button.report')}</Button>
                                         </Col>
                                   </Row>
                                   <Button onClick={() => dispatch(toggleShowModalStaff(true))}>{t('button.check_in_out')}</Button>
                                   <Button onClick={onLogout}>{t('button.logout')}</Button>
                             </div>
                             <div className="widget-body">
                                   <h3 className="title">{t('slider.title_staff_list')}</h3>

                                   <div className="list-staff-working">
                                         <div className="head">
                                               <p className="staff">{t('slider.title_column_staff')}</p>
                                               <p className="customer">{t('slider.title_column_customer')}</p>
                                         </div>
                                         <div className="body">
                                               {renderListStaff()}
                                         </div>
                                   </div>
                             </div>
                       </div>

                 </div>
            </Layout.Sider>
      );
}

export default SideBar;
