const en = {
      translation: {
            header: {
                  welcome_owner: 'Welcome, Manage',
            },
            dashboard: {
                  customer_check_in: 'Customer check in',
                  customer_appointment: 'Appointment customer',
            },
            slider: {
                  title_staff_list: 'Staff list',
                  title_column_staff: 'Name',
                  title_column_customer: 'Customer',
            },
            button: {
                  customer_check_in: 'Customer check in',
                  add_appointment: 'Add new appointment',
                  create_appointment: 'Create new appointment',
                  update_appointment: 'Update appointment',
                  delete_appointment: 'Delete appointment',
                  edit_appointment: 'Edit the appointment',
                  menu: 'Menu',
                  report: 'Report',
                  check_in_out: 'Check in / Check out',
                  logout: 'logout',
                  accepted: 'Accepted',
                  rejected: 'Rejected',
                  ok: 'OK',
                  cancel: 'Cancel',
                  delete: 'Delete',
                  finish: 'Finish',
                  submit: 'Submit',
                  start_service: 'Start Service',
                  add: 'Add',
                  booking_list: 'Booking List',
                  update: 'Update',
                  save: 'Save',
                  check_in: 'Check in',
                  check_out: 'Check out',
                  close: 'Close',
                  add_image: 'Add image',
            },
            status: {
                  online: 'Online',
                  offline: 'Offline',
                  pending: 'Waiting for approval',
                  waiting: 'Waiting for service',
                  serving: 'Serving',
                  finished: 'Finished',
                  canceled: 'Canceled',
                  deleted: 'Deleted',
                  accepted: 'Accepted',
                  in_active: 'In-active',
            },
            modal: {
                  title_confirm_switch_client_mode: 'Switching to Client mode',
                  content_confirm_switch_client_mode: 'The client will only be able to use the service booking function.\n' +
                        'When you want to switch to the owner role, you will need to enter a password. Are you sure you want to switch roles? ',
                  title_email_receive_feedback : 'Manage the email that receive feedback from customer',
                  title_check_in_out_history: 'Check in - out staff history',
                  title_update_staff: 'Update staff',
                  title_update_service: 'Update service',
                  title_choose_staff: 'Choose staff',
                  title_report: 'Summary',
                  title_cancel_appointment: 'Cancel Appointment',
                  title_staff_detail: 'Staff info',
                  title_customer_detail: 'Customer info',
                  title_staff: 'Manage staff',
                  title_service: 'Manage service',
                  title_customer: 'Manage customer',
                  title_salon: 'Manage salon',
                  title_appointment_detail: 'Appointment detail',
                  title_appointment_for_customer: 'List appointment of customer',
                  title_add_appointment : 'Create new appointment',
                  title_update_appointment : 'Update appointment',
                  title_change_password : 'Change password',
                  title_update_customer : 'Update customer',

            },
            tab: {
                  add_new_staff: 'Add new staff',
                  staff_list: 'Staff list',
                  add_new_service: 'Add new service',
                  service_list: 'Service list',
            },
            appointment: {
                  request : 'Request',
                  services : 'Services',
                  total : 'Total',
                  customer_name : 'Customer name',
                  appointment_time : 'Appointment time',
                  service_note : 'Note of Service',
                  voucher : 'Voucher',
                  discount : 'Discount',
                  price: 'Price',
                  total_discount: 'Total Discount',
                  appointment_note: 'Appointment note',
                  feedback: 'Feedback',
                  rate: 'Rating',
                  cancel_by: 'Cancel by',
                  reason_cancel: 'Reason cancel',
                  time_and_date: 'Time and date',
            },
            form: {
                  customer_info: 'Customer information',
                  request_info: 'Request information',
                  placeholder_phone: 'Enter phone',
                  placeholder_name: 'Enter name',
                  label_staff: 'Choose staff',
                  label_appointment_time: 'Appointment time',
                  placeholder_staff: 'Choose staff',
                  placeholder_appointment_time: 'Select appointment time',
                  label_services: 'Services',
                  label_note_service: 'Some thing else',
                  label_work_time: 'Estimate time complete booking',
                  placeholder_search_staff: 'Search by name or phone ...',
                  placeholder_search_service: 'Search by name',
                  placeholder_search_customer: 'Search by name or phone or email..',
                  label_salon_name: 'Salon name',
                  label_email: 'Email',
                  label_phone: 'Phone',
                  label_address: 'Address',
                  label_images: 'Images',
                  label_business: 'Business hour',
                  label_description: 'Description',
                  placeholder_description: 'Description',
                  placeholder_open_time: 'Enter open time',
                  placeholder_close_time: 'Enter close time',
                  placeholder_salon_name: 'Enter salon name',
                  placeholder_salon_email: 'Enter salon email',
                  placeholder_salon_phone: 'Enter salon phone',
                  placeholder_salon_address: 'Enter salon address',
                  label_old_password: 'Old Password',
                  label_new_password: 'New Password',
                  label_confirm_password: 'Confirm Password',
                  placeholder_service_name: 'Service name',
                  placeholder_service_price: 'Price',
                  label_more_photos: 'More photos',
                  placeholder_add_image: 'Add image',
                  label_reason_cancel : 'Reason cancel?',
                  label_reason_reject : 'Reason reject?',
                  placeholder_work_time: 'Enter minutes',
                  label_in: 'In',
                  label_out: 'Out',
                  label_total: 'Total',
                  label_note: 'Note',
                  placeholder_first_name: 'Enter first name',
                  placeholder_last_name: 'Enter last name',
                  label_type: 'Type',
                  label_add_image_before: 'Add more photos before booking',
                  label_add_image_after: 'Add more photos after booking',
            },
            menu: {
                  title: 'Menu',
                  manage_staff: 'Manage staff',
                  manage_service: 'Manage service',
                  manage_customer: 'Manage customer',
                  manage_salon: 'Manage salon',
                  email_receive_feedback: 'Email receive feedback',
                  change_password: 'Change password',
            },
            table: {
                  column_stt : 'No.',
                  column_name: 'Name',
                  column_phone: 'Phone',
                  column_check_in: 'Check in',
                  column_check_out: 'Check out',
                  column_status: 'Status',
                  column_price: 'Price',
                  column_email: 'Email',
                  column_type: 'Type',
                  column_note: 'Note',
                  column_customer: 'Customer',
                  column_staff: 'Staff',
                  column_services: 'Services',
                  column_service_note: 'Service note',
                  column_address: 'Address',
            },
            notification: {
                  title : 'Notifications',
                  read_all: 'Read all',
                  delete_all: 'Delete all',
                  read: 'Read',
                  unread: 'Mask as unread',
                  delete: 'Delete'
            },
            alert: {
                  empty_notification: 'There are 0 notifications',
                  empty_review: 'There are no reviews',
                  empty_data: 'No data',
                  loading_data: 'Loading...',
                  title_confirm_delete: 'Are you sure delete?',
                  title_confirm_delete_all: 'Are you sure delete all?',
                  no_info: 'No info',
                  lock_service: 'Are you sure you want to lock this service?',
                  unlock_service: 'Are you sure you want to unlock this service?',
                  lock_staff: 'Are you sure you want to lock this staff?',
                  unlock_staff: 'Are you sure you want to unlock this staff?'
            }
            // here we will place our translations...
      }
}

export default en