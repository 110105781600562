import React from 'react';
import {Button, Layout} from 'antd';
import {
      MenuFoldOutlined,
      MenuUnfoldOutlined,
} from '@ant-design/icons';
import {useDispatch} from "react-redux";
import {toggleFormSaveAppointment} from "../../actions/dashboard";
import {toggleShowModalReport, toggleShowModalWorking} from "../../actions/modal";
import {useTranslation} from "react-i18next";
const { Header } = Layout;

const HeaderMain = ({showMenu, onShowMenu}) => {
      const dispatch = useDispatch()
      const {t} = useTranslation()

      const showFormSaveAppointment = () => {
            dispatch(toggleFormSaveAppointment(true))
      }
      return (
            <Header className="header">
                  <div className="wp-inner">
                        <div className="left">
                              <Button type="primary" onClick={() => onShowMenu()} style={{ marginBottom: 16 }}>
                                    {showMenu ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                              </Button>
                        </div>
                        <div className="right">
                              <Button className='btn-add-large' onClick={showFormSaveAppointment}>{t('button.add_appointment')}</Button>
                              <Button className='btn-add-mobile' onClick={showFormSaveAppointment}><i className="fa-solid fa-plus"></i></Button>
                              <Button onClick={() => dispatch(toggleShowModalReport(true))}><i className="fa-solid fa-chart-column"></i></Button>
                              <Button onClick={() => dispatch(toggleShowModalWorking(true))}><i className="fa-solid fa-user-group"></i></Button>
                        </div>
                  </div>

            </Header>
      );
}

export default HeaderMain;