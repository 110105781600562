import React, {useState} from 'react';
import {Button, Form, Input, Modal} from "antd";
import {useDispatch, useSelector} from "react-redux";
import AlertCommon from "../../../components/alert";
import {ALERT_ERROR, ALERT_SUCCESS} from "../../../constants/common";
import {changePassword} from "../../../actions/auth";
import {toggleShowModalChangePassword} from "../../../actions/modal";
import {useTranslation} from "react-i18next";

const ModalChangePassword = (props) => {
      const {openChangePassword} = useSelector(state => state.modal)
      const [loading, setLoading] = useState(false)
      const [form] = Form.useForm();
      const dispatch = useDispatch()
      const {t} = useTranslation()

      const onChangePassword = async (e) => {
            try {
                  setLoading(false)
                  let response = await changePassword(e)
                  AlertCommon(ALERT_SUCCESS, response.message)
                  setLoading(false)
                  return dispatch(toggleShowModalChangePassword(false))
            } catch (e) {
                  setLoading(false)
                  AlertCommon(ALERT_ERROR, e.message)
            }
      }

      const afterClose = () => {
            form.resetFields()
      }

      return (
            <Modal
                  footer={null}
                  visible={openChangePassword}
                  maskClosable={false}
                  closable={false}
                  centered
                  className='modal-general modal-change-password'
                  afterClose={afterClose}
            >
                  <div className="head-modal">
                        <p className="title">{t('modal.title_change_password')}</p>
                        <Button onClick={() => dispatch(toggleShowModalChangePassword(false))}>{t('button.close')}</Button>
                  </div>
                  <div className="body-modal">
                        <div className="form-wp">
                              <Form
                                    form={form}
                                    layout='vertical'
                                    name="control-hooks"
                                    onFinish={onChangePassword}
                              >
                                    <Form.Item name="old_password" label={t('form.label_old_password')}>
                                          <Input.Password/>
                                    </Form.Item>
                                    <Form.Item name="new_password" label={t('form.label_new_password')}>
                                          <Input.Password/>
                                    </Form.Item>
                                    <Form.Item name="confirm_password" label={t('form.label_confirm_password')}>
                                          <Input.Password/>
                                    </Form.Item>

                                    <Form.Item>
                                          <Button loading={loading} type="primary" htmlType="submit">
                                                {t('button.save')}
                                          </Button>
                                    </Form.Item>
                              </Form>
                        </div>
                  </div>
            </Modal>
      );
}

export default ModalChangePassword;