import { combineReducers } from "redux";
import auth from "./auth";
import staff from "./staff";
import dashboard from "./dashboard";
import service from "./service";
import customer from "./customer";
import modal from "./modal";
import config from "./config";

export default combineReducers({
      auth,
      staff,
      dashboard,
      service,
      customer,
      modal,
      config,
});
